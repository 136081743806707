import React from 'react';
import { ScrollView, View, StyleSheet, Dimensions } from 'react-native';
import { TextInput, Switch, Button, Paragraph, Text, Dialog, Portal } from 'react-native-paper';
import ValidationComponent from 'react-native-form-validator';
import TruckEditForm from './TruckEditForm';
import Api from './Api';

const window = Dimensions.get("window");

class Alert extends ValidationComponent {
    state = {
        visible: false,
        loading: false,
        userId: false,
        actions: [],
        trucks: []
    };

    componentDidMount = () => {
        this.setState({
            title: 'test',
            content: 'test',
            customContent: false,
            actions: [{
                title: 'Отмена',
                action: () => { this._hideDialog() }
            }, {
                title: 'Сохранить',
                action: () => { this.onSubmit() }
            }],
            onDismiss: () => { this._hideDialog() }
        });
    }

    show = (clientInfo, updateClientInfo) => {
        this.updateClientInfo = updateClientInfo;

        let state = {
            userId: clientInfo._id,
            trucks: clientInfo.trucks ? clientInfo.trucks : [],
            visible: true,
        };
        
        this.setState(state);
    }

    onSubmit = () => {
        if (this.state.loading) return;
        this.setState({ loading: true });
        Api.request('account/saveInfo', {
            userId: this.state.userId,
            form: {
                trucks: this.state.trucks
            }
        }).then(response => {
            if (this.updateClientInfo && response.success) this.updateClientInfo(response.user);
            this.setState({ loading: false });
            this._hideDialog();
        });
    }

    _showDialog = () => this.setState({ visible: true });

    _hideDialog = () => {
        this.updateClientInfo = false;
        this.setState({ visible: false });
    }

    addTruck = () => {
        let trucks = this.state.trucks.slice();
        trucks.push({ name: '', city: '', count: '1', amount: '10' });
        this.setState({ trucks: trucks });
    }

    removeTruck = (index) => {
        this.setState(state => {
            state.trucks.splice(index, 1);
            return { trucks: this.state.trucks };
        });
    }

    editTruckName = (name, index) => {
        this.setState(state => {
            state.trucks[index].name = name;
            return { trucks: this.state.trucks };
        });
    }

    editTruckDescription = (description, index) => {
        this.setState(state => {
            state.trucks[index].description = description;
            return { trucks: this.state.trucks };
        });
    }
    
    editTruckCity = (city, index) => {
        this.setState(state => {
            state.trucks[index].city = city;
            return { trucks: this.state.trucks };
        });
    }

    render() {
        return (
            <Portal>
                <Dialog
                    style={{
                        maxHeight: window.height-80,
                        maxWidth: 640, 
                        marginHorizontal: 'auto' 
                    }}
                    visible={this.state.visible}
                    onDismiss={this.state.onDismiss}
                >
                    <Dialog.Title>Редактирование перечня техники</Dialog.Title>
                    <Dialog.Content style={{ flex: 1}}>
                        <Button
                            style={{ marginBottom: 10 }}
                            contentStyle={{ height: 48 }}
                            mode="outlined"
                            color="#212121"
                            onPress={this.addTruck}
                        >
                                Добавить еще технику
                        </Button>
                        <ScrollView style={{ flex: 1, paddingHorizontal: 5}}>
                            {this.state.trucks.map((truck, index) => (
                            <View key={index} style={{ marginBottom: 24 }}>
                                <TruckEditForm
                                    style={{ marginHorizontal: 0, borderWidth: 1 }}
                                    truck={truck}
                                    onChangeName={(value) => this.editTruckName(value, index)}
                                    onChangeDescription={(value) => this.editTruckDescription(value, index)}
                                    onChangeCity={(value) => this.editTruckCity(value, index)}
                                    onRemove={() => this.removeTruck(index)}
                                />
                            </View>
                            ))}
                        </ScrollView>
                    </Dialog.Content>
                    <Dialog.Actions>
                        {this.state.actions.map((item, i) => (
                            <Button key={i} onPress={() => item.action(this)}>{item.title}</Button>
                        ))}
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        );
    }
}

Alert.defaultProps = {
    content: '',
    customContent: false,
    actions: [{
        title: 'ОК',
        action: () => {}
    }],
    onDismiss: () => { this._hideDialog }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 24,
        paddingHorizontal: 16,
        backgroundColor: '#fff'
    },
    marginTop: {
        marginTop: 23
    },
    textInput: {
        height: 48,
    },
    text: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    divider: {
        height: 1,
        backgroundColor: '#CED4DA',
        marginVertical: 36
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    switchLabel: {
        color: '#212529',
        fontSize: 16,
        lineHeight: 19,
        marginLeft: 16
    },
    switch: {
        color: '#FFC107'
    }
})

export default Alert;