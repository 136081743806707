import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import ManagerScreen from '../screens/ManagerScreen';
import ManagerProfile from '../components/ManagerProfile';

const Drawer = createDrawerNavigator();

class ManagerDrawerNavigation extends React.Component {
	render() {
		return (
			<NavigationContainer>
		        <Drawer.Navigator drawerContent={props => (<ManagerProfile {...props} />)}>
		         	<Drawer.Screen name="ManagerScreen" component={ManagerScreen} />
		        </Drawer.Navigator>
			</NavigationContainer>
		);
	}
}

export default ManagerDrawerNavigation;