import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { NavigationContainer } from '@react-navigation/native';
import ServiceNavigator from '../navigation/ServiceNavigator';

const ServiceScreen = () => (
    <View style={{flex:1}}>
        <ServiceNavigator />
    </View>
)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default ServiceScreen