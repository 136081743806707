import React, { Component } from 'react';
import { ScrollView, View, Text, StyleSheet, TouchableOpacity, Button } from 'react-native';
import TruckList from '../components/TruckList';
import Alert from '../components/Alert';
import Api from '../components/Api';

class TrucksScreen extends Component {
    state = {
        truckItems: [],
        loading: true
    }

    componentDidMount = () => {
        Api.request('getTrucks').then(response => {
            if (response.success) {
                this.setState({
                    truckItems: response.items
                })
            }
        });
    }
    
    _order = (truck) => {
        return new Promise((resolve, reject) => {
            Api.request('chat/addOrder', {
                type: 'truck',
                form: {
                    truckId: truck._id,
                    truckName: truck.name
                }
            }).then(response => {
                if (response.success) {
                    resolve(response);
                } else {
                    reject();
                }
            });
        });
    }
    
    order = (truck) => {
        this.alert.show({
            title: truck.name,
            content: 'Отправить заказ диспетчеру?',
            actions: [{
                title: 'Отправить',
                action: async (dialog) => {
                    await this._order(truck);
                    dialog._hideDialog();
                    this.props.navigation.navigate('Chat');
                }
            }, {
                title: 'Отмена',
                action: dialog => dialog._hideDialog()
            }]
        });
    }
    
    render() {
        return (
            <ScrollView style={styles.container}>
                <Alert ref={(node) => { this.alert = node}} />
                <Text style={styles.h1}>Аренда спецтехники</Text>
                <TruckList truckItems={this.state.truckItems} onOrder={(truck) => this.order(truck)} />
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    h1: {
        fontSize: 21,
        lineHeight: 25,
        marginTop: 33,
        marginBottom: 32
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 16,
    }
})

export default TrucksScreen;