import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text'
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import StepInput from '../components/StepInput';
import TruckEditForm from '../components/TruckEditForm';
import MyButton from '../components/MyButton';
import Api from '../components/Api';
import { alertMsg } from '../components/Api';

class ClientForm extends ValidationComponent {
    state = {
        name: '',
        companyName: '',
        inn: '',
        phone: '',
        email: '',
        trucks: [{
            name: '',
            description: '',
            city: ''
        }],
        loading: false
    };

    validRules = {
        name: {required: true},
        companyName: {required: true},
        inn: {required: true},
        phone: {minlength: 11, maxlength: 11, required: true},
    };

    handlePhoneChange = (phone) => {
        let phoneFormated = phone.replace(/\D+/g,'');
        if (phoneFormated.substring(1,2) === '8') phoneFormated = '7'+phoneFormated.substring(2);
        this.setState({ phone: phoneFormated });
    };

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules)) {
            this.setState({ loading: true }, () => {
                Api.registration({
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    companyData: {
                        companyName: this.state.companyName,
                        inn: this.state.inn,
                    },
                    trucks: this.state.trucks,
                    client: false,
                    employee: true,
                }).then(() => {
                    this.setState({ loading: false });
                    this.props.onReg(this.state.phone);
                }, () => {
                    this.setState({ loading: false });
                });
            });
        } else {
            alertMsg(false, 'Заполнены не все поля');
        }
    };

    addTruck = () => {
        let trucks = this.state.trucks.slice();
        trucks.push({ name: '', city: '' });
        this.setState({ trucks: trucks });
    }

    removeTruck = (index) => {
        if (this.state.trucks.length == 1) {
            this.setState(state => {
                state.trucks.splice(index, 1);
                return {
                    trucks: [{
                        name: '',
                        description: '',
                        city: ''
                    }]
                };
            });
        } else {
            this.setState(state => {
                state.trucks.splice(index, 1);
                return { trucks: this.state.trucks };
            });
        }
    }
    
    editTruckName = (name, index) => {
        this.setState(state => {
            state.trucks[index].name = name;
            return { trucks: this.state.trucks };
        });
    }

    editTruckDescription = (description, index) => {
        this.setState(state => {
            state.trucks[index].description = description;
            return { trucks: this.state.trucks };
        });
    }
    
    editTruckCity = (city, index) => {
        this.setState(state => {
            state.trucks[index].city = city;
            return { trucks: this.state.trucks };
        });
    }

    render() {
        return (
            <View style={{ maxWidth: 360, width: '100%' }}>
                <View style={[styles.formContainer, formStyles.formShadowContainer]}>
                    <TextInput
                        style={{ marginBottom: 27 }}
                        label='Юридическое название (ООО/ИП)'
                        mode='outlined'
                        value={this.state.companyName}
                        onChangeText={(companyName) => this.setState({ companyName: companyName })}
                        autoCorrect={false}
                        returnKeyType="next"
                        error={this.isFieldInError('companyName')}
                    />
                    <TextInput
                        style={{ marginBottom: 27 }}
                        label='ИНН'
                        mode='outlined'
                        value={this.state.inn}
                        onChangeText={(inn) => this.setState({ inn: inn })}
                        autoCorrect={false}
                        returnKeyType="next"
                        error={this.isFieldInError('inn')}
                    />
                    <TextInput
                        style={{ marginBottom: 27 }}
                        label='Контактное лицо'
                        mode='outlined'
                        value={this.state.name}
                        onChangeText={(name) => this.setState({ name: name })}
                        autoCorrect={false}
                        returnKeyType="next"
                        error={this.isFieldInError('name')}
                    />
                    <TextInput
                        style={{ marginBottom: 33 }}
                        label='Телефон'
                        mode='outlined'
                        value={this.state.phone}
                        onChangeText={this.handlePhoneChange}
                        autoCorrect={false}
                        keyboardType="phone-pad"
                        returnKeyType="next"
                        render={props =>
                            <TextInputMask
                                {...props}
                                type={'custom'}
                                options={{
                                    mask: '+7 (999) 999-99-99'
                                }}
                            />
                        }
                        error={this.isFieldInError('phone')}
                    />
                    <TextInput
                        label='Электронная почта'
                        mode='outlined'
                        value={this.state.email}
                        onChangeText={(email) => this.setState({ email: email })}
                        autoCorrect={false}
                        returnKeyType="next"
                    />
                </View>
                <Text style={{ color: '#212529', fontSize: 20, lineHeight: 23, marginVertical: 24, paddingHorizontal: 32 }}>Добавление техники</Text>
                {this.state.trucks.map((truck, index) => (
                <View key={index} style={{ marginBottom: 24 }}>
                    <TruckEditForm
                        truck={truck}
                        onChangeName={(value) => this.editTruckName(value, index)}
                        onChangeDescription={(value) => this.editTruckDescription(value, index)}
                        onChangeCity={(value) => this.editTruckCity(value, index)}
                        onRemove={() => this.removeTruck(index)}
                    />
                </View>
                ))}
                <View style={{ paddingHorizontal: 32 }}>
                    <MyButton
                        onPress={this.addTruck}
                        style={{ marginBottom: 24 }}
                        color="#212121"
                        text="Добавить еще технику"
                    />
                    <Button
                        style={{ marginBottom: 24 }}
                        contentStyle={{ height: 48 }}
                        mode="contained"
                        color="#FFC107"
                        loading={this.state.loading}
                        onPress={() => this.onSubmit()}
                    >
                            Зарегистрироваться
                    </Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    formContainer: {
        marginHorizontal: 16
    }
})

export default ClientForm