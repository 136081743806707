import React from 'react';
import { Platform, ScrollView, View, StyleSheet } from 'react-native';
import { TextInput, Switch, Button, Paragraph, Text, Dialog, Portal } from 'react-native-paper';
import ValidationComponent from 'react-native-form-validator';
import Api from './Api';
import DialogStyles from '../styles/dialog.js';

class Alert extends ValidationComponent {
    state = {
        visible: false,
        loading: false,
        director: false,
        userId: false,
        actions: [],
        isEmployee: true,
        name: '',
        nickname: '',
        email: '',
        companyName: '',
        inn: '',
    };

    componentDidMount = () => {
        this.setState({
            title: 'test',
            content: 'test',
            customContent: false,
            actions: [{
                title: 'Отмена',
                action: () => { this._hideDialog() }
            }, {
                title: 'Сохранить',
                action: () => { this.onSubmit() }
            }],
            onDismiss: () => { this._hideDialog() }
        });
    }

    show = (clientInfo, updateClientInfo, accountType) => {
        this.updateClientInfo = updateClientInfo;

        let state = {
            userId: clientInfo._id,
            name: clientInfo.name,
            nickname: clientInfo.nickname,
            email: clientInfo.email,
            companyName: clientInfo.companyData ? clientInfo.companyData.companyName : '',
            inn: clientInfo.companyData ? clientInfo.companyData.inn : '',
            isEmployee: clientInfo.employee ? true : false,
            verified: clientInfo.verified ? true : false,
            allowBookOrder: clientInfo.allowBookOrder ? true : false,
            directorVerified: clientInfo.directorVerified ? true : false,
            directorInitVerified: clientInfo.directorVerified ? true : false,
            visible: true,
            director: (accountType === 'Директор') ? true : false,
        };
        
        this.setState(state);
    }

    validRules = {
        name: {required: true},
    }

    onDirectorVerification = () => {
        if (this.state.loading) return;
        this.setState({ loading: true }, () => {
            Api.request('account/requestDirectorVerification', {
                userId: this.state.userId,
            }).then(response => {
                this.setState({ loading: false });
                this._hideDialog();
            });
        });
    }

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules)) {
            this.setState({ loading: true }, () => {
                let form = {
                    name: this.state.name,
                    nickname: this.state.nickname,
                    email: this.state.email,
                    verified: this.state.verified,
                    allowBookOrder: this.state.allowBookOrder,
                    directorVerified: this.state.directorVerified,
                }
                if (this.state.isEmployee) {
                    form = {
                        ...form,
                        companyData: {
                            companyName: this.state.companyName,
                            inn: this.state.inn,
                        }
                    }
                }
                this.setState({ loading: true });
                Api.request('account/saveInfo', {
                    userId: this.state.userId,
                    form: form,
                    directorVerifiedChanged: this.state.directorInitVerified ? false : this.state.directorInitVerified !== this.state.directorVerified,
                }).then(response => {
                    if (this.updateClientInfo) this.updateClientInfo(response.user);
                    this.setState({ loading: false });
                    this._hideDialog();
                });
            });
        }
    }

    _onToggleVerifySwitch = () => this.setState(state => {
        if (state.verified) {
            return {
                verified: false,
                allowBookOrder: false,
            };
        } else {
            return {
                directorVerified: true,
                verified: true,
                allowBookOrder: true,
            };
        }
    });

    _onToggleAllowBookOrderSwitch = () => this.setState(state => {
        if (state.verified) {
            return {
                allowBookOrder: !state.allowBookOrder,
            };
        } else {
            return {
                allowBookOrder: false,
            };
        }
    });

    _showDialog = () => this.setState({ visible: true });

    _hideDialog = () => {
        this.updateClientInfo = false;
        this.setState({ visible: false });
    }

    render() {
        return (
            <Portal>
                <Dialog
                    style={[DialogStyles.container, Platform.OS === 'web' ? { minWidth: 396 } : {}]}
                    visible={this.state.visible}
                    onDismiss={this.state.onDismiss}
                >
                    <Text style={DialogStyles.title}>
                        Личные данные
                    </Text>
                    <ScrollView style={DialogStyles.content}>
                        <TextInput
                            style={[styles.textInput, {marginBottom: 23}]}
                            value={this.state.nickname}
                            onChangeText={value => this.setState({ nickname: value })}
                            label='Псевдоним'
                            mode='outlined'
                        />
                        { this.state.isEmployee ?
                        <View>
                            <TextInput
                                style={[styles.textInput, {marginBottom: 23}]}
                                value={this.state.companyName}
                                onChangeText={value => this.setState({ companyName: value })}
                                label='Юредическое название (ООО/ИП)'
                                mode='outlined'
                            />
                            <TextInput
                                style={[styles.textInput, {marginBottom: 23}]}
                                value={this.state.inn}
                                onChangeText={value => this.setState({ inn: value })}
                                label='ИНН'
                                mode='outlined'
                            />
                        </View>
                        : null }
                        <TextInput
                            style={[styles.textInput, {marginBottom: 23}]}
                            value={this.state.name}
                            onChangeText={value => this.setState({ name: value })}
                            label='Имя'
                            mode='outlined'
                            error={this.isFieldInError('name')}
                        />
                        <TextInput
                            style={[styles.textInput, {marginBottom: 23}]}
                            value={this.state.email}
                            onChangeText={value => this.setState({ email: value })}
                            label='Электронная почта'
                            mode='outlined'
                            dense={true}
                        />
                        { (this.state.directorVerified || this.state.director) ?
                            <View>
                                <View style={[styles.switchContainer, { paddingBottom: 20, paddingLeft: 10 }]}>
                                    <Switch
                                        color="#FFC107"
                                        value={this.state.verified}
                                        onValueChange={this._onToggleVerifySwitch}
                                    />
                                    <Text style={styles.switchLabel}>Данные верифицированы</Text>
                                </View>
                                <View style={[styles.switchContainer, { paddingBottom: 20, paddingLeft: 10 }]}>
                                    <Switch
                                        color="#FFC107"
                                        value={this.state.allowBookOrder}
                                        onValueChange={this._onToggleAllowBookOrderSwitch}
                                    />
                                    <Text style={styles.switchLabel}>Разрешить брать заказы</Text>
                                </View>
                            </View>
                            :
                            <View>
                                <Button
                                    style={{}}
                                    mode="contained"
                                    color="#FFC107"
                                    contentStyle={{height: 48}}
                                    loading={this.state.loading}
                                    onPress={this.onDirectorVerification}
                                >
                                    На проверку Директору
                                </Button>
                            </View>
                        }
                    </ScrollView>
                    <View style={DialogStyles.buttons}>
                        {this.state.actions.map((item, i) => (
                            <Button key={i} onPress={() => item.action(this)}>{item.title}</Button>
                        ))}
                    </View>
                </Dialog>
            </Portal>
        );
    }
}

Alert.defaultProps = {
    content: '',
    customContent: false,
    actions: [{
        title: 'ОК',
        action: () => {}
    }],
    onDismiss: () => { this._hideDialog }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 24,
        paddingHorizontal: 16,
        backgroundColor: '#fff'
    },
    marginTop: {
        marginTop: 23
    },
    textInput: {
        height: 48,
    },
    text: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    divider: {
        height: 1,
        backgroundColor: '#CED4DA',
        marginVertical: 36
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    switchLabel: {
        color: '#212529',
        fontSize: 16,
        lineHeight: 19,
        marginLeft: 16
    },
    switch: {
        color: '#FFC107'
    }
})

export default Alert;