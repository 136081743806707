import config from '../config';
import openSocket from 'socket.io-client';
import { Alert, Platform, AsyncStorage } from 'react-native';
import Spinner from './Spinner';
import App from './App.js';
import Logger from './Logger';

export const alertMsg = (title, message) => {
    if (!title) title = 'Ошибка';
    if (!message) message = 'Ошибка подключения к серверу';
    if (Platform.OS === 'web') {
        alert(message);
    } else {
        Alert.alert(
          title,
          message,
          [
            {
              text: "OK",
              style: "cancel"
            }
          ],
          { cancelable: false }
        );
    }
}

const Api = function() {
    this.connected = false;
    this.waitConnection = false;
    this.token = false;
    this.pushToken = false;
    this.userId = false;
    this.notify = () => {};
    this.chatReconnect = () => {};
    this.store = false;
    
    Logger.log('request', 'Open web sockets');
    this.socket = openSocket(config.socketsUrl, { forceNode: true });

    this.socket.on('connect', async () => {
        Logger.log('request', 'Web sockets opened');
        this.connected = true;
        if (this.token) {
            let response = await this.checkAuth(this.token);
            this.waitConnection = false;
            this.chatReconnect();
            
            if (response.auth && response.accountType == 'director' && this.store) {
                this.request('order/getList', {
                }).then(response => {
                    if (response.success) {
                        this.store.dispatch({
                            type: 'LOAD_ORDER_LIST',
                            payload: response.chats
                        });
                    }
                });
            }
        } else {
            this.waitConnection = false;
            this.token = false;
            this.userId = false;
            let app = App();
            if (app.setState) app.setState({ isLoggedIn: false });
        }
    });
    
    this.timer = {
        handler: false,
        start: () => {
            if (this.timer.handler) {
                clearTimeout(this.timer.handler);
            }
            this.timer.handler = setTimeout(function(){
                Spinner.off();
                alertMsg();
            }, 3000);
        },
        stop: () => {
            if (this.timer.handler) {
                clearTimeout(this.timer.handler);
            }
        }
    };
    
    this.uploadFile = (file, base64 = false, filename = '') => {
        if (base64) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', config.apiUrl+'/uploadFileBase64');

                xhr.onprogress = function () {
                    // TODO: доделать отображение процесса загрузки файла
                };

                xhr.onload = function () {
                    let response = JSON.parse(xhr.response);
                    if (response.success) {
                        resolve(config.apiUrl+'/getFile/'+response.path);
                    } else {
                        //TODO: добавить сообщение об ошибке
                        reject();
                    }
                };

                xhr.send(JSON.stringify({
                    file: file,
                    filename: filename
                }));
            });
        } else {
            filename = file;
            return new Promise((resolve, reject) => {
                const file = {
                    uri: filename,
                    name: filename.split("/").pop(),
                    type: 'multipart/form-data'
                };

                const formData = new FormData();
                formData.append('file', file);

                const xhr = new XMLHttpRequest();
                xhr.open('POST', config.apiUrl+'/uploadFile');

                xhr.onprogress = function () {
                    // TODO: доделать отображение процесса загрузки файла
                };

                xhr.onload = function () {
                    let response = JSON.parse(xhr.response);
                    if (response.success) {
                        resolve(config.apiUrl+'/getFile/'+response.path);
                    } else {
                        //TODO: добавить сообщение об ошибке
                        reject();
                    }
                };

                xhr.send(formData);
            });
        }
    };
    
    this.auth = function(login, password) {
        return new Promise((resolve, reject) => {
            if (!this.connected) {
                alertMsg();
                reject();
            } else {
                this.socket.emit('auth', {
                    login: login,
                    password: password
                }, response => {
                    Logger.log('auth', 'Авторизация пользователя. ' + ((response.success) ? 'Пользователь авторизован' : 'Пользователь не авторизован'), response);
                    if (response.success) {
                        this.userId = response.user.id;
                        resolve(response);
                    } else {
                        alertMsg('Ошибка', response.message);
                        reject();
                    }
                });
            }
        });
    };
    
    this.checkAuth = function(token) {
        return new Promise((resolve, reject) => {
            this.socket.emit('checkAuth', {
                token: token,
                pushToken: this.pushToken
            }, response => {
                Logger.log('auth', 'Проверка авторизации пользователя. ' + (response.success) ? 'Пользователь переподключен к серверу' : 'Пользователь не авторизован');
                if (response.success) {
                    this.userId = response.user.id;
                    resolve({auth: true, accountType: response.user.accountType, user: response.user});
                } else {
                    if (response.error === 'user_blocked') {
                        App().logout();
                    }
                    resolve(false);
                }
            });
        });
    };
    
    this.registration = function(data) {
        return new Promise((resolve, reject) => {
            this.socket.emit('registration', data, response => {
                if (response.success) {
                    resolve();
                } else {
                    alertMsg('Ошибка', response.message);
                    reject();
                }
            });
        });
    };
    
    this.remember = function(data) {
        return new Promise((resolve, reject) => {
            this.socket.emit('remember', data, response => {
                Logger.log('auth', 'Инициировано восстановление пароля');
                if (response.success) {
                    resolve();
                } else {
                    alertMsg('Ошибка', response.message);
                    reject();
                }
            });
        });
    };
    
    this.request = function(message, data = {}, alert = true) {
        data.token = this.token;
        
        return new Promise((resolve, reject) => {
            Logger.log('request', 'Запрос: ' + message, data);
            let delayed = false;

            let doRequest = () => {
                if (this.waitConnection) {
                    if (!delayed) {
                        Logger.log('request', 'Запрос отложен: ' + message);
                        delayed = true;
                    }
                    setTimeout(() => {
                        doRequest();
                    }, 50);
                } else {
                    if (!this.connected) {
                        alertMsg('Ошибка', 'Ошибка подключения к серверу');
                    }
                    if (delayed) {
                        Logger.log('request', 'Отложенный pапрос отправлен: ' + message);
                        delayed = true;
                    }
                    this.socket.emit(message, data, response => {
                        Logger.log('request', 'Ответ на запрос: ' + message, response);
                        if (response.success === false && alert === true) {
                            alertMsg('Ошибка', response.message);
                            reject(response);
                        }
                        // TODO: сделать обработку response.error = 'noauth'
                        resolve(response);
                    });
                }
            }

            doRequest();
        });
    }
    
    this.alert = alertMsg;
    
};

const api = new Api();

export default api;