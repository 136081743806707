import React from 'react';
import { TextInput as TextInputNative, View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text, Button } from 'react-native-paper';

class Input extends React.Component {
    state = {
        value: this.props.defaultValue
    }

    update = (value) => {
        this.setState({ value: value }, () => {
            if (this.props.onChangeText) this.props.onChangeText(this.state.value);
        });
    }

    dec() {
        this.setState(state => {
            let value = parseInt(state.value);
            if (value == NaN) value = 0;
            value = value - this.props.step;
            if (value < this.props.min) value = this.props.min;
            return { value: value.toString() };
        }, () => this.update(this.state.value));
    }

    inc() {
        this.setState(state => {
            let value = parseInt(state.value);
            if (value == NaN) value = 0;
            value = value + this.props.step;
            return { value: value.toString() };
        }, () => this.update(this.state.value));
    }

    render() {
        return (
            <View style={styles.container}>
                <Button
                    compact={true}
                    style={{ marginRight: 8, marginTop: 4}}
                    labelStyle={{ fontSize: 32, lineHeight: 26 }}
                    contentStyle={{ width: 36, height: 36 }}
                    mode="outlined"
                    onPress={() => this.dec()}
                >-</Button>
                <View style={{ flexDirection: 'row', width: 114, marginTop: 3, borderWidth: 1, borderRadius: 4, borderColor: '#757575' }}>
                    <View style={{ flex: 1, }}>
                        <TextInputNative
                            value={this.state.value}
                            onChangeText={this.update}
                            keyboardType="phone-pad"
                            style={{ height: 36, paddingVertical: 0, paddingHorizontal: 14, color: '#000', fontSize: 16 }}
                        />
                    </View>
                    {this.props.suffix ?
                        <View style={{ flexDirection: 'row', flexWrap: 'nowrap', marginRight: 5}}>
                            <Text style={{ lineHeight: 36 }}>м</Text>
                            <Text style={{ fontSize: 11, lineHeight: 26 }}>3</Text>
                        </View>
                    :
                        null
                    }
                </View>
                <Button
                    compact={true}
                    style={{ marginLeft: 8, marginTop: 4 }}
                    labelStyle={{ fontSize: 24, lineHeight: 22 }}
                    contentStyle={{ width: 36, height: 36 }}
                    mode="outlined"
                    onPress={() => this.inc()}
                >+</Button>
            </View>
        );
    }
}

Input.defaultProps = {
    defaultValue: '10',
    step: 1,
    min: 10,
    suffix: false,
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    }
})

export default Input;