import React, { Component } from 'react';
import { ScrollView, StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import ValidationComponent from 'react-native-form-validator';
import TextField from './TextField';
import defaultStyles from '../styles/main.js';
import Api from './Api';

class Example extends ValidationComponent {
    
    state = {
        from: '',
        destination: '',
        payload: '',
        amount: '',
        loading: false
    }

    validRules = {
        from: {required: true},
        destination: {required: true},
        payload: {required: true},
        amount: {required: true},
    }

    onSubmit = () => {
        if (this.validate(this.validRules)) {
            this.setState({ loading: true }, () => {
                Api.request('chat/addOrder', {
                    type: 'transportation',
                    form: {
                        from: this.state.from,
                        destination: this.state.destination,
                        payload: this.state.payload,
                        amount: this.state.amount,
                    }
                }).then(response => {
                    this.setState({ loading: false });
                    this.props.navigation.navigate('Chat');
                });
            });
        }
    };

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.imageContainer}>
                    <Image style={styles.image} source={require('../img/form-arrow.png')} />
                </View>
                <View style={styles.formContainer}>
                    <Text style={[styles.label, {marginBottom: 10}]}>Откуда</Text>
                    <TextField
                        value={this.state.from}
                        onChangeText={value => this.setState({ from: value })}
                        label='Город, нас. пункт'
                        error={this.isFieldInError('from')}
                        style={{ marginBottom: 15 }}
                    />
                    <Text style={styles.label}>Куда</Text>
                    <TextField
                        value={this.state.destination}
                        onChangeText={value => this.setState({ destination: value })}
                        style={styles.marginBottom}
                        label='Город, нас. пункт'
                        mode='outlined'
                        error={this.isFieldInError('destination')}
                    />
                    <TextField
                        value={this.state.payload}
                        onChangeText={value => this.setState({ payload: value })}
                        style={styles.marginBottom}
                        label='Что перевозим'
                        mode='outlined'
                        error={this.isFieldInError('payload')}
                    />
                    <TextField
                        value={this.state.amount}
                        onChangeText={value => this.setState({ amount: value })}
                        style={styles.marginBottom}
                        label='Сколько перевозим'
                        mode='outlined'
                        error={this.isFieldInError('amount')}
                    />
                    <Button
                        style={[defaultStyles.button, styles.marginBottom]}
                        mode="contained"
                        color="#FFC107"
                        loading={this.state.loading}
                        onPress={() => this.onSubmit()}
                    >
                        Узнать стоимость
                    </Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row'
    },
    imageContainer: {
        width: 40
    },
    image: {
        width: 32,
        height: 147,
        marginTop: 50
    },
    formContainer: {
        flex: 1,
    },
    label: {
        marginLeft: 14,
        marginBottom: 7,
        fontSize: 21,
        lineHeight: 25,
        color: '#212529'
    },
    marginBottom: {
        marginBottom: 20
    }
});

export default Example;