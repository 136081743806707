const INITIAL_STATE = {
    list: [],
    groups: [],
    orderList: [],
    activeChat: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOAD_CHAT_LIST':
            return {
                ...state,
                list: action.payload
            };
        case 'ADD_CHAT_TO_LIST':
            return {
                ...state,
                list: [
                    ...state.list,
                    action.payload
                ]
            };
        case 'UPDATE_CHAT_INFO':
            state.list.map(item => {
                if (item.chat) {
                    let chat = action.payload.find(chatItem => (item.chat._id == chatItem.chat._id));
                    if (chat) {
                        item.chat.lastTimestamp = chat.chat.lastTimestamp;
                        item.chat.count = chat.chat.count;
                        item.chat.readCount = chat.chat.readCount;
                    }
                } else return item;
            });
            
            return {
                ...state,
                list: state.list.slice()
            }
        case 'UPDATE_CHAT_USER_INFO':
            state.list.map((item, index) => {
                if (item._id == action.payload._id) {
                    state.list[index] = {
                        ...item,
                        ...action.payload
                    }
                } else return item;
            });
            state.orderList.map((item, index) => {
                if (item.customer._id == action.payload._id) {
                    state.orderList[index].customer = {
                        ...item.customer,
                        ...action.payload
                    }
                }
            });
            if (state.activeChat) {
                if (state.activeChat.type == 'Заказ') {
                    if (state.activeChat.customer._id == action.payload._id) {
                        state.activeChat.customer = {
                            ...state.activeChat.customer,
                            ...action.payload
                        }
                    }
                } else {
                    if (state.activeChat._id == action.payload._id) {
                        state.activeChat = {
                            ...state.activeChat,
                            ...action.payload
                        }
                    }
                }
            }
            return {
                ...state,
                list: state.list.slice(),
                orderList: state.orderList.slice()
            };
        case 'LOAD_ORDER_LIST':
            return {
                ...state,
                orderList: action.payload
            };
        case 'ADD_ORDER_TO_LIST':
            return {
                ...state,
                orderList: [
                    ...state.orderList,
                    action.payload
                ]
            };
        case 'REMOVE_ORDER_FROM_LIST':
            break;
            // return {
            //     ...state,
            //     orderList: [
            //         ...state.orderList,
            //         action.payload
            //     ]
            // };
        case 'UPDATE_ORDER_INFO':
            state.orderList.map(item => {
                if (item.chat) {
                    let chat = action.payload.find(chatItem => (item.chat._id == chatItem.chat._id));
                    if (chat) {
                        item.chat.lastTimestamp = chat.chat.lastTimestamp;
                        item.chat.count = chat.chat.count;
                        item.chat.readCount = chat.chat.readCount;
                        item.order = { ...chat.chat.order };
                    }
                } else return item;
            });
            return {
                ...state,
                orderList: state.orderList.slice()
            }
        case 'SET_ACTIVE_CHAT':
            return {
                ...state,
                activeChat: action.payload
            }
        case 'LOAD_GROUP_LIST':
            return {
                ...state,
                groups: action.payload
            };
        case 'ADD_GROUP_TO_LIST':
            return {
                ...state,
                groups: [
                    ...state.groups,
                    action.payload
                ]
            };
        case 'UPDATE_GROUP_INFO':
            let newState = {
                ...state,
                groups: [
                    ...state.groups.map(item => {
                        if (item._id === action.payload._id) {
                            return {
                                ...item,
                                ...action.payload
                            }
                        } else return item;
                    }),
                ]
            };
            if (state.activeChat && state.activeChat._id === action.payload._id) {
                newState.activeChat = {
                    ...state.activeChat,
                    ...action.payload
                };
            }
            return newState;
        default:
            return state;
    }
};