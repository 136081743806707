import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import moment from 'moment';

export const convertRating = (value = 0, procent = true) => {
    if (typeof value === 'string') value = parseInt(value);
    if (procent) {
        if (value >= 500000) return 5;
        if (value >= 250000) return 4;
        if (value >= 50000) return 3;
        if (value >= 10000) return 2;
        if (value >= 1000) return 1;
        return null;
    } else {
        if (value >= 500) return 500;
        if (value >= 400) return 400;
        if (value >= 300) return 300;
        if (value >= 200) return 200;
        if (value >= 100) return 100;
        return null;
    }
}

const loop = [1, 2, 3, 4, 5];

class Rating extends React.Component {
    _getRating() {
        let value = (this.props.value) ? parseInt(this.props.value) : 0;
        if (this.props.procent) {
            if (value >= 500000) return 5;
            if (value >= 250000) return 4;
            if (value >= 50000) return 3;
            if (value >= 10000) return 2;
            if (value >= 1000) return 1;
            return 0;
        } else {
            if (value >= 500) return 5;
            if (value >= 400) return 4;
            if (value >= 300) return 3;
            if (value >= 200) return 2;
            if (value >= 100) return 1;
            return 0;
        }
    }
    
    _getValue(rating) {
        let value = (this.props.value) ? parseInt(this.props.value) : 0;
        if (this.props.procent) {
            if (rating == 1) return 1000;
            if (rating == 2) return 10000;
            if (rating == 3) return 50000;
            if (rating == 4) return 250000;
            if (rating == 5) return 500000;
            return 0;
        } else {
            if (rating == 1) return 100;
            if (rating == 2) return 200;
            if (rating == 3) return 300;
            if (rating == 4) return 400;
            if (rating == 5) return 500;
            return 0;
        }
    }
    
    render() {
        return (
            <View style={[this.props.style, { flexDirection: 'row', justifyContent: 'space-between'}]}>
                {loop.map(key => {
                    let key_title = '';
                    if (this.props.procent) {
                        if (key == 1) key_title = '1000';
                        if (key == 2) key_title = '10 000';
                        if (key == 3) key_title = '50 000';
                        if (key == 4) key_title = '250 000';
                        if (key == 5) key_title = '500 000';
                    } else {
                        if (key == 1) key_title = '100';
                        if (key == 2) key_title = '200';
                        if (key == 3) key_title = '300';
                        if (key == 4) key_title = '400';
                        if (key == 5) key_title = '500';
                    }
                    return (
                        <View key={key} style={{ alignItems: 'center' }}>
                            { this.props.procent ? <Text style={styles.procent}>{key}%</Text> : null }
                            <IconButton
                                style={{ margin: 0 }}
                                icon="star"
                                color={(this._getRating() >= key) ? '#FFC107' : '#CED4DA'}
                                size={24}
                                onPress={() => this.props.onChange(this._getValue(key))}
                            />
                            <Text style={styles.count}>{key_title}</Text>
                            <Text style={styles.title}>{this.props.title}</Text>
                        </View>
                    );
                })}
            </View>
        );
    }
}

Rating.defaultProps = {
    style: {},
    title: 'м3',
    value: 0,
    procent: true,
    onChange: () => {}
};

const styles = StyleSheet.create({
    procent: {
        textAlign: 'center',
        fontSize: 18,
        lineHeight: 28,
        color: '#212529'
    },
    count: {
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 16,
        color: '#495057'
    },
    title: {
        textAlign: 'center',
        fontSize: 10,
        lineHeight: 12,
        color: '#212529'
    }
});

export default Rating;