import React from 'react';
import { ScrollView, View, Text, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { TextInput, Button, IconButton } from 'react-native-paper';
import ClientInfoModalEdit from './ClientInfoModalEdit';
import ClientPaymentInfoModalEdit from './ClientPaymentInfoModalEdit';
import ClientTrucksModalEdit from './ClientTrucksModalEdit';
import NoteList from './NoteList';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MyButton from './MyButton';
import Rating from './Rating';
import moment from 'moment';
import Api from './Api';
import Alert from './Alert';
import Logger from './Logger';

import { connect } from 'react-redux';

function timestampFormat(timestamp) {
    if (!timestamp) return '';
    let date = moment.unix(timestamp);
    return date.format("DD.MM.YYYY");
}

class ClientInfo extends React.Component {
    state = {
        loading: true,
        clientRating: '',
        employeeRating: '',
        clientInfo: {
            paymentData: {},
            trucks: []
        }
    }

    componentDidMount() {
        Logger.log('debug', 'Информация о пользователе', this.props.chat);

        let customer = (this.props.chat && this.props.chat.customer) ? this.props.chat.customer : this.props.chat;

        this.setState({
            clientRating: (customer.clientRating) ? customer.clientRating.toString() : 0,
            employeeRating: (customer.employeeRating) ? customer.employeeRating.toString() : 0,
        });

        Api.request('user/getById', {
            id: customer._id,
        }).then(response => {
            if (!response.user.paymentData) response.user.paymentData = {};
            if (!response.user.trucks) response.user.trucks = [];
            this.setState({
                loading: false,
                clientInfo: response.user,
            });
        });
    }

    onSubmit() {
        this.setState({ loading: true });
        Api.request('account/saveInfo', {
            userId: (this.props.chat.customer) ? this.props.chat.customer._id : this.props.chat._id,
            form: {
                clientRating: this.state.clientRating,
                employeeRating: this.state.employeeRating,
            }
        }).then(response => {
            this.setState({ loading: false });
        });
    }

    updateClientInfo = (data) => {
        let clientInfo = {
            ...this.state.clientInfo,
            ...data
        }
        this.setState({
            clientInfo: clientInfo
        });
    }

    onAddNote = (note, hideModal) => {
        let notes = (this.state.clientInfo.notes) ? this.state.clientInfo.notes.slice() : [];
        notes.unshift(note);

        Api.request('account/saveInfo', {
            userId: (this.props.chat.customer) ? this.props.chat.customer._id : this.props.chat._id,
            form: {
                notes: notes,
            }
        }).then(response => {
            this.setState({
                clientInfo: {
                    ...this.state.clientInfo,
                    notes: notes,
                }
            });
            if (hideModal) hideModal();
        });
    }

    onBlockRequest = (hideModal) => {
        Api.request('account/saveInfo', {
            userId: (this.props.chat.customer) ? this.props.chat.customer._id : this.props.chat._id,
            form: {
                blockRequest: true,
            }
        }).then(response => {
            this.setState({
                clientInfo: {
                    ...this.state.clientInfo,
                    blockRequest: true,
                }
            });
            if (hideModal) hideModal();
        });
    }

    onBlockContact = (hideModal) => {
        Api.request('account/saveInfo', {
            userId: (this.props.chat.customer) ? this.props.chat.customer._id : this.props.chat._id,
            form: {
                block: true,
            }
        }).then(response => {
            this.setState({
                clientInfo: {
                    ...this.state.clientInfo,
                    block: true,
                }
            });
            if (hideModal) hideModal();
        });
    }

    onRemoveNote = (index) => {
        let notes = (this.state.clientInfo.notes) ? this.state.clientInfo.notes.slice() : [];
        notes.splice(index, 1);

        Api.request('account/saveInfo', {
            userId: (this.props.chat.customer) ? this.props.chat.customer._id : this.props.chat._id,
            form: {
                notes: notes,
            }
        }).then(response => {
            this.setState({
                clientInfo: {
                    ...this.state.clientInfo,
                    notes: notes,
                }
            });
        });
    }

    render() {
        let customer = (this.props.chat.customer) ? this.props.chat.customer : this.props.chat;
        let clientInfo = this.state.clientInfo;
        return (
            <View style={this.props.style}>
                {
                    (this.state.loading) ?
                    <Text style={{ color: '#212529', fontSize: 18, lineHeight: 28 }}>Загрузка...</Text>
                    :
                    <View style={{ flex: 1 }}>
                        <ScrollView style={[{ paddingTop: 18, paddingBottom: 15 }, this.props.managerStyle ? {paddingRight: 15} : {}]}>
                            {this.state.clientInfo?.block ?
                                <Text style={{ color: 'red' }}>Контакт заблокирован</Text>
                                :
                                <View>
                                {this.state.clientInfo?.blockRequest ?
                                    <Text style={{ color: 'red' }}>Отправлен запрос на блокировку контакта</Text>
                                    :
                                    null
                                }
                                </View>
                            }
                            <Text style={{ color: '#212529', fontSize: 18, lineHeight: 28 }}>{customer.name}</Text>
                            { clientInfo.nickname ?
                                <Text style={{ color: '#212529', fontSize: 18, lineHeight: 28 }}>Псевдоним: {customer.nickname}</Text>
                                :
                                null
                            }
                            <Text style={{ color: '#495057', fontSize: 16, lineHeight: 28 }}>Зарегистрирован с {timestampFormat(customer.created)}</Text>
                            <Text style={{ color: '#495057', fontSize: 16, lineHeight: 28 }}>Статус: {(customer.client) ? 'заказчик' : ''}{(customer.client && customer.employee) ? '/' : ''}{(customer.employee) ? 'перевозчик' : ''}</Text>
                            <Text style={{ color: '#212529', fontSize: 18, lineHeight: 28 }}>{customer.phone}</Text>
                            { (customer.client) ?
                            <View style={{ marginTop: 16 }}>
                                <Rating value={this.state.clientRating} onChange={value => this.setState({ clientRating: value.toString() })} />
                                <TextInput
                                    style={{ marginTop: 22 }}
                                    label='Объемы'
                                    mode='outlined'
                                    value={this.state.clientRating}
                                    onChangeText={value => this.setState({ clientRating: value })}
                                    autoCorrect={false}
                                    keyboardType="phone-pad"
                                    returnKeyType="next"
                                />
                            </View> : null }
                            { (customer.employee) ?
                            <View style={{ marginTop: 16 }}>
                                <Rating procent={false} title="перевозок" value={this.state.employeeRating} onChange={value => this.setState({ employeeRating: value.toString() })} />
                                <TextInput
                                    style={{ marginTop: 22 }}
                                    label='Кол-во перевозок'
                                    mode='outlined'
                                    value={this.state.employeeRating}
                                    onChangeText={value => this.setState({ employeeRating: value })}
                                    autoCorrect={false}
                                    keyboardType="phone-pad"
                                    returnKeyType="next"
                                />
                                <View style={{
                                    height: 1,
                                    backgroundColor: '#CED4DA',
                                    marginVertical: 19
                                }}
                                ></View>
                                <ClientTrucksModalEdit ref={(refLink) => this.trucksModal = refLink} />
                                <MyButton
                                    color="#212121"
                                    style={{ marginBottom: 10, }}
                                    onPress={() => this.trucksModal.show(clientInfo, this.updateClientInfo)}
                                >
                                    <View style={{ flexDirection: 'row' }}>
                                        <MaterialCommunityIcons
                                            style={{ marginTop: 14, marginLeft: 9, marginRight: 4 }}
                                            name="pencil" size={18} color="rgba(0, 0, 0, 0.6)"
                                        />
                                        <Text style={{
                                            textAlign: 'center',
                                            textTransform: 'uppercase',
                                            color: '#212121',
                                            lineHeight: 46
                                        }}>Редактировать технику</Text>
                                    </View>
                                </MyButton>
                                <Text style={{ fontSize: 18, lineHeight:36 }}>Техника:</Text>
                                { clientInfo.trucks.length ? 
                                    <View>
                                        {clientInfo.trucks.map((truck, index) => (
                                            <Text key={index}>
                                                {truck.name}: {truck.city}
                                                { truck.description ? '. ' + truck.description : '' }
                                            </Text>
                                        ))}
                                    </View>
                                :
                                    <Text>Техники нет</Text>
                                }
                            </View> : null }
                            <View style={{
                                    height: 1,
                                    backgroundColor: '#CED4DA',
                                    marginVertical: 19
                                }}
                            ></View>
                            <ClientInfoModalEdit ref={(refLink) => this.editModal = refLink} />
                            <MyButton
                                color="#212121"
                                style={{ marginBottom: 10, }}
                                onPress={() => this.editModal.show(clientInfo, this.updateClientInfo, this.props.accountInfo.type)}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <MaterialCommunityIcons
                                        style={{ marginTop: 14, marginLeft: 9, marginRight: 4 }}
                                        name="pencil" size={18} color="rgba(0, 0, 0, 0.6)"
                                    />
                                    <Text style={{
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        color: '#212121',
                                        lineHeight: 46
                                    }}>Личные данные</Text>
                                </View>
                            </MyButton>
                            <Text style={[styles.p, {marginBottom: 10}]}>Email {this.state.clientInfo.email ? this.state.clientInfo.email : 'не указан'}</Text>
                            { clientInfo.companyData && clientInfo.companyData.companyName ?                                
                                <Text style={styles.p}>Юр. лицо {clientInfo.companyData.companyName}</Text>
                            : null }
                            { clientInfo.companyData && clientInfo.companyData.inn ?                                
                                <Text style={styles.p}>ИНН {clientInfo.companyData.inn}</Text>
                            : null }
                            {this.state.clientInfo.verified ?
                                <Text>Данные верифицированы</Text>
                                :
                                <Text style={{ color: 'red' }}>Данные не верифицированы</Text>
                            }
                            <View style={{
                                    height: 1,
                                    backgroundColor: '#CED4DA',
                                    marginVertical: 19
                                }}
                            ></View>
                            <ClientPaymentInfoModalEdit ref={(refLink) => this.editPaymentModal = refLink} />
                            <MyButton
                                color="#212121"
                                style={{ marginBottom: 10, }}
                                onPress={() => this.editPaymentModal.show(clientInfo, this.updateClientInfo)}
                            >
                                <View style={{ flexDirection: 'row' }}>
                                    <MaterialCommunityIcons
                                        style={{ marginTop: 14, marginLeft: 9, marginRight: 4 }}
                                        name="pencil" size={18} color="rgba(0, 0, 0, 0.6)"
                                    />
                                    <Text style={{
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        color: '#212121',
                                        lineHeight: 46
                                    }}>Платежные данные</Text>
                                </View>
                            </MyButton>
                            <View style={{
                                height: 1,
                                backgroundColor: '#CED4DA',
                                marginVertical: 19
                            }}
                            ></View>
                            <NoteList
                                onAddNote={this.onAddNote}
                                onRemoveNote={this.onRemoveNote}
                                notes={this.state.clientInfo?.notes}
                            />
                            <Alert
                                ref={(refLink) => this.blockContactRequestAlert = refLink}
                                title='Блокировка контакта'
                                content='Отправить директору запрос на блокировку контакта?'
                                actions={[{
                                    title: 'Да',
                                    action: () => {
                                        this.onBlockRequest(this.blockContactRequestAlert._hideDialog);
                                    }
                                }, {
                                    title: 'Нет',
                                    action: () => this.blockContactAlert._hideDialog()
                                }]}
                            />
                            <Alert
                                ref={(refLink) => this.blockContactAlert = refLink}
                                title='Блокировка контакта'
                                content='Заблокировать контакт?'
                                actions={[{
                                    title: 'Да',
                                    action: () => {
                                        this.onBlockContact(this.blockContactAlert._hideDialog);
                                    }
                                }, {
                                    title: 'Нет',
                                    action: () => this.blockContactAlert._hideDialog()
                                }]}
                            />
                            {!this.state.clientInfo?.blockRequest && this.props.accountInfo.type !== 'Директор' ?
                                <MyButton
                                    onPress={() => this.blockContactRequestAlert.show()}
                                    style={{ marginBottom: 24 }}
                                    text="Заблокировать контакт"
                                />
                                :
                                null
                            }
                            {this.props.accountInfo.type === 'Директор' && !this.state.clientInfo?.block ?
                                <MyButton
                                    onPress={() => this.blockContactAlert.show()}
                                    style={{ marginBottom: 24 }}
                                    text="Заблокировать контакт"
                                />
                                :
                                null
                            }
                        </ScrollView>
                        <Button
                            style={[{ marginBottom: 24 }, this.props.managerStyle ? {marginRight: 15} : {}]}
                            mode="contained"
                            color="#FFC107"
                            contentStyle={{height: 48}}
                            loading={this.state.loading}
                            onPress={() => this.onSubmit()}
                        >
                            Сохранить
                        </Button>
                    </View>
                }
            </View>
        );
    }
}

ClientInfo.defaultProps = {
    style: {}
}

const styles = StyleSheet.create({
    p: {
        fontSize: 16,
        lineHeight: 26,
        color: '#212529',
    }
});

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo.data,
    }
}

export default connect(mapStateToProps, {
})(ClientInfo);