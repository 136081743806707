import React from 'react';
import { View, Platform } from 'react-native';
import { Button, Paragraph, Text, Dialog, Portal } from 'react-native-paper';

class Alert extends React.Component {
    state = {
        visible: false,
        actions: [],
    };

    componentDidMount = () => {
        this.setState({
            title: this.props.title,
            content: this.props.content,
            customContent: this.props.customContent,
            actions: this.props.actions,
            onDismiss: () => { this.props.onDismiss(this) }
        });
    }

    show = (params) => {
        if (params) {
            this.setState(state => ({
                ...state,
                ...params
            }), () => this._showDialog());
        } else {
            this._showDialog();
        }
    }

    _showDialog = () => this.setState({ visible: true });

    _hideDialog = () => this.setState({ visible: false });

    render() {
        return (
            <View>
                <Portal>
                    <Dialog
                        style={ (Platform.OS === 'web') ? { maxWidth: 360, marginHorizontal: 'auto' } : {}}
                        visible={this.state.visible}
                        onDismiss={this.state.onDismiss}
                    >
                        <Dialog.Title>{this.state.title}</Dialog.Title>
                        <Dialog.Content>
                            {this.state.customContent ? this.state.customContent : <Paragraph>{this.state.content}</Paragraph> }
                        </Dialog.Content>
                        <Dialog.Actions>
                            {this.state.actions.map((item, i) => (
                                <Button key={i} onPress={() => item.action(this)}>{item.title}</Button>
                            ))}
                        </Dialog.Actions>
                    </Dialog>
                </Portal>
            </View>
        );
    }
}

Alert.defaultProps = {
    title: '',
    content: '',
    customContent: false,
    actions: [{
        title: 'ОК',
        action: (dlg) => { dlg._hideDialog() }
    }],
    onDismiss: (dlg) => { dlg._hideDialog() }
};

export default Alert;