import Api from '../../components/Api';

export const loadChatList = () => async(dispatch) => {
    Api.request('chat/getUserList', {
    }).then(response => {
        if (response.success) {
            dispatch({
                type: 'LOAD_CHAT_LIST',
                payload: response.users
            });
        }
    });
}

export const updateChatInfo = (chatList) => {
    return {
        type: 'UPDATE_CHAT_INFO',
        payload: chatList
    }
}

export const addChatToList = (chat) => {
    return {
        type: 'ADD_CHAT_TO_LIST',
        payload: chat
    }
}

export const loadOrderList = (archive = false) => async(dispatch) => {
    Api.request('order/getList', {}).then(response => {
        if (response.success) {
            dispatch({
                type: 'LOAD_ORDER_LIST',
                payload: response.chats
            });
        }
    });
}

export const addOrderToList = (order) => {
    return {
        type: 'ADD_ORDER_TO_LIST',
        payload: order
    }
}

export const removeOrderFromList = (orderId) => {
    return {
        type: 'REMOVE_ORDER_FROM_LIST',
        payload: orderId
    }
}

export const updateOrderInfo = (orderList) => {
    return {
        type: 'UPDATE_ORDER_INFO',
        payload: orderList
    }
}

export const updateChatUserInfo = (user) => {
    return {
        type: 'UPDATE_CHAT_USER_INFO',
        payload: user
    }
}

export const setActiveChat = (chat) => {
    return {
        type: 'SET_ACTIVE_CHAT',
        payload: chat
    }
}

export const loadGroupList = () => async(dispatch) => {
    Api.request('group/getList', {
    }).then(response => {
        if (response.success) {
            dispatch({
                type: 'LOAD_GROUP_LIST',
                payload: response.groups
            });
        }
    });
}

export const addGroupToList = (group) => {
    return {
        type: 'ADD_GROUP_TO_LIST',
        payload: group
    }
}

export const updateGroupInfo = (group) => {
    return {
        type: 'UPDATE_GROUP_INFO',
        payload: group
    }
}