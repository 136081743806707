import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { AppState, ScrollView, TouchableOpacity, View, Text, StyleSheet, RefreshControl } from 'react-native';
import { Switch } from 'react-native-paper';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import TextField from '../components/TextField';
import { Entypo } from '@expo/vector-icons'; 
import OrderItem from '../components/OrderItem';
import Alert from '../components/Alert';
import Api from '../components/Api';
import App from '../components/App';

const Tab = createMaterialTopTabNavigator();

import { connect } from 'react-redux';
import { loadAccountInfo, saveAccountInfo, updateAccountInfo } from '../redux/actions/account';

class OrderListScreen extends React.Component {

    state = {
        search: '',
    }

    _onRefresh = () => {
        this.props.onRefresh();
    }
    
    _order = (item, price) => {
        return new Promise((resolve, reject) => {
            if (price) item.price = price;
            Api.request('chat/bookOrder', item).then(response => {
                if (response.success) {
                    resolve(response);
                } else {
                    reject();
                }
            });
        });
    }    

    order = (item, price) => {
        this.alert.show({
            title: item.name,
            content: 'Отправить заявку диспетчеру?',
            actions: [{
                title: 'Отправить',
                action: async (dialog) => {
                    let response = await this._order(item, price);
                    if (response.notVerified) {
                        dialog._hideDialog();
                        this.alert.show({
                            title: 'Личные данные не верифицированы',
                            content: 'Чтобы брать заказы, нужно заполнить данные компании и обратиться к менеджеру через чат с просьбой проверить данные',
                            actions: [{
                                title: 'Заполнить данные',
                                action: dialog => {
                                    dialog._hideDialog();
                                    this.props.navigation.navigate('Account', { screen: 'AccountInfo' });
                                }
                            }, {
                                title: 'Отмена',
                                action: dialog => dialog._hideDialog()
                            }]
                        });
                    } else if (response.requestDuplication) {
                        dialog._hideDialog();
                        this.alert.show({
                            title: item.name,
                            content: 'Ваша заявка на рассмотрении, пожалуйста ожидайте ответа диспетчера',
                            actions: [{
                                title: 'ОК',
                                action: dialog => dialog._hideDialog()
                            }]
                        });
                    } else {
                        dialog._hideDialog();
                        this.props.navigation.navigate('Chat');
                    }
                }
            }, {
                title: 'Отмена',
                action: dialog => dialog._hideDialog()
            }]
        });
    }

    _renderSearchClearButton = () => {
        if (this.state.search) {
            return (
                <TouchableOpacity
                    onPress={this._onClearSearch}
                >
                    <Entypo name="cross" size={18} color="#666" />
                </TouchableOpacity>
            );
        } else {
            return null;
        }
    }

    _onClearSearch = () => {
        this.setState({ search: '' }, () => this.searchInput.setValue(''));
    }

    _onToggleLineSwitch = (value) => {
        this.props.onSilenceModeChange(!value);
    }
    
    render() {
        return (
            <ScrollView
                style={styles.container}
                refreshControl={
                    <RefreshControl refreshing={this.props.loading} onRefresh={this._onRefresh} />
                }
            >
                <Alert ref={(node) => { this.alert = node}} />
                <View
                    style={{
                        flexDirection: 'row',
                        marginBottom: 20,
                    }}
                >
                    <Switch
                        color="#FFC107"
                        value={!this.props.silenceMode}
                        onValueChange={this._onToggleLineSwitch}
                    />
                    <View style={{ marginLeft: 16 }}>
                        <Text style={{
                            color: '#212529',
                            fontSize: 18,
                            fontWeight: 'bold',
                            lineHeight: 24,
                        }}>
                            Я на линии
                        </Text>
                        <Text style={{
                            color: '#212529',
                            fontSize: 16,
                            lineHeight: 24,
                        }}>
                            (Уведомляем о всех заказах)
                        </Text>
                    </View>
                </View>
                <TextField
                    ref={refLink => this.searchInput = refLink}
                    label='Найти...'
                    mode='outlined'
                    value={this.state.search}
                    onChangeText={(value) => this.setState({ search: value })}
                    autoCorrect={false}
                    returnKeyType="search"
                    renderRightAccessory={this._renderSearchClearButton}
                    style = {{
                        marginBottom: 20,
                    }}
                />
                { this.props.orderList.map((chat, i) => {
                    if (this.state.search) {
                        let message = chat.name ? chat.name : '';
                        for (let v in chat.order) {
                            let val = chat.order[v];
                            if (val && !Array.isArray(val)) {
                                message = message + ' ' + val.toString();
                            }
                        }
                        message = message.toLowerCase();
                        if (message.indexOf(this.state.search.toLowerCase()) === -1) return null;
                    }

                    return (
                        <OrderItem key={i} style={{ marginBottom: 32 }} order={chat} onOrder={this.order} />
                    );
                })}
            </ScrollView>
        );
    }
}

OrderListScreen.defaultProps = {
    orderList: []
}

class OrdersScreen extends React.Component {
    
    state = {
        orders: [],
        loading: false
    }
    
    componentDidMount() {
        this.props.loadAccountInfo();

        Api.request('order/getList', {
            state: 'Опубликован'
        }).then(response => {
            if (response.success) {
                this.setState({ orders: response.chats });
            }
            
            Api.socket.on('updateOrderList', () => this._onRefresh());
        });

        AppState.addEventListener("change", this._handleAppStateChange);
    }

    componentWillUnmount() {
        Api.socket.off('updateOrderList', this.listen);
        AppState.removeEventListener("change", this._handleAppStateChange);
    }

    _handleAppStateChange = (nextAppState) => {
        if (nextAppState === 'active') {
            this._onRefresh();
        }
    }

    _onRefresh = () => {
        this.setState({ loading: true });
        Api.request('order/getList', {
            state: 'Опубликован'
        }).then(response => {
            if (response.success) {
                this.setState({ orders: response.chats });
            }
            this.setState({ loading: false });
        });
    }

    _onSilenceModeChange = (silenceMode) => {
        this.props.updateAccountInfo({ silence: silenceMode });
        this.props.saveAccountInfo({ silence: silenceMode });
    }

    filter(list, filter) {
        if (filter === 'auction') {
            return list.filter(item => item.order_type === 'auction');
        } else {
            return list.filter(item => item.order_type !== 'auction');
        }
    }
    
    render() {
        
        return (
            <Tab.Navigator>
              <Tab.Screen name="Активные" component={() => <OrderListScreen loading={this.state.loading} onRefresh={this._onRefresh} orderList={this.filter(this.state.orders, 'orders')} navigation={this.props.navigation} silenceMode={this.props.accountInfo.silence} onSilenceModeChange={this._onSilenceModeChange} />} />
              <Tab.Screen name="Торги" component={() => <OrderListScreen loading={this.state.loading} onRefresh={this._onRefresh} orderList={this.filter(this.state.orders, 'auction')} navigation={this.props.navigation} silenceMode={this.props.accountInfo.silence} onSilenceModeChange={this._onSilenceModeChange}  />} />
            </Tab.Navigator>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 32,
        paddingHorizontal: 16,
        backgroundColor: '#fff'
    }
})

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo.data
    }
}

export default connect(mapStateToProps, {
    loadAccountInfo,
    saveAccountInfo,
    updateAccountInfo,
})(OrdersScreen);