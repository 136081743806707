import React, { Component } from 'react';
import { StyleSheet, ScrollView, View, Text, Image, TouchableOpacity } from 'react-native';
import { Button, TextInput, RadioButton } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import Checkbox from '../components/Checkbox';
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import Alert from '../components/Alert';
import Api from './Api';

import { connect } from 'react-redux';

class Form extends ValidationComponent {
    state = {
      loading: false,
      customerId: false,
      customerPhone: '',
      customerName: '',
      newCustomer: false,
      createCustomer: false,
      orderType: 'auction',
      description: ''
    };

    componentDidMount() {
        if (this.props.activeChat) {
            let customer = (this.props.activeChat.customer) ? this.props.activeChat.customer : this.props.activeChat;
            this.setState({
                customerPhone: (customer.phone) ? customer.phone.toString() : ''
            }, state => {
                this.checkUser(this.state.customerPhone); 
            });
        }
    }

    validRules = {
        customerPhone: {required: true},
        customerName: {required: this.state.newCustomer},
        description: {required: true},
    };

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules)) {
            let data = {
                description: this.state.description,
            };

            this.alert.show({
                title: 'Разместить лот?',
                content: `Создание лота для ${this.state.customerName} ${this.state.customerPhone}`,
                actions: [{
                    title: 'Отмена',
                    action: dialog => dialog._hideDialog()
                }, {
                    title: 'Отправить',
                    action: dialog => {
                        Api.request('order/create', {
                            userId: this.state.customerId,
                            orderType: this.state.orderType,
                            newCustomer: this.state.newCustomer,
                            customerPhone: this.state.customerPhone.replace(/\D+/g,''),
                            customerName: this.state.customerName,
                            form: data
                        }).then(response => {
                            dialog._hideDialog();
                            this.setState({ loading: false });
                            if (response.success) {
                                if (this.props.onSubmit) this.props.onSubmit();
                            } else {
                                Api.alert();
                            }
                        });
                    }
                }]
            });
        }
    };

    checkUser(phone) {
        if (typeof phone === 'number') phone = phone.toString();
        phone = phone.replace(/\D+/g,'');
        Api.request('user/getByPhone', {
            phone: phone
        }, {}, false).then(response => {
            if (response.success) {
                let userName = [
                    response.user.last_name,
                    response.user.name,
                    response.user.second_name,
                ].join(' ');
                this.setState({
                    newCustomer: false,
                    createCustomer: false,
                    customerId: response.user._id,
                    customerName: userName,
                });
            } else {
                this.setState({
                    customerId: false,
                    newCustomer: true,
                    createCustomer: false
                });
            }
        });
    }

    handleCustomerPhone(value) {
        this.setState({ customerPhone: value }, state => {
            if (this.state.customerPhone.length === 18) {
                this.checkUser(this.state.customerPhone);
            }
        });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <Alert ref={(node) => { this.alert = node}} />
                <ScrollView style={{ paddingLeft: 23, paddingRight: 15 }}>
                    <TextInput style={{ flex: 1 }}
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Заказчик (телефон)'
                        mode='outlined'
                        value={this.state.customerPhone}
                        onChangeText={value => this.handleCustomerPhone(value)}
                        autoCorrect={false}
                        keyboardType="phone-pad"
                        returnKeyType="next"
                        render={props =>
                            <TextInputMask
                                {...props}
                                type={'custom'}
                                options={{
                                    mask: '+7 (999) 999-99-99'
                                }}
                            />
                        }
                        error={this.isFieldInError('customerPhone')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Заказчик (имя)'
                        mode='outlined'
                        value={this.state.customerName}
                        disabled={!this.state.newCustomer}
                        onChangeText={value => this.setState({ customerName: value })}
                    />
                    { this.state.newCustomer ?
                    <View>
                        <Text style={{ marginLeft: 14, fontSize: 14, lineHeight: 18, color: 'red' }}>Заказчика нет в базе</Text>
                        <Checkbox
                            title='добавить заказчика в базу'
                            onCheck={value => this.setState({ createCustomer: value }) }
                        />
                    </View> : null }
                    <View style={{ height: 1, backgroundColor: '#CED4DA', marginTop: 10, marginBottom: 19 }}></View>
                    <TextInput
                        style={styles.textArea}
                        label='Описание лота'
                        multiline={true}
                        numberOfLines={10}
                        mode='outlined'
                        value={this.state.description}
                        onChangeText={value => this.setState({ description: value })}
                        error={this.isFieldInError('description')}
                    />
                </ScrollView>
                <Button
                    style={{ marginLeft: 23, marginRight: 30, marginBottom: 24 }}
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    loading={this.state.loading}
                    disabled={this.state.customerPhone.replace(/\D+/g,'').length != 11 || (this.state.newCustomer && !this.state.createCustomer)}
                    onPress={() => this.onSubmit()}
                >
                    Разместить лот
                </Button>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    imageContainer: {
        width: 40
    },
    image: {
        width: 32,
        height: 147,
        marginTop: 50
    },
    formContainer: {
        flex: 1,
    },
    label: {
        marginBottom: 7,
        fontSize: 21,
        lineHeight: 25,
        color: '#212529'
    },
    button: {
        padding: 14,
        paddingVertical: 14
    },
    buttonText: {
        fontSize: 16,
        lineHeight: 19
    },
    textInput: {
        fontSize: 16,
        height: 48,
        backgroundColor: '#fff',
    },
    textInputBottom: {
        marginBottom: 10
    },
    textArea: {
        fontSize: 16,
        backgroundColor: '#fff',
        marginBottom: 20
    }
});

const mapStateToProps = state => {
    return {
        activeChat: state.chatList.activeChat,
    }
}

export default connect(mapStateToProps, null)(Form);