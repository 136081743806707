import React, { Component } from 'react';
import { Platform, View } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { OutlinedTextField } from './Textfield';

class TextField extends Component {

    setValue = (value) => {
        if (this._input && this._input.setValue) this._input.setValue(value);
    }
    
    render() {
        let style = this.props.style ? this.props.style : {};
        return (
            <View style={style}>
            { Platform.OS === 'web' ?
                <TextInput
                    {...this.props}
                    style={{}}
                    mode='outlined'
                />
            :
                <OutlinedTextField
                    ref={refLink => this._input = refLink}
                    {...this.props}
                    style={{}}
                    inputContainerStyle={{ backgroundColor: '#f6f6f6'}}
                    baseColor='rgba(0, 0, 0, .54)'
                />
            }
            </View>
        );
    }
}

export default TextField;