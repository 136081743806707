import React from 'react';
import { AsyncStorage } from 'react-native';

let id;

const generate = () => {
    const gen = count => {
        let out = ''
        for (let index = 0; index < count; index++) {
            out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        }
        return out
    }

    return [gen(2), gen(1), gen(1), gen(1), gen(3)].join('-')
}

const UniqueId = () => {
    return new Promise((resolve, reject) => {
        if (id) {
            resolve(id);
        } else {
            AsyncStorage.getItem('__uniqueId').then(data => {
                if (data == null) {
                    const generatedId = generate();
                    AsyncStorage.setItem('__uniqueId', generatedId).then(() => {
                        id = generatedId;
                        resolve(id);
                    });
                } else {
                    id = data;
                    resolve(id);
                }
            });
        }
    });
}

export default UniqueId;