import React, { Component } from 'react';
import { Vibration } from 'react-native';
import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import { KeyboardAvoidingView, AppState, AsyncStorage, Platform, SafeAreaView, View, Text, StatusBar, StyleSheet } from 'react-native';
import { Provider } from 'react-native-paper';
import Spinner from 'react-native-loading-spinner-overlay';
import SpinnerController from './components/Spinner';
import AppNavigator from './navigation/AppNavigator';
import ManagerDrawerNavigation from './navigation/ManagerDrawerNavigation';
import LoginScreen from './screens/LoginScreen';
import DirectorScreen from './screens/DirectorScreen';
import Api from './components/Api';
import App from './components/App';
import defaultStyles from './styles/main.js';
import Sound from './components/Sound';
import Logger from './components/Logger';

import { Provider as ReduxProvider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './redux/reducers';

const store = createStore(reducers, applyMiddleware(ReduxThunk));
Api.store = store;

class InertMarket extends Component {
    state = {
        isLoggedIn: false,
        inited: false,
        accountType: false,
        client: false,
        employee: false,
        spinner: false,
        expoPushToken: '',
        user: {}
    };

    componentDidMount() {
        App(this);
        SpinnerController.init(this);
        
        AsyncStorage.getItem('token').then(async (token) => {
            if (token !== null) {
                Api.checkAuth(token).then((response) => {
                    if (response.auth) {
                        Api.token = token;
                        this.setState({ isLoggedIn: true, inited: true, accountType: response.accountType, client: response.user.client, employee: response.user.employee, user: response.user });
                        console.log('check grants');
                        this.registerForPushNotificationsAsync();
                        this._notificationSubscription = Notifications.addListener(this._handleNotification);
                    } else {
                        this.setState({ isLoggedIn: false, inited: true, accountType: false });
                    }
                });
            } else {
                this.setState({ inited: true });
            }
        });

        Api.socket.on('sound/incomingMessage', (data) => {
            // Воспроизведение звука
            if (Sound().ready) {
                if (Platform.OS == 'web') {
                    if (AppState.currentState === 'background' || !this.props.activeChat || data.chatId != this.props.activeChat.chat._id) {
                        Logger.log('debug', 'Активировано звуковое оповещение', data);
                        Sound().incoming();
                        //Api.notify('Получено сообщение');
                    }
                }
            }

            // Вибрация
            if (!this.props.activeChat || data.chatId !== this.props.activeChat.chat._id) {
                Logger.log('debug', 'Активирована вибрация', data);
                Vibration.vibrate(200);
            }
        });
        
        AppState.addEventListener("change", nextAppState => {
            Logger.log('debug', 'Изменилось состояние', nextAppState);
            if (Platform.OS !== 'web') {
                if (nextAppState === 'inactive' || nextAppState === 'background') {
                    Api.waitConnection = true;
                } else {
                    if (Api.socket.connected) Api.waitConnection = false;
                }
            }
        });
    };

    changeClientType = (state) => {
        this.setState(state);
    }

    registerForPushNotificationsAsync = async () => {
        if (Constants.isDevice && Platform.OS !== 'web') {
            const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
                const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                console.log('Failed to get push token for push notification!');
                return;
            }
            token = await Notifications.getExpoPushTokenAsync();
            console.log(token);
            Api.pushToken = token;
            this.setState({ expoPushToken: token });
            Api.request('pushToken', {
                pushToken: Api.pushToken
            });
        }

        if (Platform.OS === 'android') {
            Notifications.createChannelAndroidAsync('default', {
                name: 'default',
                sound: true,
                priority: 'max',
                vibrate: [0, 250, 250, 250],
            });
        }
    };

    _handleNotification = notification => {
        //Vibration.vibrate();
        console.log(notification);
//        this.setState({ notification: notification });
    };

    login(token, user) {
        Api.token = token;
        AsyncStorage.setItem('token', token);
        this.setState({ isLoggedIn: true, accountType: user.accountType, client: user.client, employee: user.employee, user: user });
        this.registerForPushNotificationsAsync();
        this._notificationSubscription = Notifications.addListener(this._handleNotification);
    };

    logout() {
        this.setState({ isLoggedIn: false, accountType: false });
        AsyncStorage.removeItem('token');
    };

    render() {
        if (this.state.inited) {
            if (this.state.isLoggedIn) {
                if (this.state.accountType == 'manager') {
                    return (
                        <SafeAreaView style={styles.container}>
                            <Provider>
                                <ManagerDrawerNavigation />
                            </Provider>
                        </SafeAreaView>
                    );
                } else if (this.state.accountType == 'director') {
                    return (
                        <SafeAreaView style={styles.container}>
                            <Provider>
                                <DirectorScreen user={this.state.user} />
                            </Provider>
                        </SafeAreaView>
                    );
                } else {
                    return (
                        <SafeAreaView style={styles.container}>
                            <Provider>
                                <AppNavigator client={this.state.client} employee={this.state.employee} />
                            </Provider>
                        </SafeAreaView>
                    );
                }
            } else {
                return (
                    <SafeAreaView style={styles.container}>
                        {Platform.OS !== 'web' ?
                        <Spinner
                            visible={this.state.spinner}
                            textContent={'Загрузка...'}
                        />
                        : null }
                        <Provider>
                            <LoginScreen loginCallback={(token, user) => this.login(token, user)} />
                        </Provider>
                    </SafeAreaView>
                );
            }
        } else {
            return (
                <SafeAreaView style={styles.container}>
                    <View style={styles.startScreen}>
                        <Text>Загрузка...</Text>
                    </View>
                </SafeAreaView>
            );
        }
    
    }
};

// export default InertMarket;
const mapStateToProps = state => {
    return {
        activeChat: state.chatList.activeChat
    }
}
const InertMarketReduxConnected = connect(mapStateToProps, null)(InertMarket);

import { setStatusBar } from './components/StatusBarCheat';

setStatusBar(StatusBar);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#E5E5E5',
        paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight: 0
    },
    startScreen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
});

class InertMarketApp extends Component {
    render() {
        return (
            <ReduxProvider store={store}>
                <InertMarketReduxConnected />
            </ReduxProvider>
        );
    }
}

export default InertMarketApp;