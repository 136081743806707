var app = false;

const App = function(instance) {
    if (instance !== undefined) {
        app = instance;
    } else {
        return app;
    }
}

export default App;