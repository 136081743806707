import React from 'react';
import { Platform, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialIcons } from '@expo/vector-icons';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import DirectorOrdersScreen from './DirectorOrdersScreen.js';
import DirectorChatsScreen from './DirectorChatsScreen.js';
import DirectorProfileScreen from './DirectorProfileScreen.js';

const Tab = createBottomTabNavigator();

import { connect } from 'react-redux';
import { loadAccountInfo } from '../redux/actions/account';

class DirectorScreen extends React.Component {

    componentDidMount() {
        this.props.loadAccountInfo();
    }
    
    renderProfileButton = () => {
        return <DirectorProfileScreen user={this.props.user} />;
    }
    
    render() {
        return (
            <View style={ (Platform.OS == 'web') ? { flex: 1, width: 640, marginHorizontal: 'auto' } : { flex: 1} }>
                <NavigationContainer>
                    <Tab.Navigator>
                        <Tab.Screen
                            name = "Orders"
                            component = {DirectorOrdersScreen}
                            options = {{
                                tabBarLabel: 'Заказы',
                                tabBarIcon: ({ color, size}) => (
                                    <MaterialIcons name="dns" color={color} size={size} />
                                )
                            }}
                        />
                        <Tab.Screen
                            name = "Chats"
                            component = {DirectorChatsScreen}
                            options = {{
                                tabBarLabel: 'Чаты',
                                tabBarIcon: ({ color, size}) => (
                                    <MaterialIcons name="chat" color={color} size={size} />
                                )
                            }}
                        />
                        <Tab.Screen
                            name = "Profile"
                            component = {this.renderProfileButton}
                            options = {{
                                tabBarLabel: 'Профиль',
                                tabBarIcon: ({ color, size}) => (
                                    <Icon name="account" color={color} size={size} />
                                )
                            }}
                        />
                    </Tab.Navigator>
                </NavigationContainer>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo.data
    }
}

export default connect(mapStateToProps, {
    loadAccountInfo
})(DirectorScreen);