import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import Api from '../components/Api';

class Form extends React.Component {
    render() {
        return (
            <View style={[styles.formContainer, formStyles.formShadowContainer]}>
                <Text style={formStyles.title}>Регистрация в ИнертМаркете</Text>
                <Text style={[styles.h2, {marginBottom: 20}]}>Пожалуйста, выберите кто Вы.</Text>
                <Button
                    style={{ marginBottom: 12 }}
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    onPress={() => this.props.navigation.navigate('ClientRegistration')}
                >
                    Заказчик
                </Button>
                <Text style={[styles.h3, { marginBottom: 30 }]}>Для заказов инертных материалов и спецтехники</Text>
                <Button
                    style={{ marginBottom: 12 }}
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    onPress={() => this.props.navigation.navigate('EmployeeRegistration')}
                >
                    Владелец техники
                </Button>
                <Text style={styles.h3}>Для получение заявок и участие в торгах на бирже</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    h2: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    h3: {
        color: '#495057',
        fontSize: 16,
        lineHeight: 24
    },
    formContainer: {
        marginHorizontal: 16
    }
})

export default Form