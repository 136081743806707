import React from 'react';
import { Platform, KeyboardAvoidingView, View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import AuthForm from '../components/AuthForm';
import ChooseRegForm from '../components/ChooseRegForm';
import ClientRegForm from '../components/ClientRegForm';
import EmployeeRegForm from '../components/EmployeeRegForm';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

const Stack = createStackNavigator();

class AuthScreen extends React.Component {
    state = {
        form: 'reg'
    };

    initAuthForm = (phone) => {
        this.setState({
            form: 'login'
        }, () => {
            this.authFormRef.handleLoginChange(phone);
        });
    }
    
    render() {
        return (
            <KeyboardAwareScrollView>
                <View style={{ alignItems: 'center', paddingBottom: 10 }}>
                    <View style={[styles.maxWidth, styles.titleContainer]}>
                        <View style={styles.imageContainer}>
                            <Image style={styles.image} source={require('../img/logo.png')} />
                        </View>
                        <Text style={styles.title}>Биржа инертных материалов</Text>
                        <Text style={styles.title}>Перевозка сыпучих грузов</Text>
                        <Text style={styles.title}>Аренда спецтехники</Text>
                    </View>
                </View>
                { (Platform.OS != 'web') ?
                <View style={{ alignItems: 'center' }}>
                    <View
                        style={[styles.maxWidth, { width: '100%', zIndex: 2, elevation: 2 }]}
                    >
                        <View style={[
                            styles.maxWidth,
                            {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                
                                marginHorizontal: 16,
                            }
                        ]}>
                            <View style={{
                                flex: 1,
                            }}>
                                <TouchableOpacity
                                    onPress={() => this.setState({ form: 'reg' })}
                                >
                                    <Text style={{
                                        lineHeight: 48,
                                        fontSize: 14,
                                        textTransform: 'uppercase',
                                        textAlign: 'center'
                                    }}>Регистрация</Text>
                                    { this.state.form == 'reg' ?
                                    <View style={{
                                        height: 3,
                                        backgroundColor: '#212529',
                                    }}></View>
                                    : null }
                                </TouchableOpacity>
                            </View>
                            <View style={{
                                flex: 1
                            }}>
                                <TouchableOpacity
                                    onPress={() => this.setState({ form: 'login' })}
                                >
                                    <Text style={{
                                        lineHeight: 48,
                                        fontSize: 14,
                                        textTransform: 'uppercase',
                                        textAlign: 'center'
                                    }}>Вход</Text>
                                    { this.state.form == 'login' ?
                                    <View style={{
                                        height: 3,
                                        backgroundColor: '#212529',
                                    }}></View>
                                    : null }
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{ zIndex: 1, elevation: 1, marginTop: -1, width: '100%', marginBottom: 24, height: 1, backgroundColor: '#868E96'}}
                    ></View>
                    <View style={[styles.maxWidth, { width: '100%', marginBottom: 20 }]}>
                        { this.state.form == 'reg' ?
                        <ChooseRegForm navigation={this.props.navigation} />
                        :
                        <AuthForm ref={refLink => this.authFormRef = refLink} loginCallback={this.props.loginCallback} />
                        }
                    </View>
                </View>
                :
                <View style={[ styles.maxWidth, { width: '100%', marginHorizontal: 'auto'}]}>
                    <AuthForm ref={refLink => this.authFormRef = refLink} loginCallback={this.props.loginCallback} />
                </View>
                }
            </KeyboardAwareScrollView>
        );
    }
}

class LoginScreen extends React.Component {
    
    AuthScreen = ({ navigation }) => {
        return (
            <AuthScreen ref={refLink => this.authRef = refLink} loginCallback={this.props.loginCallback} navigation={navigation} />
        );
    }
    
    ClientRegistration = ({ navigation }) => {
        return (
            <KeyboardAwareScrollView>
                <View style={{ alignItems: 'center', paddingTop: 24 }}>
                    <View style={{ flex: 1 }}>
                        <ClientRegForm
                            style={{ flex: 1 }}
                            onReg={(phone) => {
                                this.authRef.initAuthForm(phone);
                                navigation.navigate('SuccessRegistration');
                            }}
                        />
                    </View>
                </View>
            </KeyboardAwareScrollView>
        );
    }
    
    EmployeeRegistration = ({ navigation }) => {
        return (
            <KeyboardAwareScrollView>
                <View style={{ alignItems: 'center', paddingTop: 24 }}>
                    <EmployeeRegForm
                        onReg={(phone) => {
                            this.authRef.initAuthForm(phone);
                            navigation.navigate('SuccessRegistration');
                        }}
                    />
                </View>
            </KeyboardAwareScrollView>
        );
    }
    
    SuccessRegistration = ({ navigation }) => {
        return (
            <KeyboardAwareScrollView>
                <View style={{ alignItems: 'center', paddingVertical: 24, paddingHorizontal: 16 }}>
                    <Text style={{ fontSize: 18, lineHeight: 28, marginBottom: 25 }}>Регистрация прошла успешно. Пароль был выслан по SMS.</Text>
                    <Button
                        contentStyle={{ height: 48 }}
                        mode="contained"
                        color="#FFC107"
                        onPress={() => navigation.navigate('Auth')}
                    >
                            Вернуться на страницу входа
                    </Button>
                </View>
            </KeyboardAwareScrollView>
        );
    }
    
    render() {
        return (
            <NavigationContainer>
                <Stack.Navigator>
                    <Stack.Screen name="Auth" options={{headerShown: false, title: ''}} component={this.AuthScreen} />
                    <Stack.Screen name="ClientRegistration" options={{ title: 'Заказчик' }} component={this.ClientRegistration} />
                    <Stack.Screen name="EmployeeRegistration" options={{ title: 'Владелец техники' }} component={this.EmployeeRegistration} />
                    <Stack.Screen name="SuccessRegistration" options={{ title: 'Регистрация успешна' }} component={this.SuccessRegistration} />
                </Stack.Navigator>
            </NavigationContainer>
        );
    }
}
    
const styles = StyleSheet.create({
    maxWidth: {
        maxWidth: 360
    },
    titleContainer: {
        marginTop: 35,
        marginHorizontal: 32,
        marginBottom: 10
    },
    imageContainer: {
        marginBottom: 24
    },
    image: {
        width: 295,
        height: 63
    },
    title: {
        fontSize: 16,
        lineHeight: 26,
        color: '#212529',
    }
})

export default LoginScreen