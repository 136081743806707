import { Linking } from 'expo';
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Platform } from 'react-native';

import { Alert } from 'react-native'

export default async function getPermissionAsync(permission) {
  const { status } = await Permissions.askAsync(permission)
  if (status !== 'granted') {
    const permissionName = permission.toLowerCase().replace('_', ' ')
    Alert.alert(
      'Внимание!',
      `Если вы хотите использовать данную функцию, то вам необходимо разрешить ${permissionName} в настройках телефона.`,
      [
        {
          text: "Настройки",
          onPress: () => Linking.openURL('app-settings:'),
        },
        { text: 'Отмена', onPress: () => {}, style: 'cancel' },
      ],
      { cancelable: true },
    )

    return false
  }
  return true
}

export async function getLocationAsync(callback) {
  if (await getPermissionAsync(Permissions.LOCATION)) {
    const location = await Location.getCurrentPositionAsync({})
    if (location) {
        callback({ location: location.coords });
        return location.coords;
    }
  }
}

export async function pickDocumentAsync(callback) {
    const result = await DocumentPicker.getDocumentAsync();
    if (!result.cancelled) {
        callback(result);
        return result.uri;
    }
}

export async function pickImageAsync(callback) {
    if (Platform.OS == 'web') {
        const result = await ImagePicker.launchImageLibraryAsync();

        if (!result.cancelled) {
            callback(result);
            return result.uri;
        }
    } else {
        if (await getPermissionAsync(Permissions.CAMERA_ROLL)) {
            const result = await ImagePicker.launchImageLibraryAsync()

            if (!result.cancelled) {
                console.log(result);
                callback(result);
                return result.uri;
            }
        }
    }
}

export async function takePictureAsync(callback) {
  if (await getPermissionAsync(Permissions.CAMERA)) {
    const result = await ImagePicker.launchCameraAsync({
//      allowsEditing: true,
//      aspect: [4, 3],
    })

    if (!result.cancelled) {
      callback(result);
      return result.uri;
    }
  }
}