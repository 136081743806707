import React from 'react';
import { ScrollView, View, Text, StyleSheet } from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import ScrollViewKB from '../components/ScrollViewKB';
import { Button, Switch, TextInput, IconButton } from 'react-native-paper';
import TextField from '../components/TextField';
import ValidationComponent from 'react-native-form-validator';
import { TextInputMask } from 'react-native-masked-text'
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import Rating from '../components/Rating';
import App from '../components/App';
import Api from '../components/Api';
import Alert from '../components/Alert';
import { alertMsg } from '../components/Api';

class ClientScreen extends ValidationComponent {

    componentDidMount() {}
    
    _onToggleClientSwitch = () => {
        this.props.onChange({ client: !this.props.formData.client });
    }

    _onTogglePaymentMethodSwitch = () => {
        this.props.onChange({ paymentMethod: (this.props.formData.paymentMethod === 'card') ? 'invoice' : 'card' });
    }

    _onPaymentDataChange = data => {
        this.props.onChange({
            paymentData: {
                ...this.props.formData.paymentData,
                ...data,
            }
        });
    }

    validRules = {
        name: {required: true},
        phone: {required: true},
    }

    onRatingChange = () => {
        this.Alert.show({
            title: 'Рейтинг',
            content: 'Для изменения рейтинга обратитесь к менеджеру через чат',
        });
    }

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.state.isClient) {
            if (this.validate(this.validRules)) {
                this.setState({ loading: true }, () => {
                    let form;
                    if (this.state.isCard) {
                        form = {
                            client: true,
                            paymentMethod: 'card',
                            name: this.state.name,
                            phone: this.state.phone.replace(/\D+/g,''),
                            email: this.state.email,
                            paymentData: {
                                cardNumber: this.state.cardNumber,
                                cardName: this.state.cardName,
                                cardBank: this.state.cardBank,
                                cardBik: this.state.cardBik,
                                cardKorrAccount: this.state.cardKorrAccount,
                                cardInn: this.state.cardInn,
                                cardKpp: this.state.cardKpp,
                            }
                        }
                    } else {
                        form = {
                            client: true,
                            paymentMethod: 'invoice',
                            name: this.state.name,
                            phone: this.state.phone.replace(/\D+/g,''),
                            email: this.state.email,
                            paymentData: {
                                companyName: this.state.companyName,
                                inn: this.state.inn,
                                kpp: this.state.kpp,
                                ogrn: this.state.ogrn,
                                bankAccountNumber: this.state.bankAccountNumber,
                                bank: this.state.bank,
                                bik: this.state.bik,
                                ksNumber: this.state.ksNumber
                            }
                        }
                    }
                    this.setState({ loading: true });
                    Api.request('account/saveInfo', {
                        form: form
                    }).then(response => {
                        this.setState({ loading: false });
                        alertMsg('Сохранено', 'Изменения внесены');
                        App().changeClientType({ client: true });
                    });
                });
            }
        } else {
            this.setState({ loading: true });
            Api.request('account/saveInfo', {
                form: {
                    client: false
                }
            }).then(response => {
                this.setState({ loading: false });
                alertMsg('Сохранено', 'Изменения внесены');
                App().changeClientType({ client: false });
            });
        }
    };
    
    render() {
        let formData = this.props.formData;
        return (
            <View style={{
                flex: 1,
                backgroundColor: '#fff'
            }}>
                <ScrollViewKB
                    style={{
                        flex: 1,
                        paddingHorizontal: 16,
                        paddingTop: 24,
                    }}
                    bottomContentInset={this.props.bottomContentInset}
                    onKBShow={this.props.onKBShow}
                    onKBHide={this.props.onKBHide}
                >
                    <Alert ref={refLink => this.Alert = refLink} />
                    <View style={styles.switchContainer}>
                        <Switch
                            color="#FFC107"
                            value={formData.client}
                            onValueChange={this._onToggleClientSwitch}
                        />
                        <Text style={styles.switchLabel}>Я заказчик</Text>
                    </View>
                    { formData.client ?
                        <View>
                            <Text style={[styles.text, styles.marginTop ]}>Мой статус</Text>
                            <Rating style={{ marginTop: 16 }} value={formData.clientRating} onChange={() => this.onRatingChange()} />
                            <View style={styles.divider}></View>
                            <Text style={styles.text}>Персональные данные</Text>
                            <View style={[{ flexDirection: 'row', alignItems: 'center'}, styles.marginTop]}>
                                <TextField
                                    style={[{ flex: 1, marginRight: 10 }, styles.textInput]}
                                    value={formData.name}
                                    onChangeText={value => this.props.onChange({ name: value })}
                                    label='Имя'
                                    error={this.isFieldInError('name')}
                                />
                                <IconButton
                                    icon="camera"
                                    color='#212529'
                                    size={32}
                                    onPress={() => {}}
                                />
                            </View>
                            <TextInput
                                style={[styles.textInput, styles.marginTop]}
                                label='Телефон'
                                mode='outlined'
                                value={formData.phone}
                                onChangeText={value => this.props.onChange({ phone: value })}
                                error={this.isFieldInError('phone')}
                                autoCorrect={false}
                                keyboardType="phone-pad"
                                returnKeyType="next"
                                render={props =>
                                    <TextInputMask
                                        {...props}
                                        type={'custom'}
                                        options={{
                                            mask: '+7 (999) 999-99-99'
                                        }}
                                    />
                                }
                            />
                            <TextField
                                style={[styles.textInput, styles.marginTop]}
                                value={formData.email}
                                onChangeText={value => this.props.onChange({ email: value })}
                                label='Электронная почта'
                                dense={true}
                            />
                            <View style={styles.divider}></View>
                            <Text style={styles.text}>Реквизиты для вознаграждения</Text>
                            <View style={[styles.switchContainer, styles.marginTop]}>
                                <Switch
                                    color="#FFC107"
                                    value={formData.paymentMethod === 'card'}
                                    onValueChange={this._onTogglePaymentMethodSwitch}
                                />
                                <Text style={styles.switchLabel}>Оплата на карту</Text>
                            </View>
                            <View style={[styles.switchContainer, styles.marginTop]}>
                                <Switch
                                    color="#FFC107"
                                    value={formData.paymentMethod !== 'card'}
                                    onValueChange={this._onTogglePaymentMethodSwitch}
                                />
                                <Text style={styles.switchLabel}>Оплата на счет организации</Text>
                            </View>
                            { formData.paymentMethod === 'card' ?
                                <View>
                                    <View></View>
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardName}
                                        onChangeText={value => this._onPaymentDataChange({ cardName: value })}
                                        label='Получатель'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardNumber}
                                        onChangeText={value => this._onPaymentDataChange({ cardNumber: value })}
                                        label='Номер счета'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardBank}
                                        onChangeText={value => this._onPaymentDataChange({ cardBank: value })}
                                        label='Банк получателя'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardBik}
                                        onChangeText={value => this._onPaymentDataChange({ cardBik: value })}
                                        label='БИК'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardKorrAccount}
                                        onChangeText={value => this._onPaymentDataChange({ cardKorrAccount: value })}
                                        label='Корр. счет'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardInn}
                                        onChangeText={value => this._onPaymentDataChange({ cardInn: value })}
                                        label='ИНН'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.cardKpp}
                                        onChangeText={value => this._onPaymentDataChange({ cardKpp: value })}
                                        label='КПП'
                                        mode='outlined'
                                    />
                                </View>
                                :
                                <View>
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.companyName}
                                        onChangeText={value => this._onPaymentDataChange({ companyName: value })}
                                        label='Наименование юр.лица'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.inn}
                                        onChangeText={value => this._onPaymentDataChange({ inn: value })}
                                        label='ИНН'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.kpp}
                                        onChangeText={value => this._onPaymentDataChange({ kpp: value })}
                                        label='КПП'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.ogrn}
                                        onChangeText={value => this._onPaymentDataChange({ ogrn: value })}
                                        label='ОГРН'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.bankAccountNumber}
                                        onChangeText={value => this._onPaymentDataChange({ bankAccountNumber: value })}
                                        label='Р/с'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.bank}
                                        onChangeText={value => this._onPaymentDataChange({ bank: value })}
                                        label='Банк'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.bik}
                                        onChangeText={value => this._onPaymentDataChange({ bik: value })}
                                        label='БИК'
                                        mode='outlined'
                                    />
                                    <TextField
                                        style={[styles.textInput, styles.marginTop]}
                                        value={formData.paymentData.ksNumber}
                                        onChangeText={value => this._onPaymentDataChange({ ksNumber: value })}
                                        label='К/с'
                                        mode='outlined'
                                    />
                                </View>
                            }
                        </View>
                        :
                        null
                    }
                </ScrollViewKB>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    marginTop: {
        marginTop: 23
    },
    textInput: {
        height: 48,
    },
    text: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    divider: {
        height: 1,
        backgroundColor: '#CED4DA',
        marginVertical: 36
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    switchLabel: {
        color: '#212529',
        fontSize: 16,
        lineHeight: 19,
        marginLeft: 16
    },
    switch: {
        color: '#FFC107'
    }
});

ClientScreen.defaultProps = {
    onChange: () => {},
    onKBShow: () => {},
    onKBHide: () => {},
    bottomContentInset: 0,
};

export default ClientScreen;