import React from 'react';
import { Platform, View, Text, StyleSheet } from 'react-native';
import { Appbar, Avatar } from 'react-native-paper';
import Chat from '../components/Chat';
import call from 'react-native-phone-call';

import StatusBarCheat from '../components/StatusBarCheat';

const OrdersScreen = () => (
    <View style={styles.container}>
        <Appbar.Header style={styles.header}>
            <View style={styles.headerContent}>
                <Avatar.Image size={32} source={require('../img/avatar.jpg')} />
                <Text style={styles.headerTitle}>Чат с менеджером</Text>
                <Appbar.Action icon="phone" onPress={() => call({ number: '88007073462' })} />
            </View>
        </Appbar.Header>
        <Chat accountType="client" />
    </View>
)

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        backgroundColor: '#fff',
        marginTop: Platform.OS === 'android' ? -StatusBarCheat().currentHeight: 0
    },
    headerContent: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10
    },
    headerTitle: {
        flex: 1,
        marginHorizontal: 20,
        fontSize: 18,
        lineHeight: 28,
        color: '#495057'
    }
})

export default OrdersScreen