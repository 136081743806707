import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';
import App from '../components/App';

class DirectorProfileScreen extends React.Component {
    render() {
        return (
            <View style={styles.container}>
                <Text style={{ fontSize: 18 }}>{this.props.user.name} {this.props.user.last_name}</Text>
                <View style={{ maxWidth: 250 }}>
                    <Button
                        style={{ marginTop: 50 }}
                        contentStyle={{ height: 48 }}
                        mode="contained"
                        color="#FFC107"
                        loading={false}
                        onPress={() => App().logout()}
                    >
                        Выйти из приложения
                    </Button>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingVertical: 30,
        backgroundColor: '#fff'
    },
})

export default DirectorProfileScreen;
