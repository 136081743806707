import React from 'react';
import { ScrollView, View, Dimensions } from 'react-native';
import { TextInput, Button, Paragraph, Text, Dialog, Portal } from 'react-native-paper';
import ListItem from './ListItem';
import { convertRating } from './Rating';
import Api from './Api';
import { alertMsg } from './Api';

const window = Dimensions.get("window");

function formatRating(item) {    
    if (item.employee) {
        if (item.employeeRating) return convertRating(item.employeeRating, false);
    }
    else if (item.client) {
        if (item.clientRating) return convertRating(item.clientRating, true)+'%';
    }
    return null;
}

class GroupAddMember extends React.Component {
    state = {
        visible: false,
        search: '',
        userList: [],
        userListLoading: true,
        loading: false,
        selected: [],
    };

    componentDidMount = () => {}

    show = (group) => {
        this.setState({
            group: group,
            userListLoading: true,
            loading: false,
            selected: [],
        }, () => this._showDialog());

        Api.request('user/getList', {}).then((response) => {
            let list = [];
            if (response.success) list = response.userList;
            this.setState({ userListLoading: false, userList: list });
        }, () => {
            this.setState({ userListLoading: false });
        });
    }

    _showDialog = () => this.setState({ visible: true });

    _hideDialog = () => this.setState({ visible: false });

    onSubmit = () => {
        if (this.state.loading) return;
        this.setState({ loading: true }, () => {
            Api.request('group/addMembers', {
                groupId: this.state.group._id,
                members: this.state.selected,
            }).then(() => {
                this.setState({ loading: false });
                this._hideDialog();
                this.props.onSubmit();
            }, () => {
                this.setState({ loading: false });
            });
        });
    }

    _selectUser = (user) => {
        let index = this.state.selected.indexOf(user._id);
        if (index !== -1) {
            this.state.selected.splice(index, 1);
        } else {
            this.state.selected.push(user._id);
        }
        this.setState({
            selected: this.state.selected.slice()
        });
    }

    render() {
        return (
            <View>
                <Portal>
                    <Dialog
                        style={{
                            maxHeight: window.height-80,
                            maxWidth: 460,
                            marginHorizontal: 'auto',
                        }}
                        visible={this.state.visible}
                        onDismiss={this._hideDialog}
                    >
                        <Dialog.Title>Добавить участников</Dialog.Title>
                        <Dialog.Content style={{ flex: 1}}>
                            <View style={{ flex: 1}}>
                                <TextInput
                                    style={{ marginBottom: 24 }}
                                    value={this.state.search}
                                    onChangeText={value => this.setState({ search: value })}
                                    label='Поиск'
                                    mode='outlined'
                                />
                                { this.state.userListLoading ?
                                    <Text>Загрузка...</Text>
                                :
                                    <ScrollView>
                                        { this.state.userList.filter(item => {
                                            if (this.state.group.members.indexOf(item._id) !== -1) return false;
                                            if (this.state.search) {
                                                let search = this.state.search.toLowerCase();
                                                let name = item.full_name.toLowerCase();
                                                let phone = item.phone.toString();
                                                if (name.indexOf(search) !== -1 || phone.indexOf(search) !== -1) return true;
                                            } else {
                                                return true;
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <ListItem
                                                    key={index}
                                                    title={item.full_name}
                                                    discount={formatRating(item)}
                                                    content={item.phone}
                                                    avatar={item.avatar}
                                                    active={this.state.selected.indexOf(item._id) !== -1}
                                                    onPress={() => this._selectUser(item)}
                                                />
                                            );
                                        }) }
                                    </ScrollView>
                                }
                                <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: 24
                                }}>
                                    <Button
                                        mode="outlined"
                                        onPress={() => this._hideDialog()}
                                    >
                                        Отмена
                                    </Button>
                                    <Button
                                        mode="contained"
                                        color="#FFC107"
                                        loading={this.state.loading}
                                        onPress={() => this.onSubmit()}
                                    >
                                        Добавить
                                    </Button>
                                </View>
                            </View>
                        </Dialog.Content>
                    </Dialog>
                </Portal>
            </View>
        );
    }
}

GroupAddMember.defaultProps = {
    onSubmit: () => {}
}

export default GroupAddMember;