import React from 'react';
import { StyleSheet, ScrollView, View, Text, Vibration } from 'react-native';
import { IconButton } from 'react-native-paper';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import OrderList from '../components/OrderList';
import Chat from '../components/Chat';
import ClientInfo from '../components/ClientInfo';

const Tab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();

import { connect } from 'react-redux';

class DirectorOrdersScreen extends React.Component {
    state = {
        activeChat: false,
    }

    chatScreen = () => {
        return (
            <Chat accountType="director"
                   chatId={(this.state.activeChat) ? this.state.activeChat.chat._id : false}
                   owner={(this.state.activeChat.owner) ? this.state.activeChat.owner : false}
                   key={(this.state.activeChat) ? this.state.activeChat._id : 123}
             />
        );
    }
    
    userProfileScreen = () => {
        return (
            <ScrollView style={{ backgroundColor: '#fff', paddingVertical: 24, paddingHorizontal: 32 }}>
                <ClientInfo
                    key={this.state.activeChat._id}
                    chat={this.state.activeChat}
                />
            </ScrollView>
        );
    }

    _onAddOrderToList = () => {
        Vibration.vibrate(200);
    }
    
    orderList = ({ navigation }) => {
        return (
            <View style={{ flex: 1, backgroundColor: '#fff'}}>
                <OrderList
                    onUpdate={this._onUpdateOrderInfo}
                    onAdd={this._onAddOrderToList}
                    onSelect={order => this.setState({ activeChat: order }, () => navigation.navigate('Chat'))}
                />
            </View>
        );
    }
    
    archiveList = ({ navigation }) => {
        return (
            <View style={{ flex: 1, backgroundColor: '#fff'}}>
                <OrderList
                    archive={true}
                    notLoad={true}
                    onUpdate={this._onUpdateOrderInfo}
                    onAdd={this._onAddOrderToList}
                    onSelect={order => this.setState({ activeChat: order }, () => navigation.navigate('Chat'))}
                />
            </View>
        );
    }
    
    homeScreen = () => {
        return (
            <Tab.Navigator>
                <Tab.Screen name="Подписание" component={this.orderList} />
                <Tab.Screen name="Архив" component={this.archiveList} />
            </Tab.Navigator>
        );
    }
    
    render() {
        return (
            <Stack.Navigator>
                <Stack.Screen name="Home" options={{headerShown: false}} component={this.homeScreen} />
                <Stack.Screen
                    name="Chat"
                    component={this.chatScreen}
                    options={({ navigation, route }) => ({
                        title: (this.state.activeChat) ? this.state.activeChat.name + ' (' + this.state.activeChat.customer.name + ')' : '',
                        headerRight: () => (
                            <IconButton
                                icon="account-edit"
                                color='#868E96'
                                size={20}
                                onPress={() => navigation.navigate('Profile')}
                            />
                        )
                    })}
                />
                <Stack.Screen
                    name="Profile"
                    component={this.userProfileScreen}
                    options={{
                        title: (this.state.activeChat) ? this.state.activeChat.name + ' - профиль заказчика' : '',
                    }}
                />
            </Stack.Navigator>
        );
    }
}


const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo.data,
        accountInfoLoading: state.accountInfo.loading
    }
}

export default connect(mapStateToProps, {
})(DirectorOrdersScreen);