import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Provider, Menu, IconButton } from 'react-native-paper';
import Api from '../components/Api';
//import defaultStyles from '../styles/main.js';

class ContextMenu extends React.Component {
    state = {
        mainMenu: false
    }

    componentDidMount() {
    }

    onPress = (item) => {
        if (item.action) item.action();
        if (item.dismiss !== false) this.setState({mainMenu: false});
    }
 
    render() {
        return (
            <Menu
                visible={this.state.mainMenu}
                onDismiss={() => this.setState({mainMenu: false})}
                anchor={
                    <IconButton
                        icon="menu"
                        color='#868E96'
                        size={20}
                        onPress={() => this.setState({mainMenu: true})}
                    />
                }
            >
                {this.props.items.map(item => <Menu.Item key={item.key} onPress={() => this.onPress(item)} title={item.title} />)}
            </Menu>
        )
    }
}

ContextMenu.defaultProps = {
    items: []
};

const styles = StyleSheet.create({
   
})

export default ContextMenu;