import React from 'react';
import { ScrollView, View, Dimensions } from 'react-native';
import { TextInput, Button, Paragraph, Text, Dialog, Portal } from 'react-native-paper';
import Api from '../components/Api';
import { alertMsg } from '../components/Api';

const window = Dimensions.get("window");

class CreateGroup extends React.Component {
    state = {
        visible: false,
        name: '',
        loading: false
    };

    componentDidMount = () => {}

    show = () => {
        this._showDialog();
    }

    _showDialog = () => this.setState({ visible: true });

    _hideDialog = () => this.setState({ visible: false });

    onSubmit = () => {
        if (this.state.loading) return;
        this.setState({ loading: true }, () => {
            Api.request('group/create', {
                name: this.state.name,
            }).then(() => {
                this.setState({ loading: false, name: '' });
                this._hideDialog();
            }, () => {
                this.setState({ loading: false });
            });
        });
    }

    render() {
        return (
            <View>
                <Portal>
                    <Dialog
                        style={{
                            maxHeight: window.height-80,
                            maxWidth: 400,
                            marginHorizontal: 'auto'
                        }}
                        visible={this.state.visible}
                        onDismiss={this._hideDialog}
                    >
                        <Dialog.Title>Создание группы</Dialog.Title>
                        <Dialog.Content style={{ flex: 1}}>
                            <ScrollView style={{ flex: 1}}>
                                <TextInput
                                    value={this.state.name}
                                    onChangeText={value => this.setState({ name: value })}
                                    label='Название группы'
                                    mode='outlined'
                                />
                                <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: 36
                                }}>
                                    <Button
                                        mode="outlined"
                                        loading={this.state.loading}
                                        onPress={() => this._hideDialog()}
                                    >
                                        Отмена
                                    </Button>
                                    <Button
                                        mode="contained"
                                        color="#FFC107"
                                        loading={this.state.loading}
                                        onPress={() => this.onSubmit()}
                                    >
                                        Создать
                                    </Button>
                                </View>
                            </ScrollView>
                        </Dialog.Content>
                    </Dialog>
                </Portal>
            </View>
        );
    }
}

export default CreateGroup;