import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Button, TextInput } from 'react-native-paper';

class Input extends React.Component {
    state = {
        value: this.props.defaultValue
    }

    update = (value) => {
        this.setState({ value: value }, () => {
            if (this.props.onChangeText) this.props.onChangeText(this.state.value);
        });
    }

    dec() {
        this.setState(state => {
            let value = parseInt(state.value);
            if (value == NaN) value = 0;
            value = value - this.props.step;
            if (value < this.props.min) value = this.props.min;
            return { value: value.toString() };
        }, () => this.update(this.state.value));
    }

    inc() {
        this.setState(state => {
            let value = parseInt(state.value);
            if (value == NaN) value = 0;
            value = value + this.props.step;
            return { value: value.toString() };
        }, () => this.update(this.state.value));
    }

    render() {
        return (
            <View style={styles.container}>
                <Button
                    compact={true}
                    style={{ marginRight: 8, marginTop: 5}}
                    labelStyle={{ fontSize: 32, lineHeight: 26 }}
                    contentStyle={{ width: 36, height: 36 }}
                    mode="outlined"
                    onPress={() => this.dec()}
                >-</Button>
                <Text style={{ flex: 1, textAlign: 'center' }}>{this.state.value}{this.props.label}</Text>
                <Button
                    compact={true}
                    style={{ marginLeft: 8, marginTop: 5 }}
                    labelStyle={{ fontSize: 24, lineHeight: 22 }}
                    contentStyle={{ width: 36, height: 36 }}
                    mode="outlined"
                    onPress={() => this.inc()}
                >+</Button>
            </View>
        );
    }
}

Input.defaultProps = {
    defaultValue: '10',
    step: 1,
    min: 10,
    label: '',
    onChangeText: () => {},
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    }
})

export default Input;