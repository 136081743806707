import React, { Component } from 'react';
import { StyleSheet, ScrollView, View, Text, Image, TouchableOpacity } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import Api from './Api';

class OrderForm extends ValidationComponent {
    state = {
      loading: false,
    };

    componentDidMount() {
        let order = this.props.chat.order;

        this.setState({
            ...order,
        });
    }

    validRules = {
        transportation: {
        },
        delivery: {
        },
        trucks: {
        },
        materials: {
        }
    };

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules[this.props.chat.order_type])) {
            let data = {};

            if (this.props.chat.order_type === 'transportation') {
                data = {
                    description: this.state.description,
                    from: this.state.from,
                    destination: this.state.destination,
                    payload: this.state.payload,
                    amount: this.state.amount,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            if (this.props.chat.order_type === 'delivery') {
                data = {
                    description: this.state.description,
                    from: this.state.from,
                    destination: this.state.destination,
                    payload: this.state.payload,
                    amount: this.state.amount,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            if (this.props.chat.order_type === 'trucks') {
                data = {
                    description: this.state.description,
                    truckName: this.state.truckName,
                    truckParams: (this.state.truckParams) ? this.state.truckParams : '',
                    from: this.state.from,
                    destination: this.state.destination,
                    amount: this.state.amount,
                    date: this.state.date,
                    time: this.state.time,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            if (this.props.chat.order_type === 'materials') {
                data = {
                    description: this.state.description,
                    destination: this.state.destination,
                    payload: this.state.payload,
                    amount: this.state.amount,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            this.setState({ loading: true }, () => {
                Api.request('order/save', {
                    orderId: this.props.chat._id,
                    form: data,
                }).then(response => {
                    this.setState({ loading: false });
                    if (!response.success) {
                        Api.alert('Ошибка при сохранении');
                    }
                });
            });
        }
    };

  render() {
    let orderType = this.props.chat.order_type;
    return (
        <View style={{ flex: 1 }}>
            <ScrollView style={{ paddingLeft: 23, paddingRight: 15 }}>
                { (orderType === 'transportation') ?
                <View>
                    <Text style={styles.label}>Откуда</Text>
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Город, нас. пункт'
                        mode='outlined'
                        value={this.state.from}
                        onChangeText={value => this.setState({ from: value })}
                        error={this.isFieldInError('from')}
                    />
                    <Text style={styles.label}>Куда</Text>
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Город, нас. пункт'
                        mode='outlined'
                        value={this.state.destination}
                        onChangeText={value => this.setState({ destination: value })}
                        error={this.isFieldInError('destination')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Что перевозим'
                        mode='outlined'
                        value={this.state.payload}
                        onChangeText={value => this.setState({ payload: value })}
                        error={this.isFieldInError('payload')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Сколько перевозим'
                        mode='outlined'
                        value={this.state.amount}
                        onChangeText={value => this.setState({ amount: value })}
                        error={this.isFieldInError('amount')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Цена работы'
                        mode='outlined'
                        value={this.state.price}
                        onChangeText={value => this.setState({ price: value })}
                        error={this.isFieldInError('price')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.description}
                        onChangeText={value => this.setState({ description: value })}
                        error={this.isFieldInError('description')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Внутренний комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.comment}
                        onChangeText={value => this.setState({ comment: value })}
                    />
                </View>
                : null }
                { (orderType === 'delivery') ?
                <View>
                    <Text style={styles.label}>Откуда</Text>
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Город, нас. пункт'
                        mode='outlined'
                        value={this.state.from}
                        onChangeText={value => this.setState({ from: value })}
                        error={this.isFieldInError('from')}
                    />
                    <Text style={styles.label}>Куда</Text>
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Город, нас. пункт'
                        mode='outlined'
                        value={this.state.destination}
                        onChangeText={value => this.setState({ destination: value })}
                        error={this.isFieldInError('destination')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Что перевозим'
                        mode='outlined'
                        value={this.state.payload}
                        onChangeText={value => this.setState({ payload: value })}
                        error={this.isFieldInError('payload')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Сколько перевозим'
                        mode='outlined'
                        value={this.state.amount}
                        onChangeText={value => this.setState({ amount: value })}
                        error={this.isFieldInError('amount')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Цена работы'
                        mode='outlined'
                        value={this.state.price}
                        onChangeText={value => this.setState({ price: value })}
                        error={this.isFieldInError('price')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.description}
                        onChangeText={value => this.setState({ description: value })}
                        error={this.isFieldInError('description')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Внутренний комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.comment}
                        onChangeText={value => this.setState({ comment: value })}
                    />
                </View>
                : null }
                { (orderType === 'trucks') ?
                <View>
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Название техники'
                        mode='outlined'
                        value={this.state.truckName}
                        onChangeText={value => this.setState({ truckName: value })}
                        error={this.isFieldInError('truckName')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Куда (город, нас. пункт)'
                        mode='outlined'
                        value={this.state.destination}
                        onChangeText={value => this.setState({ destination: value })}
                        error={this.isFieldInError('destination')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Характеристики'
                        mode='outlined'
                        value={this.state.truckParams}
                        onChangeText={value => this.setState({ truckParams: value })}
                        error={this.isFieldInError('truckParams')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Сколько'
                        mode='outlined'
                        value={this.state.amount}
                        onChangeText={value => this.setState({ amount: value })}
                        error={this.isFieldInError('amount')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Когда'
                        mode='outlined'
                        value={this.state.date}
                        onChangeText={value => this.setState({ date: value })}
                        error={this.isFieldInError('date')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Во сколько'
                        mode='outlined'
                        value={this.state.time}
                        onChangeText={value => this.setState({ time: value })}
                        error={this.isFieldInError('time')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Цена работы'
                        mode='outlined'
                        value={this.state.price}
                        onChangeText={value => this.setState({ price: value })}
                        error={this.isFieldInError('price')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.description}
                        onChangeText={value => this.setState({ description: value })}
                        error={this.isFieldInError('description')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Внутренний комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.comment}
                        onChangeText={value => this.setState({ comment: value })}
                    />
                </View>
                : null }
                { (orderType === 'materials') ?
                <View>
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Название материала'
                        mode='outlined'
                        value={this.state.payload}
                        onChangeText={value => this.setState({ payload: value })}
                        error={this.isFieldInError('payload')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Куда (город, нас. пункт)'
                        mode='outlined'
                        value={this.state.destination}
                        onChangeText={value => this.setState({ destination: value })}
                        error={this.isFieldInError('destination')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Сколько перевозим'
                        mode='outlined'
                        value={this.state.amount}
                        onChangeText={value => this.setState({ amount: value })}
                        error={this.isFieldInError('amount')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Цена'
                        mode='outlined'
                        value={this.state.price}
                        onChangeText={value => this.setState({ price: value })}
                        error={this.isFieldInError('price')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.description}
                        onChangeText={value => this.setState({ description: value })}
                        error={this.isFieldInError('description')}
                    />
                    <TextInput
                        style={styles.textArea}
                        label='Внутренний комментарий'
                        multiline={true}
                        numberOfLines={5}
                        mode='outlined'
                        value={this.state.comment}
                        onChangeText={value => this.setState({ comment: value })}
                    />
                </View> : null }
            </ScrollView>
            <Button
                style={{ marginLeft: 23, marginRight: 30, marginBottom: 24 }}
                contentStyle={{ height: 48 }}
                mode="contained"
                color="#FFC107"
                loading={this.state.loading} onPress={() => this.onSubmit()}
            >
                Сохранить
            </Button>
        </View>
    );
  }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    imageContainer: {
        width: 40
    },
    image: {
        width: 32,
        height: 147,
        marginTop: 50
    },
    formContainer: {
        flex: 1,
    },
    label: {
        marginBottom: 7,
        fontSize: 21,
        lineHeight: 25,
        color: '#212529'
    },
    button: {
        padding: 14,
        paddingVertical: 14
    },
    buttonText: {
        fontSize: 16,
        lineHeight: 19
    },
    textInput: {
        fontSize: 16,
        height: 48,
        backgroundColor: '#fff',
        marginBottom: 10
    },
    textArea: {
        fontSize: 16,
        backgroundColor: '#fff',
        marginBottom: 20
    }
});

export default OrderForm;