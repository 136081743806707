import React from 'react';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    button: {
        padding: 10,
        borderRadius: 4,
        backgroundColor: '#FFC107',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.24,
        shadowRadius: 2
    },
    buttonText: {
        color: '#212121',
        textAlign: 'center',
        fontSize: 14,
        lineHeight: 16,
        textTransform: 'uppercase'
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.24,
        shadowRadius: 4
    },
    shadowMini: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.12,
        shadowRadius: 4
    },
});

export default styles;