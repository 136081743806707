import React from 'react';
import { AppState, Platform, View, Text, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import { MaterialIcons } from '@expo/vector-icons';
import ListItem from './ListItem';
import { convertRating } from './Rating';
import Api from './Api';
import moment from 'moment';
import sort from 'fast-sort';
import Logger from './Logger';

import { connect } from 'react-redux';
import {
    loadChatList,
    loadGroupList,
    addGroupToList,
    updateGroupInfo,
    updateChatInfo, 
    updateChatUserInfo,
    setActiveChat,
    addChatToList,
} from '../redux/actions';

function formatRating(item) {    
    if (item.employee) {
        if (item.employeeRating) return convertRating(item.employeeRating, false);
    }
    else if (item.client) {
        if (item.clientRating) return convertRating(item.clientRating, true)+'%';
    }
    return null;
}

function timestampFormat(timestamp) {
    if (!timestamp) return '';
    let date = moment.unix(timestamp);
    if (moment().diff(date, 'days') < 0) {
        return date.format();
    }
    return date.format("DD.MM h:mm");
}

function unreadCount(chat, userId) {
    if (!chat) return 0;
    let readCount = 0;
    if (chat.readCount) {
        readCount = chat.readCount.find(item => item._id == userId);
        if (!readCount) readCount = 0;
        else readCount = readCount.count;
    }
    return chat.count - readCount;
}

const TabItem = props => (
    <View style={{ flexGrow: 1 }}>
        <TouchableOpacity onPress={props.onPress}>
            <Text style={{ fontSize: 14,
                            textTransform: 'uppercase',
                            marginTop: 14,
                            marginRight: 14,
                            marginBottom: 12,
                            marginLeft: 14,
                            textAlign: 'center',
                            color: props.active ? '#FFC107' : '#fff'
                        }}>{props.title}</Text>
        </TouchableOpacity>
        <View style={{ width: '100%', height: 2, backgroundColor: props.active ? '#FFC107' : '#212529'}}></View>
    </View>
);

class ChatList extends React.Component {
    state = {
        filter: 'all',
        search: ''
    }

    componentDidMount() {
        
        this.props.loadChatList();
        this.props.loadGroupList();
        
        Api.socket.on('chat/updateInfo', (data) => {
            Logger.log('debug', 'Обновилась информация о чате', data);
            if (!Array.isArray(data)) data = [data];
            this.props.updateChatInfo(data);
        });

        Api.socket.on('chat/newChatAdded', (data) => {
            Logger.log('debug', 'Появился новый пользователь');
            this.props.loadChatList();
        });

        Api.socket.on('group/newGroup', (data) => {
            Logger.log('debug', 'Добавилась новая группа чатов', data);
            this.props.addGroupToList(data);
        });

        Api.socket.on('group/updateInfo', (data) => {
            Logger.log('debug', 'Обновилась информация о группе чатов', data);
            if (this.props.selected && this.props.selected.chat._id && this.props.selected.chat._id === data.chat) {
                delete data.chat; 
            }
            this.props.updateGroupInfo(data);
        });
        
        Api.socket.on('user/updateUserInfo', (data) => {
            Logger.log('debug', 'Обновилась информация о пользователе');
            this.props.updateChatUserInfo(data.user);
        });
    }

    selectChat(chat) {
        Logger.log('debug', 'Выбран чат', chat);
        this.props.setActiveChat(chat);
        if (this.props.onSelect) this.props.onSelect(chat);
    }

    selectGroup(group) {
        Logger.log('debug', 'Выбрана группа чатов', group);
        Api.request('group/initChat', {
            groupId: group._id,
        }, false).then(response => {
            if (response.success) {
                let selectedGroup = {
                    ...group,
                    chat: response.chat
                };
                this.props.setActiveChat(selectedGroup);
                if (this.props.onSelect) this.props.onSelect(selectedGroup);
            }
        });
    }

    _getList = () => {

        let list = [
            ...this.props.chatList,
            ...this.props.groupList.map(item => {
                item.group = true;
                return item;
            })
        ];

        return sort(list.filter(item => {
            if (item.group) {
                if (this.state.filter === 'all') {
                    if (item.members === 'employees') return false;
                    if (item.members === 'clients') return false;
                }
                if (this.state.filter === 'clients' && item.members === 'clients') return true;
                if (this.state.filter === 'employees' && item.members === 'employees') return true;
                if (this.state.filter !== 'all') return false;
                if (this.state.search && item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) == -1) return false;
                return true;
            } else {
                if (item.block) return false;
                if (this.state.search) {
                    let name = '';
                    let phone = '';
                    if (item.name) {
                        name = item.name.toLowerCase();
                    }
                    if (item.nickname) {
                        name = name + ' ' + item.nickname.toLowerCase();
                    }
                    if (item.phone) {
                        phone = item.phone.toString().toLowerCase()
                    }
                    if (item.blockRequest) {
                        name = name + 'запрос на блокировку';
                    }
                    let search = this.state.search.toLowerCase()
                    if (name.indexOf(search) == -1 && phone.indexOf(search) == -1) return false;
                }
                if (this.state.filter == 'all') return true;
                if (this.state.filter == 'clients') return item.type == 'Клиент' && item.client;
                if (this.state.filter == 'employees') return item.type == 'Клиент' && item.employee;
            }
         })).desc([
            u => {
                if (u.group) {
                    return u.lastTimestamp;
                } else {
                    return unreadCount(u.chat, Api.userId)
                }
            },
            u => {
                if (u.group) {
                    return u.lastTimestamp;
                } else {
                    return u.chat.lastTimestamp;
                }
            }
         ]);
    }

    _renderListItem = (item) => {
        if (item.group) {
            return (
                <ListItem
                    title={item.name}
                    content={item.membersCount+' участников'}
                    avatar={true}
                    RenderAvatar={() => (
                        <MaterialIcons name="people" size={24} color="#212529" />
                    )}
                    time={timestampFormat(item.lastTimestamp)}
                    counter={unreadCount(item, Api.userId)}
                    active={this.props.selected._id==item._id}
                    onPress={() => this.selectGroup(item)}
                />
            );
        } else {
            return (
                <ListItem
                    title={item.nickname ? item.nickname : item.name}
                    block={item.block}
                    blockRequest={item.blockRequest}
                    discount={formatRating(item)}
                    content={item.phone}
                    avatar={item.avatar}
                    marker={(item.client && !item.employee) || item.verified || item.system ? false : true}
                    time={timestampFormat(item.chat.lastTimestamp)}
                    counter={unreadCount(item.chat, Api.userId)}
                    active={this.props.selected._id==item._id}
                    onPress={() => this.selectChat(item)}
                />
            );
        }
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', backgroundColor: '#212529' }}>
                    <TabItem title="Все" active={(this.state.filter == 'all')} onPress={() => this.setState({ filter: 'all' })} />
                    <TabItem title="Заказчики" active={(this.state.filter == 'clients')} onPress={() => this.setState({ filter: 'clients'})} />
                    <TabItem title="Перевозчики" active={(this.state.filter == 'employees')} onPress={() => this.setState({ filter: 'employees'})} />
                </View>
                <TextInput
                    label='Найти...'
                    mode='outlined'
                    value={this.state.search}
                    onChangeText={(value) => this.setState({ search: value })}
                    autoCorrect={false}
                    returnKeyType="search"
                    style={{
                        backgroundColor: '#fff',
                        fontSize: 16,
                        height: 32,
                        marginVertical: 10,
                        marginHorizontal: 10
                    }}
                    theme={{ colors: { placeholder: '#868E96'}}}
                />
                <FlatList
                    style={{ flex: 1, paddingTop: 10 }}
                    data={this._getList()}
                    extraData={[this.props.selected, this.props.chatList]}
                    renderItem={({ item }) => this._renderListItem(item)}
                    keyExtractor={item => item._id}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({
    marginBottom: {
        marginBottom: 10
    }
});

const mapStateToProps = state => {
    return {
        chatList: state.chatList.list,
        groupList: state.chatList.groups,
        selected: state.chatList.activeChat
    }
}

export default connect(mapStateToProps, {
    loadChatList,
    loadGroupList,
    addGroupToList,
    updateGroupInfo,
    updateChatInfo,
    updateChatUserInfo,
    setActiveChat,
    addChatToList,
})(ChatList);