import React from 'react';
import { View, Text } from 'react-native';
import { IconButton } from 'react-native-paper';
import TextField from './TextField';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import StepInput from '../components/StepInput';
import Alert from '../components/Alert';

class Form extends React.Component {

    render() {
        let truck = this.props.truck;
        return (
            <View style={[{marginHorizontal: 16}, formStyles.formShadowContainer, this.props.style]}>
                <Alert ref={(refLink) => this.Alert = refLink} />
                { (this.props.canRemove) ? 
                <IconButton
                    style={{ marginLeft: 'auto' }}
                    icon="close"
                    color='#868E96'
                    size={20}
                    onPress={() => this.Alert.show({
                        title: 'Удалить ' + truck.name + '?',
                        content: 'Подтвердите действие',
                        actions: [{
                            title: 'Удалить',
                            action: () => {
                                this.props.onRemove();
                                this.Alert._hideDialog();
                            }
                        }, {
                            title: 'Отмена',
                            action: () => this.Alert._hideDialog()
                        }],
                    })}
                /> : null }
                <TextField
                    style={{ marginBottom: 24 }}
                    label='Название техники'
                    value={truck.name}
                    onChangeText={(value) => this.props.onChangeName(value)}
                    autoCorrect={false}
                    returnKeyType="next"
                />
                <TextField
                    style={{ marginBottom: 24 }}
                    label='Технические характеристики'
                    value={truck.description}
                    onChangeText={(value) => this.props.onChangeDescription(value)}
                    autoCorrect={false}
                    multiline={true}
                    numberOfLines={5}
                    returnKeyType="next"
                />
                <TextField
                    label='Базирование, город'
                    value={truck.city}
                    onChangeText={(value) => this.props.onChangeCity(value)}
                    autoCorrect={false}
                    returnKeyType="next"
                />
            </View>
        );
    }
}

Form.defaultProps = {
    truck: {},
    canRemove: true,
    onChangeName: () => {},
    onChangeDescription: () => {},
    onChangeCity: () => {},
    onRemove: () => {},
    style: {},
}

export default Form