import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text'
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import Api from '../components/Api';
import { alertMsg } from '../components/Api';
import * as ImagePicker from 'expo-image-picker';

class ClientForm extends ValidationComponent {
    state = {
        name: '',
        phone: '',
        loading: false
    };

    validRules = {
        name: {required: true},
        phone: {minlength: 11, maxlength: 11, required: true},
    };

    handlePhoneChange = (phone) => {
        let phoneFormated = phone.replace(/\D+/g,'');
        if (phoneFormated.substring(1,2) === '8') phoneFormated = '7'+phoneFormated.substring(2);
        this.setState({ phone: phoneFormated });
    };

    openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();

        if (permissionResult.granted === false) {
            alert("Не хватает прав доступа!");
            return;
        }

        let pickerResult = await ImagePicker.launchImageLibraryAsync();
        console.log(pickerResult);
    }

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules)) {
            this.setState({ loading: true }, () => {
                Api.registration({
                    phone: this.state.phone,
                    name: this.state.name,
                    client: true,
                    employee: false,
                }).then(() => {
                    this.setState({ loading: false });
                    this.props.onReg(this.state.phone);
                }, () => {
                    this.setState({ loading: false });
                });
            });
        } else {
            alertMsg(false, 'Заполнены не все поля');
        }
    };

    render() {
        return (
            <View style={[this.props.style, styles.formContainer, formStyles.formShadowContainer, {maxWidth: 360}]}>
                <TextInput
                    style={{ marginBottom: 27 }}
                    label='Имя'
                    mode='outlined'
                    value={this.state.name}
                    onChangeText={(name) => this.setState({ name: name })}
                    autoCorrect={false}
                    returnKeyType="next"
                    error={this.isFieldInError('name')}
                />
                <TextInput
                    style={{ marginBottom: 33 }}
                    label='Телефон'
                    mode='outlined'
                    value={this.state.phone}
                    onChangeText={this.handlePhoneChange}
                    autoCorrect={false}
                    keyboardType="phone-pad"
                    returnKeyType="next"
                    render={props =>
                        <TextInputMask
                            {...props}
                            type={'custom'}
                            options={{
                                mask: '+7 (999) 999-99-99'
                            }}
                        />
                    }
                    error={this.isFieldInError('phone')}
                />
                <Text style={{ color: '#495057', fontSize: 16, lineHeight: 24, marginBottom: 16 }}>Реквизиты нужен для выплаты Вам агентского вознаграждение с каждого заказа (можно добавить позже)</Text>
                <Button
                    style={{ marginBottom: 24 }}
                    contentStyle={{ height: 48 }}
                    mode="outlined"
                    color="#212121"
                    onPress={this.openImagePickerAsync}
                >
                        Выбрать файл с реквизитами
                </Button>
                <Button
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    loading={this.state.loading}
                    onPress={() => this.onSubmit()}
                >
                        Зарегистрироваться
                </Button>
            </View>
        );
    }
}

ClientForm.defaultProps = {
    style: {}
}

const styles = StyleSheet.create({
    formContainer: {
        marginHorizontal: 16
    }
})

export default ClientForm