import React from 'react';
import { StyleSheet, ScrollView, View, Text } from 'react-native';
import { IconButton } from 'react-native-paper';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import ChatList from '../components/ChatList';
import Chat from '../components/Chat';
import ClientInfo from '../components/ClientInfo';
import GroupInfo from '../components/GroupInfo';

const Tab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();

class DirectorChatsScreen extends React.Component {
    state = {
        activeChat: false,
    }
    
    chatScreen = () => {
        return (
            <Chat accountType="director"
                   chatId={(this.state.activeChat) ? this.state.activeChat.chat._id : false}
                   owner={(this.state.activeChat) ? this.state.activeChat.owner : false}
                   key={(this.state.activeChat) ? this.state.activeChat._id: 123}
             />
        );
    }
    
    userProfileScreen = () => {
        return (
            <ScrollView style={{ backgroundColor: '#fff', paddingVertical: 24, paddingHorizontal: 32 }}>
                <ClientInfo
                    key={this.state.activeChat._id}
                    chat={this.state.activeChat}
                />
            </ScrollView>
        );
    }

    groupInfoScreen = () => {
        return (
            <ScrollView style={{ backgroundColor: '#fff', paddingVertical: 24, paddingHorizontal: 32 }}>
                <GroupInfo
                    key={this.state.activeChat._id}
                    group={this.state.activeChat}
                />
            </ScrollView>
        );
    }
    
    chatList = ({ navigation }) => {
        return (
            <View style={{ flex: 1, backgroundColor: '#fff'}}>
                <ChatList onSelect={chat => this.setState({ activeChat: chat }, () => navigation.navigate('Chat'))} />
            </View>
        );
    }

    onInfoButtonPress = (navigation) => {
        if (this.state.activeChat.chat.type === 'Групповой чат') {
            navigation.navigate('GroupInfo');
        } else {
            navigation.navigate('Profile');
        }
    }
    
    render() {
        return (
            <Stack.Navigator>
                <Stack.Screen name="Чаты" options={{headerShown: false}} component={this.chatList} />
                <Stack.Screen
                    name="Chat"
                    component={this.chatScreen}
                    options={({ navigation, route }) => ({
                        title: (this.state.activeChat) ? this.state.activeChat.name : '',
                        headerRight: () => (
                            <IconButton
                                icon="account-edit"
                                color='#868E96'
                                size={20}
                                onPress={() => this.onInfoButtonPress(navigation)}
                            />
                        )
                    })}
                />
                <Stack.Screen
                    name="Profile"
                    component={this.userProfileScreen}
                    options={{
                        title: (this.state.activeChat) ? this.state.activeChat.name + ' - профиль' : '',
                    }}
                />
                <Stack.Screen
                    name="GroupInfo"
                    component={this.groupInfoScreen}
                    options={{
                        title: (this.state.activeChat) ? this.state.activeChat.name + ' - группа' : '',
                    }}
                />
            </Stack.Navigator>
        );
    }
}

export default DirectorChatsScreen;