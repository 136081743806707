import React from 'react';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    formContainer: {
        flex: 1,
    },
    formShadowContainer: {
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingVertical: 24,
        borderRadius: 16,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.24,
        shadowRadius: 8
    },
    title: {
        fontSize: 20,
        lineHeight: 34,
        color: '#212529',
        fontWeight: 'bold'
    },
    label: {
        marginLeft: 14,
        marginBottom: 7,
        fontSize: 21,
        lineHeight: 25,
        color: '#212529'
    },
    button: {
        padding: 14,
        paddingVertical: 18
    },
    buttonText: {
        fontSize: 16,
        lineHeight: 19
    },
    marginBottom: {
        marginBottom: 20
    }
});

export default styles;