import React from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity } from 'react-native';

function Counter({ count }) {
    if (count) {
        return (
            <View style={styles.counterContainer}>
                <Text style={styles.counter}>{count}</Text>
            </View>
        );
    } else {
        return (
            <></>
        );
    }
}

Counter.defaultProps = {
    count: 0
};

function ListItem({ title, block, blockRequest, content, avatar, marker, RenderAvatar, time, counter, discount, active, onPress }) {
    return (
        <TouchableOpacity onPress={onPress}>
            <View style={[styles.container, active ? styles.active : {}]}>
                <View style={styles.leftContainer}>
                    <View style={styles.discountContainer}>
                        { (avatar) ?
                            <RenderAvatar uri={avatar} />
                        :
                        <Text style={styles.discount}>{discount}</Text>
                        }
                    </View>
                    { marker ?
                        <View
                            style={{
                                backgroundColor: '#F03D3E', width: 10, height: 10, borderRadius: 10,
                                position: 'absolute',
                                top: 0, right: 0,
                            }}
                        ></View>
                    :
                        null
                    }
                </View>
                <View style={styles.middleContainer}>
                    <Text style={[styles.title, blockRequest ? { color: 'red'} : {} ]}>
                        {title} {(blockRequest && !block) ? '(Запрос на блокировку)' : null} {block ? '(Заблокирован)' : null}
                    </Text>
                    { (content) ? <Text style={styles.content}>{content}</Text> : null }
                </View>
                <View style={styles.rightContainer}>
                    <Text style={styles.time}>{time}</Text>
                    <Counter count={counter} />
                </View>
            </View>
        </TouchableOpacity>
    );
}

ListItem.defaultProps = {
    title: 'Заголовок',
    content: false,
    avatar: false,
    marker: false,
    RenderAvatar: (props) => {
        return (
            <Image
                style={{ width: 46, height: 46 }}
                source={{ uri: props.uri }}
            />
        );
    },
    time: '',
    counter: '',
    discount: '',
    active: false,
    onPress: () => {}
};

const styles = StyleSheet.create({
    active: {
        backgroundColor: '#FFC107'
    },
    container: {
        paddingVertical: 8,
        paddingHorizontal: 10,
        flexDirection: 'row'
    },
    leftContainer: {
        justifyContent: 'center'
    },
    middleContainer: {
        flex: 1,
        marginHorizontal: 12,
        justifyContent: 'center'
    },
    rightContainer: {
        alignItems: 'flex-end'
    },
    discountContainer: {
        backgroundColor: '#CED4DA',
        width: 46,
        height: 46,
        borderRadius: 46,
        justifyContent: 'center',
        alignItems: 'center'
    },
    discount: {
        color: '#212529',
        fontSize: 16
    },
    title: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 21,
        marginBottom: 3
    },
    content: {
        color: '#495057',
        fontSize: 18,
        lineHeight: 21
    },
    time: {
        flex: 1,
        color: '#868E96',
        fontSize: 16,
        lineHeight: 19
    },
    counterContainer: {
        backgroundColor: '#FFC107',
        borderRadius: 20,
        paddingHorizontal: 4,
        paddingVertical: 2
    },
    counter: {
        color: '#212529',
        fontSize: 13,
        lineHeight: 15
    },
});

export default ListItem;