import React from 'react';
import { StyleSheet, View } from 'react-native';
import TruckItem from './TruckItem';

const TruckList = ({ truckItems, onOrder }) => (
    <View>
        {truckItems.map(item => (
            <View key={item._id} style={styles.item}>
                <TruckItem
                    truck={item}
                    onOrder={onOrder}
                />
            </View>
        ))}
    </View>
);

TruckList.defaultProps = {
    truckItems: []
};

const styles = StyleSheet.create({
    item: {
        marginBottom: 24
    }
});

export default TruckList;