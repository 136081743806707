import React from 'react';
import { ScrollView, View, Text } from 'react-native';
import MyButton from './MyButton';
import GroupAddMember from './GroupAddMember';
import Alert from './Alert';
import Api from './Api';
import ListItem from './ListItem';
import NoteList from './NoteList';
import { convertRating } from './Rating';
import Logger from './Logger';

function formatRating(item) {    
    if (item.employee) {
        if (item.employeeRating) return convertRating(item.employeeRating, false);
    }
    else if (item.client) {
        if (item.clientRating) return convertRating(item.clientRating, true)+'%';
    }
    return null;
}

class GroupInfo extends React.Component {
	state = {
		userListLoading: false,
		userList: [],
		data: {},
	}

    componentDidMount() {
        Logger.log('debug', 'Информация о группе пользователей', this.props.group);
        this._loadData();
        this._loadUserList();
    }

    _loadData = () => {
        Api.request('group/get', {
        	groupId: this.props.group._id
        }).then((response) => {
            this.setState({ data: response.group });
        }, () => {
            
        });
    }

    _loadUserList = () => {
    	if (!this.props.group.hiddenMemberList) {
	    	this.setState({
	    		userListLoading: true
	    	}, () => {
		        Api.request('group/getMemberList', {
		        	groupId: this.props.group._id
		        }).then((response) => {
		            this.setState({ userListLoading: false, userList: response.userList });
		        }, () => {
		            this.setState({ userListLoading: false });
		        });
	    	});
	    }
    }

    _showMemberDlg = (item) => {
    	this.memberDlg.show({
		    title: 'Выберите действие',
		    content: 'Пользователь: '+item.full_name,
		    customContent: false,
		    actions: [{
		        title: 'Отмена',
		        action: (dlg) => { dlg._hideDialog() }
		    },{
		        title: 'Удалить',
		        action: (dlg) => {
			        Api.request('group/removeMember', {
			        	groupId: this.props.group._id,
			        	memberId: item._id
			        }).then((response) => {
			        	let index = this.state.userList.indexOf(item);
			        	if (index !== -1) {
			        		this.state.userList.splice(index, 1);
			        		this.setState({
			        			userList: this.state.userList.slice()
			        		});
			        	}
			        	dlg._hideDialog();
			        });
		        }
		    },{
		        title: 'Написать в чат',
		        action: () => {} // TODO: сделать переход в чат с клиентом
		    }]
    	});
    }

    handleMemberClick(item) {
    	if (this.props.group.constantMemberList) {
    		// TODO: сделать переход в чат с клиентом
    	} else {
    		this._showMemberDlg(item);
    	}
    }

    onAddNote = (note, hideModal) => {
        let notes = (this.state.data.notes) ? this.state.data.notes.slice() : [];
        notes.unshift(note);

        Api.request('group/save', {
            groupId: this.props.group._id,
            data: {
                notes: notes,
            }
        }).then(response => {
            this.setState({
                data: {
                    ...this.state.data,
                    notes: notes,
                }
            });
            if (hideModal) hideModal();
        });
    }

    onRemoveNote = (index) => {
        let notes = (this.state.data.notes) ? this.state.data.notes.slice() : [];
        notes.splice(index, 1);

        Api.request('group/save', {
            groupId: this.props.group._id,
            data: {
                notes: notes,
            }
        }).then(response => {
            this.setState({
                data: {
                    ...this.state.data,
                    notes: notes,
                }
            });
        });
    }

	render() {
		return (
			<ScrollView style={{
				paddingTop: 18,
				paddingBottom: 15,
				paddingLeft: 23,
				paddingRight: 15,
			}} >
				<GroupAddMember
					ref={refLink => this.addDlg = refLink}
					onSubmit={() => this._loadUserList()}
				/>
				<Alert ref={refLink => this.memberDlg = refLink} />
				<Text style={{
					fontSize: 18,
					lineHeight: 28,
					textTransform: 'uppercase',
					marginBottom: 16,
				}} >{this.props.group.membersCount} участников</Text>
				{ !this.props.group.constantMemberList ? 
				<MyButton
					style={{ marginBottom: 24 }}
					color="#212121"
					lineHeight={36}
					text="Добавить участника"
					onPress={() => this.addDlg.show(this.props.group)}
				/>
				: null }
				{ !this.props.group.hiddenMemberList ?
					<View>
	                { this.state.userListLoading ?
	                    <Text>Загрузка...</Text>
	                :
	                    <View>
	                        { this.state.userList.map((item, index) => {
	                            return (
	                                <ListItem
	                                    key={index}
	                                    title={item.full_name}
	                                    discount={formatRating(item)}
	                                    content={item.phone}
	                                    avatar={item.avatar}
	                                    onPress={() => this.handleMemberClick(item)}
	                                />
	                            );
	                        }) }
	                    </View>
	                }
	                </View>
	            : null }
				<Text style={{
					color: '#495057',
					fontSize: 14,
					lineHeight: 26,
					textTransform: 'uppercase',
					marginBottom: 16,
				}}>Заметки</Text>
                <NoteList
                    onAddNote={this.onAddNote}
                    onRemoveNote={this.onRemoveNote}
                    notes={this.state.data?.notes}
                />
				{ !this.props.group.persistent ?
				<MyButton
					style={{ marginBottom: 24 }}
					color="#F44336"
					lineHeight={36}
					text="Архивировать группу"
				/>
				: null }
			</ScrollView>
		);
	}
}

GroupInfo.defaultProps = {
    // style: {}
}

export default GroupInfo;