import React, { useState } from 'react';
import { ScrollView, Text, StyleSheet } from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import OrderForm from '../components/OrderForm.js';

const TransportationScreen = ({ navigation }) => (
    <KeyboardAwareScrollView style={styles.container}>
        <OrderForm navigation={navigation} />
    </KeyboardAwareScrollView>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 25,
        backgroundColor: '#fff'
    }
})

export default TransportationScreen