import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button } from 'react-native-paper';
import TextField from './TextField';
import defaultStyles from '../styles/main.js';
import moment from 'moment';

function OrderItem({ order, style, onOrder }) {
    const [price, setPrice] = React.useState('');

    let item = order;
    
    let description = '';
    if (item.order.truckName) description = description + 'название техники: ' + item.order.truckName + ', ';
    if (item.order.truckParams) description = description + 'характеристики: ' + item.order.truckParams + ', ';
    if (item.order.from) description = description + 'откуда: ' + item.order.from + ', ';
    if (item.order.destination) description = description + 'куда: ' + item.order.destination + ', ';
    if (item.order.payload) description = description + 'что: ' + item.order.payload + ', ';
    if (item.order.amount) description = description + 'сколько: ' + item.order.amount + ', ';
    if (item.order.date) description = description + 'когда: ' + item.order.date + ', ';
    if (item.order.time) description = description + 'во сколько: ' + item.order.time + ', ';

    if (item.order.comment) {
        if (order.order_type === 'auction') {
            description = item.order.comment;
        } else {
            description = description + 'комментарий: ' + item.order.comment;
        }
    }

    const getDate = (publishDate, signDate) => {
        if (publishDate) return publishDate;
        return signDate;
    }
    
    return (
        <View style={[styles.container, defaultStyles.shadow, style]}>
            <View style={{ paddingBottom: 10, paddingTop: 17, paddingHorizontal: 16 }}>
                <Text style={styles.text}>
                    {item.name} {(item.publishDate || item.signDate) ? moment.unix(getDate(item.publishDate, item.signDate)).format('(DD.MM.YY | HH:mm)') : ''}
                </Text>
                { order.order_type === 'auction' ?
                <View>
                    <Text style={styles.text}>{item.order.description}</Text>
                </View>
                : null }
                { order.order_type === 'transportation' ?
                <View>
                    <Text style={[ styles.text, {fontWeight: 'bold', backgroundColor: '#FFF5D9', paddingHorizontal: 10, paddingVertical: 5} ]}>Перевозка</Text>
                    { item.order.description ?
                        <Text style={styles.text}>{item.order.description}</Text>
                        :
                        null
                    }
                    { (item.order.from && item.order.destination) ?
                        <View>
                            <View style={{ flexDirection: 'row', marginBottom: 10}}>
                                <Text style={[ styles.text, { fontWeight: 'bold', marginRight: 5, width: 73 }]}>Откуда:</Text>
                                <Text style={[ styles.text, { paddingRight: 76 } ]}>{item.order.from}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: 10}}>
                                <Text style={[ styles.text, { fontWeight: 'bold', marginRight: 5, width: 73 }]}>Куда:</Text>
                                <Text style={[ styles.text, { paddingRight: 76 } ]}>{item.order.destination}</Text>
                            </View>
                        </View>
                        :
                        <View>
                            { item.order.from ?
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={[ styles.text, {fontWeight: 'bold', marginRight: 5}]}>Откуда:</Text>
                                    <Text style={[ styles.text, { paddingRight: 76 } ]}>{item.order.from}</Text>
                                </View>
                                :
                                null
                            }
                            { item.order.destination ?
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={[ styles.text, {fontWeight: 'bold', marginRight: 5}]}>Куда:</Text>
                                    <Text style={[ styles.text, { paddingRight: 55 } ]}>{item.order.destination}</Text>
                                </View>
                                :
                                null
                            }
                        </View>
                    }
                    { item.order.payload ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Что: </Text>
                            <Text style={[ styles.text, { paddingRight: 44 } ]}>{item.order.payload}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.amount ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Сколько: </Text>
                            <Text style={[ styles.text, { paddingRight: 87 } ]}>{item.order.amount}</Text>
                        </View>
                        :
                        null
                    }
                </View>
                : null }
                { order.order_type === 'delivery' ?
                <View>
                    <Text style={[ styles.text, {fontWeight: 'bold', backgroundColor: '#FFF5D9', paddingHorizontal: 10, paddingVertical: 5} ]}>Поставка</Text>
                    { item.order.description ?
                        <Text style={styles.text}>{item.order.description}</Text>
                        :
                        null
                    }
                    { (item.order.from && item.order.destination) ?
                        <View>
                            <View style={{ flexDirection: 'row', marginBottom: 10}}>
                                <Text style={[ styles.text, { fontWeight: 'bold', marginRight: 5, width: 73 }]}>Откуда:</Text>
                                <Text style={[ styles.text, { paddingRight: 76 } ]}>{item.order.from}</Text>
                            </View>
                            <View style={{ flexDirection: 'row', marginBottom: 10}}>
                                <Text style={[ styles.text, { fontWeight: 'bold', marginRight: 5, width: 73 }]}>Куда:</Text>
                                <Text style={[ styles.text, { paddingRight: 76 } ]}>{item.order.destination}</Text>
                            </View>
                        </View>
                        :
                        <View>
                            { item.order.from ?
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={[ styles.text, {fontWeight: 'bold', marginRight: 5}]}>Откуда:</Text>
                                    <Text style={[ styles.text, { paddingRight: 76 } ]}>{item.order.from}</Text>
                                </View>
                                :
                                null
                            }
                            { item.order.destination ?
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={[ styles.text, {fontWeight: 'bold', marginRight: 5}]}>Куда:</Text>
                                    <Text style={[ styles.text, { paddingRight: 55 } ]}>{item.order.destination}</Text>
                                </View>
                                :
                                null
                            }
                        </View>
                    }
                    { item.order.payload ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Что: </Text>
                            <Text style={styles.text}>{item.order.payload}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.amount ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Сколько: </Text>
                            <Text style={styles.text}>{item.order.amount}</Text>
                        </View>
                        :
                        null
                    }
                </View>
                : null }
                { order.order_type === 'trucks' ?
                <View>
                    <Text style={[ styles.text, {fontWeight: 'bold', backgroundColor: '#FFF5D9', paddingHorizontal: 10, paddingVertical: 5} ]}>Техника</Text>
                    { item.order.description ?
                        <Text style={styles.text}>{item.order.description}</Text>
                        :
                        null
                    }
                    { item.order.truckName ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Название техники: </Text>
                            <Text style={styles.text}>{item.order.truckName}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.destination ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Куда: </Text>
                            <Text style={[ styles.text, { paddingRight: 55 } ]}>{item.order.destination}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.truckParams ? <Text style={styles.text}>Характеристики: {item.order.truckParams}</Text> : null }
                    { item.order.amount ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Сколько: </Text>
                            <Text style={styles.text}>{item.order.amount}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.date ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Когда: </Text>
                            <Text style={styles.text}>{item.order.date}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.time ? <Text style={styles.text}>Во сколько: {item.order.time}</Text> : null }
                </View>
                : null }
                { order.order_type === 'materials' ?
                <View>
                    <Text style={[ styles.text, {fontWeight: 'bold', backgroundColor: '#FFF5D9', paddingHorizontal: 10, paddingVertical: 5} ]}>Доставка материала</Text>
                    { item.order.description ?
                        <Text style={styles.text}>{item.order.description}</Text>
                        :
                        null
                    }
                    { item.order.destination ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Куда: </Text>
                            <Text style={[ styles.text, { paddingRight: 55 } ]}>{item.order.destination}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.payload ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Что: </Text>
                            <Text style={styles.text}>{item.order.payload}</Text>
                        </View>
                        :
                        null
                    }
                    { item.order.amount ?
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[ styles.text, {fontWeight: 'bold'} ]}>Сколько: </Text>
                            <Text style={styles.text}>{item.order.amount}</Text>
                        </View>
                        :
                        null
                    }
                </View>
                : null }
            </View>
            { order.order_type === 'auction' ?
            <View style={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16, paddingBottom: 24, paddingHorizontal: 16, backgroundColor: '#FFF5D9' }}>
                <Text style={[styles.text, { lineHeight: 47}]}>Предложить цену</Text>
                <TextField
                    style={{
                        fontSize: 16,
                        height: 48,
                        backgroundColor: '#fff',
                        marginBottom: 24
                    }}
                    label='Цена'
                    mode='outlined'
                    value={price}
                    onChangeText={value => setPrice(value)}
                />
                <Button
                    style={{ }}
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    loading={false}
                    onPress={() => onOrder(item, price)}
                >
                    Отправить цену
                </Button>
            </View>
            :
            <View style={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16, paddingBottom: 24, paddingHorizontal: 16, backgroundColor: '#FFF5D9' }}>
                <View
                    style={{
                        flexDirection: 'row',
                    }}
                >
                    <Text style={[styles.text, { lineHeight: 47}]}>Цена: </Text>
                    <Text style={[styles.text, { lineHeight: 47, fontWeight: 'bold'}]}>{item.order.price}</Text>
                </View>
                <Button
                    style={{ }}
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    loading={false}
                    onPress={() => onOrder(item)}
                >
                    Беру заказ
                </Button>
            </View>
            }
        </View>
    );
}

OrderItem.defaultProps = {
//    title: 'Заказ №129',
//    description: 'Доставка песка в Новороссийск, ул. Ленина №23, 4 сентября, объемом 10 000 тонн 3,5 руб. тоннокилометр',
//    price: '3,5 руб. тоннокилометр'
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 16,
        borderWidth: 1,
    },
    text: {
        fontSize: 18,
        lineHeight: 28,
        color: '#212529'
    }
});

export default OrderItem;