import React from 'react';
import { Audio } from "expo-av";

class Sound {
    ready = false;
    
    constructor() {
        this.soundObject = new Audio.Sound();
        this.loadSounds();
    }

    loadSounds = async () => {
        await this.soundObject.loadAsync(require('../assets/incoming.mp3'));
        this.ready = true;
    }
    
    incoming() {
        this.soundObject.playAsync();
    }
}

let sound = new Sound();

export default function() {
    return sound;
}