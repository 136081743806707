import React from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, Alert } from 'react-native';
import defaultStyles from '../styles/main.js';

const TruckItem = ({ truck, onOrder }) => (
    <View style={styles.container}>
        <View style={styles.textContainer}>
            <View style={styles.margin}>
                <Text style={styles.textStyle}>
                    {truck.name}
                </Text>
                <Text style={styles.textStyle}>
                    {truck.price ? truck.price : 'Цена под запрос'}
                </Text>
            </View>
    
            <TouchableOpacity
                style={[defaultStyles.button, styles.button]}
                onPress={() => onOrder(truck) }
            >
                <Text style={defaultStyles.buttonText}>
                    Заказать
                </Text>
            </TouchableOpacity>
        </View>
    
        <View style={styles.imageContainer}>
            <Image
                style={styles.image}
                source={{ uri: truck.image }}
            />
        </View>
    </View>
)

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: '#CED4DA',
        borderRadius: 16,
        borderStyle: 'solid'
    },
    textContainer: {
        flex: 1,
        paddingVertical: 13,
        paddingHorizontal: 16
    },
    textStyle: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    textComplete: {
    },
    button: {
        maxWidth: 160,
    },
    imageContainer: {
        alignSelf: 'stretch',
        paddingTop: 25
    },
    image: {
        height: 100,
        width: 150,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16
    },
    margin: {
        marginBottom: 14
    }
})

export default TruckItem;