import React, { Component } from 'react';
import { StyleSheet, ScrollView, View, Text, Image, TouchableOpacity } from 'react-native';
import { Button, TextInput, RadioButton } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text';
import Checkbox from '../components/Checkbox';
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import Alert from '../components/Alert';
import Api from './Api';

import { connect } from 'react-redux';

class orderForm extends ValidationComponent {
    state = {
      loading: false,
      customerId: false,
      customerPhone: '',
      customerName: '',
      newCustomer: false,
      createCustomer: false,
      orderType: 'transportation',
      description: '',
      truckName: '',
      truckParams: '',
      date: '',
      time: '',
      from: '',
      destination: '',
      payload: '',
      amount: '',
      price: '',
      comment: ''
    };

    componentDidMount() {
        if (this.props.activeChat) {
            let customer = (this.props.activeChat.customer) ? this.props.activeChat.customer : this.props.activeChat;
            this.setState({
                customerPhone: (customer.phone) ? customer.phone.toString() : ''
            }, state => {
                this.checkUser(this.state.customerPhone); 
            });
        }
    }

    validRules = {
        transportation: {
            customerPhone: {required: true},
            customerName: {required: this.state.newCustomer},
        },
        delivery: {
            customerPhone: {required: true},
            customerName: {required: this.state.newCustomer},
        },
        trucks: {
            customerPhone: {required: true},
            customerName: {required: this.state.newCustomer},
        },
        materials: {
            customerPhone: {required: true},
            customerName: {required: this.state.newCustomer},
        }
    };

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules[this.state.orderType])) {
            let data = {};

            if (this.state.orderType === 'transportation') {
                data = {
                    description: this.state.description,
                    from: this.state.from,
                    destination: this.state.destination,
                    payload: this.state.payload,
                    amount: this.state.amount,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            if (this.state.orderType === 'delivery') {
                data = {
                    description: this.state.description,
                    from: this.state.from,
                    destination: this.state.destination,
                    payload: this.state.payload,
                    amount: this.state.amount,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            if (this.state.orderType === 'trucks') {
                data = {
                    description: this.state.description,
                    truckName: this.state.truckName,
                    truckParams: (this.state.truckParams) ? this.state.truckParams : '',
                    from: this.state.from,
                    destination: this.state.destination,
                    amount: this.state.amount,
                    date: this.state.date,
                    time: this.state.time,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            if (this.state.orderType === 'materials') {
                data = {
                    description: this.state.description,
                    destination: this.state.destination,
                    payload: this.state.payload,
                    amount: this.state.amount,
                    price: this.state.price,
                    comment: this.state.comment,
                }
            }

            this.alert.show({
                title: 'Отправить заказ на подпись директору?',
                content: `Создание заказа для ${this.state.customerName} ${this.state.customerPhone}`,
                actions: [{
                    title: 'Отмена',
                    action: dialog => dialog._hideDialog()
                }, {
                    title: 'Отправить',
                    action: dialog => {
                        Api.request('order/create', {
                            userId: this.state.customerId,
                            orderType: this.state.orderType,
                            newCustomer: this.state.newCustomer,
                            customerPhone: this.state.customerPhone.replace(/\D+/g,''),
                            customerName: this.state.customerName,
                            form: data
                        }).then(response => {
                            dialog._hideDialog();
                            this.setState({ loading: false });
                            if (response.success) {
                                if (this.props.onSubmit) this.props.onSubmit();
                            } else {
                                Api.alert();
                            }
                        });
                    }
                }]
            });
        }
    };

    checkUser(phone) {
        if (typeof phone === 'number') phone = phone.toString();
        phone = phone.replace(/\D+/g,'');
        Api.request('user/getByPhone', {
            phone: phone
        }, {}, false).then(response => {
            if (response.success) {
                let userName = [
                    response.user.last_name,
                    response.user.name,
                    response.user.second_name,
                ].join(' ');
                this.setState({
                    newCustomer: false,
                    createCustomer: false,
                    customerId: response.user._id,
                    customerName: userName,
                });
            } else {
                this.setState({
                    customerId: false,
                    newCustomer: true,
                    createCustomer: false
                });
            }
        });
    }

    handleCustomerPhone(value) {
        this.setState({ customerPhone: value }, state => {
            if (this.state.customerPhone.length === 18) {
                this.checkUser(this.state.customerPhone);
            }
        });
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <Alert ref={(node) => { this.alert = node}} />
                <ScrollView style={{ paddingLeft: 23, paddingRight: 15 }}>
                    <TextInput style={{ flex: 1 }}
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Заказчик (телефон)'
                        mode='outlined'
                        value={this.state.customerPhone}
                        onChangeText={value => this.handleCustomerPhone(value)}
                        autoCorrect={false}
                        keyboardType="phone-pad"
                        returnKeyType="next"
                        render={props =>
                            <TextInputMask
                                {...props}
                                type={'custom'}
                                options={{
                                    mask: '+7 (999) 999-99-99'
                                }}
                            />
                        }
                        error={this.isFieldInError('customerPhone')}
                    />
                    <TextInput
                        style={[styles.textInput, styles.textInputBottom]}
                        label='Заказчик (имя)'
                        mode='outlined'
                        value={this.state.customerName}
                        disabled={!this.state.newCustomer}
                        onChangeText={value => this.setState({ customerName: value })}
                    />
                    { this.state.newCustomer ?
                    <View>
                        <Text style={{ marginLeft: 14, fontSize: 14, lineHeight: 18, color: 'red' }}>Заказчика нет в базе</Text>
                        <Checkbox
                            title='добавить заказчика в базу'
                            onCheck={value => this.setState({ createCustomer: value }) }
                        />
                    </View> : null }
                    <View style={{ height: 1, backgroundColor: '#CED4DA', marginTop: 10, marginBottom: 19 }}></View>
                    <View style={{ marginLeft: -10 }}>
                        <RadioButton.Group
                            onValueChange={value => this.setState({ orderType: value })}
                            value={this.state.orderType}
                        >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <RadioButton
                                    value="transportation"
                                    color="#FFC107"
                                />
                                <Text style={{ fontSize: 18, lineHeight: 28 }}>Перевозка</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <RadioButton
                                    value="delivery"
                                    color="#FFC107"
                                />
                                <Text style={{ fontSize: 18, lineHeight: 28 }}>Поставка</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <RadioButton
                                    value="trucks"
                                    color="#FFC107"
                                />
                                <Text style={{ fontSize: 18, lineHeight: 28 }}>Спец. техника</Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>

                                <RadioButton
                                    value="materials"
                                    color="#FFC107"
                                />
                                <Text style={{ fontSize: 18, lineHeight: 28 }}>Материалы</Text>
                            </View>
                        </RadioButton.Group>
                    </View>
                    <View style={{ height: 1, backgroundColor: '#CED4DA', marginTop: 10, marginBottom: 19 }}></View>
                    { (this.state.orderType === 'transportation') ?
                    <View>
                        <Text style={styles.label}>Откуда</Text>
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Город, нас. пункт'
                            mode='outlined'
                            value={this.state.from}
                            onChangeText={value => this.setState({ from: value })}
                            error={this.isFieldInError('from')}
                        />
                        <Text style={styles.label}>Куда</Text>
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Город, нас. пункт'
                            mode='outlined'
                            value={this.state.destination}
                            onChangeText={value => this.setState({ destination: value })}
                            error={this.isFieldInError('destination')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Что перевозим'
                            mode='outlined'
                            value={this.state.payload}
                            onChangeText={value => this.setState({ payload: value })}
                            error={this.isFieldInError('payload')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Сколько перевозим'
                            mode='outlined'
                            value={this.state.amount}
                            onChangeText={value => this.setState({ amount: value })}
                            error={this.isFieldInError('amount')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Цена работы'
                            mode='outlined'
                            value={this.state.price}
                            onChangeText={value => this.setState({ price: value })}
                            error={this.isFieldInError('price')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.description}
                            onChangeText={value => this.setState({ description: value })}
                            error={this.isFieldInError('description')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Внутренний комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.comment}
                            onChangeText={value => this.setState({ comment: value })}
                        />
                    </View>
                    : null }
                    { (this.state.orderType === 'delivery') ?
                    <View>
                        <Text style={styles.label}>Откуда</Text>
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Город, нас. пункт'
                            mode='outlined'
                            value={this.state.from}
                            onChangeText={value => this.setState({ from: value })}
                            error={this.isFieldInError('from')}
                        />
                        <Text style={styles.label}>Куда</Text>
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Город, нас. пункт'
                            mode='outlined'
                            value={this.state.destination}
                            onChangeText={value => this.setState({ destination: value })}
                            error={this.isFieldInError('destination')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Что перевозим'
                            mode='outlined'
                            value={this.state.payload}
                            onChangeText={value => this.setState({ payload: value })}
                            error={this.isFieldInError('payload')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Сколько перевозим'
                            mode='outlined'
                            value={this.state.amount}
                            onChangeText={value => this.setState({ amount: value })}
                            error={this.isFieldInError('amount')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Цена работы'
                            mode='outlined'
                            value={this.state.price}
                            onChangeText={value => this.setState({ price: value })}
                            error={this.isFieldInError('price')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.description}
                            onChangeText={value => this.setState({ description: value })}
                            error={this.isFieldInError('description')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Внутренний комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.comment}
                            onChangeText={value => this.setState({ comment: value })}
                        />
                    </View>
                    : null }
                    { (this.state.orderType === 'trucks') ?
                    <View>
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Название техники'
                            mode='outlined'
                            value={this.state.truckName}
                            onChangeText={value => this.setState({ truckName: value })}
                            error={this.isFieldInError('truckName')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Куда (город, нас. пункт)'
                            mode='outlined'
                            value={this.state.destination}
                            onChangeText={value => this.setState({ destination: value })}
                            error={this.isFieldInError('destination')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Характеристики'
                            mode='outlined'
                            value={this.state.truckParams}
                            onChangeText={value => this.setState({ truckParams: value })}
                            error={this.isFieldInError('truckParams')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Сколько'
                            mode='outlined'
                            value={this.state.amount}
                            onChangeText={value => this.setState({ amount: value })}
                            error={this.isFieldInError('amount')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Когда'
                            mode='outlined'
                            value={this.state.date}
                            onChangeText={value => this.setState({ date: value })}
                            error={this.isFieldInError('date')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Во сколько'
                            mode='outlined'
                            value={this.state.time}
                            onChangeText={value => this.setState({ time: value })}
                            error={this.isFieldInError('time')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Цена работы'
                            mode='outlined'
                            value={this.state.price}
                            onChangeText={value => this.setState({ price: value })}
                            error={this.isFieldInError('price')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.description}
                            onChangeText={value => this.setState({ description: value })}
                            error={this.isFieldInError('description')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Внутренний комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.comment}
                            onChangeText={value => this.setState({ comment: value })}
                        />
                    </View>
                    : null }
                    { (this.state.orderType === 'materials') ?
                    <View>
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Название материала'
                            mode='outlined'
                            value={this.state.payload}
                            onChangeText={value => this.setState({ payload: value })}
                            error={this.isFieldInError('payload')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Куда (город, нас. пункт)'
                            mode='outlined'
                            value={this.state.destination}
                            onChangeText={value => this.setState({ destination: value })}
                            error={this.isFieldInError('destination')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Сколько перевозим'
                            mode='outlined'
                            value={this.state.amount}
                            onChangeText={value => this.setState({ amount: value })}
                            error={this.isFieldInError('amount')}
                        />
                        <TextInput
                            style={[styles.textInput, styles.textInputBottom]}
                            label='Цена'
                            mode='outlined'
                            value={this.state.price}
                            onChangeText={value => this.setState({ price: value })}
                            error={this.isFieldInError('price')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.description}
                            onChangeText={value => this.setState({ description: value })}
                            error={this.isFieldInError('description')}
                        />
                        <TextInput
                            style={styles.textArea}
                            label='Внутренний комментарий'
                            multiline={true}
                            numberOfLines={5}
                            mode='outlined'
                            value={this.state.comment}
                            onChangeText={value => this.setState({ comment: value })}
                        />
                    </View> : null }
                </ScrollView>
                <Button
                    style={{ marginLeft: 23, marginRight: 30, marginBottom: 24 }}
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    loading={this.state.loading}
                    disabled={this.state.customerPhone.replace(/\D+/g,'').length != 11 || (this.state.newCustomer && !this.state.createCustomer)}
                    onPress={() => this.onSubmit()}
                >
                    Отправить на подпись
                </Button>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    imageContainer: {
        width: 40
    },
    image: {
        width: 32,
        height: 147,
        marginTop: 50
    },
    formContainer: {
        flex: 1,
    },
    label: {
        marginBottom: 7,
        fontSize: 21,
        lineHeight: 25,
        color: '#212529'
    },
    button: {
        padding: 14,
        paddingVertical: 14
    },
    buttonText: {
        fontSize: 16,
        lineHeight: 19
    },
    textInput: {
        fontSize: 16,
        height: 48,
        backgroundColor: '#fff',
    },
    textInputBottom: {
        marginBottom: 10
    },
    textArea: {
        fontSize: 16,
        backgroundColor: '#fff',
        marginBottom: 20
    }
});

const mapStateToProps = state => {
    return {
        activeChat: state.chatList.activeChat,
    }
}

export default connect(mapStateToProps, null)(orderForm);