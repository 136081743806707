const INITIAL_STATE = {
	data: {},
	changed: false,
	loading: true,
	saving: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOAD_ACCOUNT_INFO':
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case 'UPDATE_ACCOUNT_INFO':
            return {
                ...state,
                data: {
                	...state.data,
                	...action.payload,
                },
                changed: true,
            };
        case 'EXTERNAL_UPDATE_ACCOUNT_INFO':
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        case 'START_SAVE_ACCOUNT_INFO':
            return {
                ...state,
                saving: true,
            };
        case 'FINISH_SAVE_ACCOUNT_INFO':
            let test= {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                changed: false,
                saving: false,
            };
            console.log(test);
            return test;
        default:
            return state;
    }
};