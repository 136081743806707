import React from 'react';
import { TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { pickDocumentAsync } from './mediaUtils'; 

export default class WebChatActions extends React.Component {
	onActionsPress = () => {
		pickDocumentAsync(this.props.onPick);
	}

    render() {
        return (
            <TouchableOpacity
                style={{
				    width: 26,
				    height: 26,
				    marginLeft: 10,
				    marginBottom: 10,
                }}
                onPress={this.onActionsPress}
            >
                <MaterialIcons name="attach-file" size={24} color="black" />
            </TouchableOpacity>
        )
    }
}

WebChatActions.defaultProps = {
    onImage: () => {},
}