import React, { Component } from 'react';
import { Text } from 'react-native';
import { TouchableRipple } from 'react-native-paper';

class MyButton extends Component {
	render() {
		return (
            <TouchableRipple
                onPress={this.props.onPress}
                rippleColor={this.props.rippleColor}
                style={[ { borderColor: this.props.color, borderWidth: 1, borderRadius: 4 }, this.props.style ]}
            >
            	{ this.props.children ? this.props.children :
                <Text style={{ textAlign: 'center', textTransform: 'uppercase', color: this.props.color, lineHeight: this.props.lineHeight }}>{this.props.text}</Text>
            	}
            </TouchableRipple>
		);
	}
}

MyButton.defaultProps = {
	onPress: () => {},
	color: '#F03D3E',
	rippleColor: 'rgba(0, 0, 0, .32)',
	lineHeight: 46,
	text: 'Кнопка',
	style: {}
}

export default MyButton;