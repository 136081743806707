import React from 'react';
import { ScrollView, View, StyleSheet, Dimensions } from 'react-native';
import { TextInput, Switch, Button, Paragraph, Text, Dialog, Portal } from 'react-native-paper';
import ValidationComponent from 'react-native-form-validator';
import Api from './Api';

const window = Dimensions.get("window");

class Alert extends ValidationComponent {
    state = {
        visible: false,
        loading: false,
        userId: false,
        actions: [],
        isClient: true,
        isCard: true,
        clientRating: 0,
        cardName: '',
        cardNumber: '',
        cardBank: '',
        cardBik: '',
        cardKorrAccount: '',
        cardInn: '',
        cardKpp: '',
        companyName: '',
        inn: '',
        kpp: '',
        ogrn: '',
        bankAccountNumber: '',
        bank: '',
        bik: '',
        ksNumber: '',
    };

    componentDidMount = () => {
        this.setState({
            title: 'test',
            content: 'test',
            customContent: false,
            actions: [{
                title: 'Отмена',
                action: () => { this._hideDialog() }
            }, {
                title: 'Сохранить',
                action: () => { this.onSubmit() }
            }],
            onDismiss: () => { this._hideDialog() }
        });
    }

    show = (clientInfo, updateClientInfo) => {
        this.updateClientInfo = updateClientInfo;

        let state = {
            userId: clientInfo._id,
            isClient: clientInfo.client ? true : false,
            visible: true,
        };
        
        if (!clientInfo.paymentMethod) clientInfo.paymentMethod = 'card';
        if (!clientInfo.paymentData) clientInfo.paymentData = {};
        
        if (clientInfo.paymentMethod == 'card') {
            if (!clientInfo.paymentData.cardNumber) clientInfo.paymentData.cardNumber = '';
            if (!clientInfo.paymentData.cardName) clientInfo.paymentData.cardName = '';
            if (!clientInfo.paymentData.cardBank) clientInfo.paymentData.cardBank = '';
            if (!clientInfo.paymentData.cardBik) clientInfo.paymentData.cardBik = '';
            if (!clientInfo.paymentData.cardKorrAccount) clientInfo.paymentData.cardKorrAccount = '';
            if (!clientInfo.paymentData.cardInn) clientInfo.paymentData.cardInn = '';
            if (!clientInfo.paymentData.cardKpp) clientInfo.paymentData.cardKpp = '';
            state.cardNumber = clientInfo.paymentData.cardNumber.toString();
            state.cardName = clientInfo.paymentData.cardName.toString();
            state.cardBank = clientInfo.paymentData.cardBank.toString();
            state.cardBik = clientInfo.paymentData.cardBik.toString();
            state.cardKorrAccount = clientInfo.paymentData.cardKorrAccount.toString();
            state.cardInn = clientInfo.paymentData.cardInn.toString();
            state.cardKpp = clientInfo.paymentData.cardKpp.toString();
            state.isCard = true;
        }
        
        if (clientInfo.paymentMethod == 'invoice') {
            if (!clientInfo.paymentData.companyName) clientInfo.paymentData.companyName = '';
            if (!clientInfo.paymentData.inn) clientInfo.paymentData.inn = '';
            if (!clientInfo.paymentData.kpp) clientInfo.paymentData.kpp = '';
            if (!clientInfo.paymentData.ogrn) clientInfo.paymentData.ogrn = '';
            if (!clientInfo.paymentData.bankAccountNumber) clientInfo.paymentData.bankAccountNumber = '';
            if (!clientInfo.paymentData.bank) clientInfo.paymentData.bank = '';
            if (!clientInfo.paymentData.ksNumber) clientInfo.paymentData.ksNumber = '';
            
            state.companyName = clientInfo.paymentData.companyName;
            state.inn = clientInfo.paymentData.inn.toString();
            state.kpp = clientInfo.paymentData.kpp.toString();
            state.ogrn = clientInfo.paymentData.ogrn.toString();
            state.bankAccountNumber = clientInfo.paymentData.bankAccountNumber.toString();
            state.bank = clientInfo.paymentData.bank;
            state.bik = clientInfo.paymentData.bik;
            state.ksNumber = clientInfo.paymentData.ksNumber.toString();
            state.isCard = false;
        }
        
        this.setState(state);
    }

    validRules = {
        name: {required: true},
    }

    onSubmit = () => {
        if (this.state.loading) return;
        // if (this.state.isClient) {
            if (this.validate(this.validRules)) {
                this.setState({ loading: true }, () => {
                    let form;
                    if (this.state.isCard) {
                        form = {
                            client: true,
                            paymentMethod: 'card',
                            paymentData: {
                                cardNumber: this.state.cardNumber,
                                cardName: this.state.cardName,
                                cardBank: this.state.cardBank,
                                cardBik: this.state.cardBik,
                                cardKorrAccount: this.state.cardKorrAccount,
                                cardInn: this.state.cardInn,
                                cardKpp: this.state.cardKpp,
                            }
                        }
                    } else {
                        form = {
                            client: true,
                            paymentMethod: 'invoice',
                            paymentData: {
                                companyName: this.state.companyName,
                                inn: this.state.inn,
                                kpp: this.state.kpp,
                                ogrn: this.state.ogrn,
                                bankAccountNumber: this.state.bankAccountNumber,
                                bank: this.state.bank,
                                bik: this.state.bik,
                                ksNumber: this.state.ksNumber
                            }
                        }
                    }
                    this.setState({ loading: true });
                    Api.request('account/saveInfo', {
                        userId: this.state.userId,
                        form: form
                    }).then(response => {
                        if (this.updateClientInfo) this.updateClientInfo(response.user);
                        this.setState({ loading: false });
                        this._hideDialog();
                    });
                });
            }
        // } else {
        //     this.setState({ loading: true });
        //     Api.request('account/saveInfo', {
        //         userId: this.state.userId,
        //         form: {
        //             client: false
        //         }
        //     }).then(response => {
        //         if (this.updateClientInfo) this.updateClientInfo(response.data);
        //         this.setState({ loading: false });
        //         this._hideDialog();
        //     });
        // }
    }

    _onTogglePaymentMethodSwitch = () => this.setState(state => ({ isCard: !state.isCard }));

    _showDialog = () => this.setState({ visible: true });

    _hideDialog = () => {
        this.updateClientInfo = false;
        this.setState({ visible: false });
    }

    render() {
        return (
            <Portal>
                <Dialog
                    style={{
                        maxHeight: window.height-80,
                        minWidth: 640, 
                        marginHorizontal: 'auto' 
                    }}
                    visible={this.state.visible}
                    onDismiss={this.state.onDismiss}
                >
                    <Dialog.Title>Платежные данные</Dialog.Title>
                    <Dialog.Content style={{ flex: 1}}>
                        <ScrollView style={{ flex: 1}}>
                            <Text style={styles.text}>Реквизиты для вознаграждения</Text>
                            <View style={[styles.switchContainer, styles.marginTop]}>
                                <Switch
                                    color="#FFC107"
                                    value={this.state.isCard}
                                    onValueChange={this._onTogglePaymentMethodSwitch}
                                />
                                <Text style={styles.switchLabel}>Оплата на карту</Text>
                            </View>
                            <View style={[styles.switchContainer, styles.marginTop]}>
                                <Switch
                                    color="#FFC107"
                                    value={!this.state.isCard}
                                    onValueChange={this._onTogglePaymentMethodSwitch}
                                />
                                <Text style={styles.switchLabel}>Оплата на счет организации</Text>
                            </View>
                            { this.state.isCard ? <View>
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardName}
                                    onChangeText={value => this.setState({ cardName: value })}
                                    label='Получатель'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardNumber}
                                    onChangeText={value => this.setState({ cardNumber: value })}
                                    label='Номер счета'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardBank}
                                    onChangeText={value => this.setState({ cardBank: value })}
                                    label='Банк получателя'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardBik}
                                    onChangeText={value => this.setState({ cardBik: value })}
                                    label='БИК'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardKorrAccount}
                                    onChangeText={value => this.setState({ cardKorrAccount: value })}
                                    label='Корр. счет'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardInn}
                                    onChangeText={value => this.setState({ cardInn: value })}
                                    label='ИНН'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.cardKpp}
                                    onChangeText={value => this.setState({ cardKpp: value })}
                                    label='КПП'
                                    mode='outlined'
                                />
                            </View> :
                            <View>
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.companyName}
                                    onChangeText={value => this.setState({ companyName: value })}
                                    label='Наименование юр.лица'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.inn}
                                    onChangeText={value => this.setState({ inn: value })}
                                    label='ИНН'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.kpp}
                                    onChangeText={value => this.setState({ kpp: value })}
                                    label='КПП'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.ogrn}
                                    onChangeText={value => this.setState({ ogrn: value })}
                                    label='ОГРН'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.bankAccountNumber}
                                    onChangeText={value => this.setState({ bankAccountNumber: value })}
                                    label='Р/с'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.bank}
                                    onChangeText={value => this.setState({ bank: value })}
                                    label='Банк'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.bik}
                                    onChangeText={value => this.setState({ bik: value })}
                                    label='БИК'
                                    mode='outlined'
                                />
                                <TextInput
                                    style={[styles.textInput, styles.marginTop]}
                                    value={this.state.ksNumber}
                                    onChangeText={value => this.setState({ ksNumber: value })}
                                    label='К/с'
                                    mode='outlined'
                                />
                            </View> }
                        </ScrollView>
                    </Dialog.Content>
                    <Dialog.Actions>
                        {this.state.actions.map((item, i) => (
                            <Button key={i} onPress={() => item.action(this)}>{item.title}</Button>
                        ))}
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        );
    }
}

Alert.defaultProps = {
    content: '',
    customContent: false,
    actions: [{
        title: 'ОК',
        action: () => {}
    }],
    onDismiss: () => { this._hideDialog }
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 24,
        paddingHorizontal: 16,
        backgroundColor: '#fff'
    },
    marginTop: {
        marginTop: 23
    },
    textInput: {
        height: 48,
    },
    text: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    divider: {
        height: 1,
        backgroundColor: '#CED4DA',
        marginVertical: 36
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    switchLabel: {
        color: '#212529',
        fontSize: 16,
        lineHeight: 19,
        marginLeft: 16
    },
    switch: {
        color: '#FFC107'
    }
})

export default Alert;