import React, { Component } from 'react';
import { View } from 'react-native';
import MyButton from './MyButton';
import Alert from './Alert';
import App from './App';

class Button extends Component {
	render() {
		return (
			<View>
				<Alert
					ref={(refLink) => this.Alert = refLink}
				    title='Выход'
				    content='Вы действительно хотите выйти из аккаунта?'
				    actions={[{
				        title: 'Да',
				        action: () => App().logout()
				    }, {
				    	title: 'Нет',
				    	action: () => this.Alert._hideDialog()
				    }]}
				/>
	            <MyButton
	                onPress={() => this.Alert.show()}
	                color={this.props.color}
	                text='Выйти'
	                style={this.props.style}
	            />
	        </View>
		);
	}
}

Button.defaultProps = {
	style: {},
	color: '#F03D3E',
}

export default Button;