import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';
import defaultStyles from '../styles/main.js';

function ClientType(customer) {
    let clientResult = '';
    if (customer.client) {
        clientResult = clientResult + 'Заказчик';
        let level = '';
        if (customer.clientRating >= 1000) level = 'уровень 1%';
        if (customer.clientRating >= 10000) level = 'уровень 2%';
        if (customer.clientRating >= 50000) level = 'уровень 3%';
        if (customer.clientRating >= 250000) level = 'уровень 4%';
        if (customer.clientRating >= 500000) level = 'уровень 5%';
        if (level) clientResult = clientResult + ' ' + level;
    }
    let employeeResult = '';
    if (customer.employee) {
        employeeResult = employeeResult + 'Перевозчик';
        let level = '';
        if (customer.clientRating >= 100) level = 'уровень 1';
        if (customer.clientRating >= 200) level = 'уровень 2';
        if (customer.clientRating >= 300) level = 'уровень 3';
        if (customer.clientRating >= 400) level = 'уровень 4';
        if (customer.clientRating >= 500) level = 'уровень 5';
        if (level) employeeResult = employeeResult + ' ' + level;
    }
    
    let result = '';
    if (clientResult && employeeResult) return clientResult + ' / ' + employeeResult;
    if (clientResult) return clientResult;
    if (employeeResult) return employeeResult;
    return '';
}

function TitleBar({ data, onSidebarShow }) {
    let customer = {};
    if (data.customer) customer = data.customer; else customer = data;
    return (
        <View>
            {data ?
            <View style={ styles.container }>
                {data.type == 'Заказ' ?
                <View style={styles.userData}>
                    { customer.nickname ?
                        <Text style={styles.text}>
                            {customer.nickname} ({customer.name})
                        </Text>
                        :
                        <Text style={styles.text}>
                            {customer.name}
                        </Text>
                    }
                    <Text style={styles.text}>{customer.phone}</Text>
                </View>
                :
                <View style={styles.userData}>
                    { customer.nickname ?
                        <Text style={styles.text}>
                            {customer.nickname} ({customer.name}) - {ClientType(customer)}
                        </Text>
                        :
                        <Text style={styles.text}>
                            {customer.name} - {ClientType(customer)}
                        </Text>
                    }
                    <Text style={styles.text}>{customer.phone}</Text>
                </View> }
                <View style={styles.iconContainer}>
                    <IconButton
                        icon="locker-multiple"
                        color='#868E96'
                        size={20}
                        onPress={onSidebarShow}
                    />
                </View>
            </View> : null }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingVertical: 5
    },
    userData: {
        flex: 1
    },
    text: {
        fontSize: 18,
        lineHeight: 21,
        color: '#212529'
    },
    buttonContainer: {
        marginRight: 34
    },
    iconContainer: {
    }
});

export default TitleBar;