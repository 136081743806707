import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import App from '../components/App';
import { alertMsg } from '../components/Api';
import Api from '../components/Api';
import Logger from '../components/Logger';

import PaymentScreen from '../screens/PaymentScreen';
import ClientAccountScreen from '../screens/ClientAccountScreen';
import EmployeeAccountScreen from '../screens/EmployeeAccountScreen';

import { connect } from 'react-redux';
import { loadAccountInfo, updateAccountInfo, saveAccountInfo } from '../redux/actions/account';

const Tab = createMaterialTopTabNavigator();
const buttonHeight = 76;

class SettingsScreen extends React.Component {
    state = {
        currentTab: 'client',
        keyboardHeight: 0,
    }

    componentDidMount() {
        this._loadAccountInfo();
    }

    componentWillUnmount() {
    }

    _loadAccountInfo = async () => {
        await this.props.loadAccountInfo();
        if (!this.props.accountInfo.data.client && this.props.accountInfo.data.employee) {
            this.setState({ currentTab: 'employee' });
        }
    }

    onChangeFormData = (data) => {
        if (data.client !== undefined) {
            data.employee = !data.client;
        } else if (data.employee !== undefined) {
            data.client = !data.employee;
        }
        this.props.updateAccountInfo(data);
    }

    _onSubmit = async () => {
        await this.props.saveAccountInfo(this.props.accountInfo.data, true);
        App().changeClientType({
            client: this.props.accountInfo.data.client,
            employee: this.props.accountInfo.data.employee,
        });
    }

    render() {
        let buttonPannelHeight;
        if (this.props.accountInfo.changed) {
            buttonPannelHeight = this.state.keyboardHeight + buttonHeight;
        } else {
            buttonPannelHeight = this.state.keyboardHeight;
        }
        return (
            <View style={{
                flex: 1,
            }}>
                {this.props.accountInfo.loading ?
                    <View style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 32,
                        backgroundColor: '#fff',
                    }}>
                        <Text>Загрузка данных...</Text>
                    </View>
                    :
                    <View style={{
                        flex: 1,
                    }}>
                        <View style={{
                            backgroundColor: '#212529',
                            flexDirection: 'row',
                        }}>
                            <TouchableOpacity
                                style={{
                                    flex: 1,
                                    height: 48,
                                }}
                                onPress={() => this.setState({ currentTab: 'client' })}
                            >
                                <Text style={{
                                    textAlign: 'center',
                                    height: 45,
                                    lineHeight: 45,
                                    color: this.state.currentTab === 'client' ? '#FFC107' : '#FFFFFF',
                                    textTransform: 'uppercase',
                                }}>Заказчик</Text>
                                <View style={[
                                    { height: 3 },
                                    this.state.currentTab === 'client' ? { backgroundColor: '#FFC107' } : {},
                                ]}></View>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    flex: 1,
                                    height: 48,
                                }}
                                onPress={() => this.setState({ currentTab: 'employee' })}
                            >
                                <Text style={{
                                    textAlign: 'center',
                                    height: 45,
                                    lineHeight: 45,
                                    color: this.state.currentTab === 'employee' ? '#FFC107' : '#FFFFFF',
                                    textTransform: 'uppercase',
                                }}>Перевозчик</Text>
                                <View style={[
                                    { height: 3 },
                                    this.state.currentTab === 'employee' ? { backgroundColor: '#FFC107' } : {},
                                ]}></View>
                            </TouchableOpacity>
                        </View>
                        <View style={{
                            flex: 1,
                        }}>
                            { this.state.currentTab === 'client' ?
                                <ClientAccountScreen
                                    formData={this.props.accountInfo.data}
                                    onChange={this.onChangeFormData}
                                    bottomContentInset={false}
                                    onKBShow={height => this.setState({ keyboardHeight: height ? height - 49 : 0})}
                                    onKBHide={() => this.setState({ keyboardHeight: 0})}
                                />
                                :
                                <EmployeeAccountScreen
                                    formData={this.props.accountInfo.data}
                                    showVerificationButton={!this.props.accountInfo.changed}
                                    onChange={this.onChangeFormData}
                                    bottomContentInset={false}
                                    onKBShow={height => this.setState({ keyboardHeight: height ? height - 49 : 0})}
                                    onKBHide={() => this.setState({ keyboardHeight: 0})}
                                    navigation={this.props.navigation}
                                />
                            }
                        </View>
                        <View style={{
                                backgroundColor: '#fff',
                                height: buttonPannelHeight,
                            }}>
                            {this.props.accountInfo.changed ?
                                <View style={{
                                    height: buttonHeight,
                                }}>
                                    <Button
                                        style={{
                                            marginTop: 14,
                                            marginBottom: 14,
                                            marginHorizontal: 16,
                                        }}
                                        contentStyle={{height: 48}}
                                        mode="contained"
                                        color="#FFC107"
                                        loading={this.props.accountInfo.saving}
                                        onPress={this._onSubmit}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </View>
                                :
                                null
                            }
                        </View>
                    </View>
                }
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}

const SettingsScreenComponent = connect(mapStateToProps, {
    loadAccountInfo,
    updateAccountInfo,
    saveAccountInfo,
})(SettingsScreen);

// const SettingsScreen = () => {
//     return (
//         <Tab.Navigator tabBarOptions={{
//             style: {
//                 backgroundColor: '#212529'
//             },
// //            labelStyle: {
// //                color: '#fff'
// //            },
//             activeTintColor: '#FFC107',
//             inactiveTintColor: '#fff'
//         }}>
//             <Tab.Screen name="ClientAccount" options={{tabBarLabel: 'Заказчик'}} component={ClientAccountScreen} />
//             <Tab.Screen name="EmployeeAccount" options={{tabBarLabel: 'Владелец техники'}} component={EmployeeAccountScreen} />
//         </Tab.Navigator>
//     );
// }

const AccountScreen = () => {
    return (
        <Tab.Navigator>
          <Tab.Screen name="Вознаграждение" component={PaymentScreen} />
          <Tab.Screen name="AccountInfo" options={{tabBarLabel: 'Мои данные'}} component={SettingsScreenComponent} />
        </Tab.Navigator>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default AccountScreen;