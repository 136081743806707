import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import LogoutButton from './LogoutButton';
import App from '../components/App';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';

import { connect } from 'react-redux';

class ManagerProfile extends React.Component {

  _onCreateGroup = () => {
    this.props.navigation.emit({
      type: 'onCreateGroup',
    });
  }

  _closeDrawer = () => {
    this.props.navigation.closeDrawer();
  }

	render() {
		return (
			<View>
        <View style={{
          marginHorizontal: 15,
          marginVertical: 15,
        }}>
          <TouchableOpacity onPress={this._closeDrawer}>
            <AntDesign name="arrowleft" size={24} color="black" />
          </TouchableOpacity>
        </View>
        <Button
          style={{
            marginHorizontal: 15,
          }}
          contentStyle={{
            height: 48,
          }}
          icon="account-supervisor-circle"
          mode="contained"
          color="#FFC107"
          onPress={this._onCreateGroup}>
          Создать группу
        </Button>
        <View
          style={{
            backgroundColor: '#E9ECEF',
            height: 228,
            marginVertical: 15,
          }}
        >

        </View>
        <View style={{ marginHorizontal: 15 }} >
          <View style={{ flexDirection: 'row', alignItems: 'center', height: 48 }} >
            <View style={{ width: 50 }} >
              <MaterialIcons name="face" size={24} color="#868E96" />
            </View>
            <Text style={{ fontSize: 18, color: '#212121' }}>
              {this.props.accountInfo?.data?.last_name} {this.props.accountInfo?.data?.name} {this.props.accountInfo?.data?.second_name}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center', height: 48, marginBottom: 15 }} >
            <View style={{ width: 50 }} >
              <MaterialIcons name="phone" size={24} color="#868E96" />
            </View>
            <Text style={{ fontSize: 18, color: '#212121' }}>{this.props.accountInfo?.data?.phone}</Text>
          </View>
          <LogoutButton
            color='#212121'
            style={{ borderColor: '#FFC107' }}
          />
        </View>
      </View>
		);
	}
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}

export default connect(mapStateToProps, {
})(ManagerProfile);