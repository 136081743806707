import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { IconButton } from 'react-native-paper';

class Checkbox extends React.Component {
    state = {
        checked: false
    }

    componentDidMount() {
        this.setState({ checked: this.props.checked });
    }

    onPress = () => {
        this.setState(state => {
            if (this.props.onCheck) this.props.onCheck(!state.checked);
            return {
                checked: !state.checked
            }
        });
    }

    render() {
        return (
            <View style={styles.container}>
                  <IconButton
                    icon={this.state.checked ? 'checkbox-marked' : 'checkbox-blank-outline'}
                    color={this.props.color}
                    size={this.props.size}
                    onPress={this.onPress}
                  />
                <TouchableOpacity onPress={this.onPress}>
                    <Text>{this.props.title}</Text>
                </TouchableOpacity>
            </View>
        );
    }
}

Checkbox.defaultProps = {
    color: 'black',
    size: 20,
    checked: false
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    }
})

export default Checkbox;