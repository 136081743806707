import Api from '../../components/Api';

export const loadAccountInfo = () => async (dispatch) => {
    let response = await Api.request('account/getInfo', {});
    if (response.success) {
        let formData = response.user;
        delete formData['_uid'];
        delete formData['created'];
        delete formData['custom-order'];
        delete formData['modified'];
        delete formData['user_id'];

        let data = {
            paymentMethod: 'card',
            paymentData: {
                cardNumber: '',
                cardName: '',
                cardBank: '',
                cardBik: '',
                cardKorrAccount: '',
                cardInn: '',
                cardKpp: '',
                companyName: '',
                inn: '',
                kpp: '',
                ogrn: '',
                bankAccountNumber: '',
                bank: '',
                ksNumber: '',
            },
            companyData: {
                companyName: '',
                inn: '',
            },
            trucks: [],
        };

        data = {
            ...data,
            ...formData,
        };

        if (formData.companyData?.companyName) data.companyData.companyName = formData.companyData.companyName.toString();
        if (formData.companyData?.inn) data.companyData.inn = formData.companyData.inn.toString();

        if (formData.paymentData?.cardNumber) data.paymentData.cardNumber = formData.paymentData.cardNumber.toString();
        if (formData.paymentData?.cardName) data.paymentData.cardName = formData.paymentData.cardName.toString();
        if (formData.paymentData?.cardBank) data.paymentData.cardBank = formData.paymentData.cardBank.toString();
        if (formData.paymentData?.cardBik) data.paymentData.cardBik = formData.paymentData.cardBik.toString();
        if (formData.paymentData?.cardKorrAccount) data.paymentData.cardKorrAccount = formData.paymentData.cardKorrAccount.toString();
        if (formData.paymentData?.cardInn) data.paymentData.cardInn = formData.paymentData.cardInn.toString();
        if (formData.paymentData?.cardKpp) data.paymentData.cardKpp = formData.paymentData.cardKpp.toString();
        
        if (formData.paymentData?.inn) data.paymentData.inn = formData.paymentData.inn.toString();
        if (formData.paymentData?.kpp) data.paymentData.kpp = formData.paymentData.kpp.toString();
        if (formData.paymentData?.ogrn) data.paymentData.ogrn = formData.paymentData.ogrn.toString();
        if (formData.paymentData?.bankAccountNumber) data.paymentData.bankAccountNumber = formData.paymentData.bankAccountNumber.toString();
        if (formData.paymentData?.bank) data.paymentData.bank = formData.paymentData.bank.toString();
        if (formData.paymentData?.bik) data.paymentData.bik = formData.paymentData.bik.toString();
        if (formData.paymentData?.ksNumber) data.paymentData.ksNumber = formData.paymentData.ksNumber.toString();

        data = {
            ...data,
            phone: formData.phone ? formData.phone.toString() : '',
            client: formData.client ? true : false,
        }

        dispatch({
            type: 'LOAD_ACCOUNT_INFO',
            payload: data,
        });
    }
}

export const updateAccountInfo = (data, change = true) => {
    if (change) {
        return {
            type: 'UPDATE_ACCOUNT_INFO',
            payload: data,
        }
    } else {
        return {
            type: 'EXTERNAL_UPDATE_ACCOUNT_INFO',
            payload: data,
        }
    }
}

export const saveAccountInfo = (data, verification = false) => async(dispatch) => {
    dispatch({
        type: 'START_SAVE_ACCOUNT_INFO',
        payload: {},
    });
    await Api.request('account/saveInfo', {
        form: data
    });
    // Временно закомментированы, чтобы сохранение профиля не сбрасывало верификацию
    //if (verification) await Api.request('account/requestVerification', {});
    dispatch({
        type: 'FINISH_SAVE_ACCOUNT_INFO',
        // payload: verification ? { verified: false } : {},
        payload: {},
    });
}