import React from 'react';
import { ScrollView, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import LogoutButton from '../components/LogoutButton';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import Alert from '../components/Alert';
import Api from '../components/Api';

class PaymentScreen extends React.Component {
    
    state = {
        user: false,
        agentSumm: false, 
        paymentMethod: false,
        paymentData: false,
        paymentFilled: false,
        loading: true
    }
    
    componentDidMount() {
        this.loadPaymentInfo();
    }

    _orderAgentSumm = () => {
        return new Promise((resolve, reject) => {
            Api.request('chat/orderAgentSumm').then(response => {
                if (response.success) {
                    resolve(response);
                } else {
                    reject();
                }
            });
        });
    }

    orderAgentSumm = () => {
        if (parseInt(this.state.agentSumm)) {
            this.alert.show({
                title: 'Подтвердите действие',
                content: 'Отправить запрос на получение вознаграждения?',
                actions: [{
                    title: 'Отправить',
                    action: async (dialog) => {
                        await this._orderAgentSumm();
                        dialog._hideDialog();
                        this.props.navigation.navigate('Chat');
                    }
                }, {
                    title: 'Отмена',
                    action: dialog => dialog._hideDialog()
                }]
            });
        } else {
            this.alert.show({
                title: 'Баланс 0 руб.',
                content: 'Вы не можете получить вознаграждение',
                actions: [{
                    title: 'Понятно',
                    action: dialog => dialog._hideDialog()
                }]
            });
        }
    }

    loadPaymentInfo() {
        this.setState({
            loading: true
        }, () => {
            Api.request('account/getInfo', {
            }).then(response => {
                this.setState({
                    loading: false,
                    user: response.user,
                    paymentMethod: response.user.paymentMethod,
                    paymentData: response.user.paymentData,
                    paymentFilled: this.checkPaymentData(response.user.paymentMethod, response.user.paymentData),
                    agentSumm: response.user.agentSumm ? response.user.agentSumm.toString() : '0',
                });
            });
        });
    }

    checkPaymentData(method, data) {
        if (!data) return false;
        if (method == 'card') {
            return (!!data.cardName &&
                    !!data.cardNumber &&
                    !!data.cardBank &&
                    !!data.cardBik &&
                    !!data.cardKorrAccount &&
                    !!data.cardInn &&
                    !!data.cardKpp);
        } else {
            return (!!data.companyName &&
                    !!data.inn &&
                    !!data.kpp &&
                    !!data.ogrn &&
                    !!data.bankAccountNumber &&
                    !!data.bank &&
                    !!data.ksNumber);
        }
    }
    
    render() {
        return (
            <ScrollView style={styles.container}>
                <Alert ref={(node) => { this.alert = node}} />
                { this.state.loading ?
                <View>
                    <Text>Загрузка информации...</Text>
                </View>
                : <View>
                    { this.state.user.client ?
                    <View>
                        <Text style={styles.regular}>Объем перевозок на текущий момент</Text>
                        <View style={{flexDirection: 'row', marginVertical: 24, alignItems: 'flex-end'}}>
                            <Text style={styles.price}>{(this.state.user.clientRating) ? this.state.user.clientRating: 0}</Text>
                            <Text style={[styles.currency, {marginLeft: 5}]}>м3</Text>
                        </View>
                    </View>: null }
                    <Text style={styles.regular}>Агентское вознаграждение</Text>
                    <View style={{flexDirection: 'row', marginVertical: 24, alignItems: 'flex-end'}}>
                        <Text style={styles.price}>{this.state.agentSumm}</Text>
                        <Text style={[styles.currency, {marginLeft: 5}]}>руб.</Text>
                    </View>
                    { this.state.paymentFilled ?
                    <Button
                        contentStyle={{ height: 48 }}
                        mode="contained"
                        color="#FFC107"
                        loading={false}
                        onPress={() => this.orderAgentSumm()}
                    >
                        Перевести
                    </Button>
                    :
                    <View>
                        <Text style={{ color: '#495057', fontSize: 16, lineHeight: 26, marginBottom: 24 }}>Добавьте реквизиты для перевода Вам агентского вознаграждение</Text>
                        <Button
                            contentStyle={{ height: 48 }}
                            mode="contained"
                            color="#FFC107"
                            loading={false}
                            onPress={() => this.props.navigation.navigate('AccountInfo')}
                        >
                            Добавить реквизиты
                        </Button>
                    </View> }
                    <Button
                        style={{marginTop: 24}}
                        contentStyle={{ height: 48 }}
                        mode="outlined"
                        color="#212529"
                        onPress={() => this.loadPaymentInfo()}
                    >
                        Обновить данные
                    </Button>
                    <LogoutButton style={{ marginTop: 28, marginBottom: 36 }} />
                </View> }
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingVertical: 30,
        backgroundColor: '#fff'
    },
    regular: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    price: {
        color: '#212529',
        fontSize: 28,
        lineHeight: 33
    },
    currency: {
        color: '#212529',
        fontSize: 18,
    },
})

export default PaymentScreen;