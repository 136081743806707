import { Platform } from 'react-native';

const config = {
	// apiUrl: 'http://192.168.88.177:8080',
	// socketsUrl: 'ws://192.168.88.177:8080',
	apiUrl: 'http://api.inertmarket.com:8080',
	socketsUrl: 'ws://api.inertmarket.com:8080'
};

export default config;

export const debugLevel = [
	// 'debug', 
	// 'auth',
	// 'request',
];