import React from 'react';
import { StyleSheet, Dimensions } from 'react-native';

const window = Dimensions.get("window");

const styles = StyleSheet.create({
    container: {
        maxHeight: window.height-80,
        maxWidth: 640, 
        marginHorizontal: 'auto' 
    },
    title: {
        fontWeight: 'bold',
        fontSize: 18,
        paddingVertical: 15,
        paddingHorizontal: 15,
    },
    content: {
        paddingHorizontal: 16,
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingVertical: 10,
        paddingHorizontal: 16, 
    },
});

export default styles;