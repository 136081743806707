const Spinner = function() {
    this.inited = false;
    
    this.init = (container) => {
        this.container = container;
        this.inited = true;
    };
    
    this.on = () => {
        if (!this.inited) return;
        this.container.setState({
            spinner: true
        });
    };
    
    this.off = () => {
        if (!this.inited) return;
        this.container.setState({
            spinner: false
        });
    };
};

const spinner = new Spinner();

export default spinner;