import React, { Component } from 'react';
import { StyleSheet, ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import Api from './Api';

class Form extends ValidationComponent {
    state = {
      loading: false,
    };

    componentDidMount() {
        let order = this.props.chat.order;

        this.setState({
            ...order,
        });
    }

    validRules = {
    };

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules)) {
            this.setState({ loading: true }, () => {
                Api.request('order/save', {
                    orderId: this.props.chat._id,
                    form: {
                        description: this.state.description,
                    },
                }).then(response => {
                    this.setState({ loading: false });
                    if (!response.success) {
                        Api.alert('Ошибка при сохранении');
                    }
                });
            });
        }
    };

  render() {
    return (
        <View style={{ flex: 1 }}>
            <ScrollView style={{ paddingLeft: 23, paddingRight: 15 }}>
                <View>
                    <TextInput
                        style={styles.textArea}
                        label='Описание лота'
                        multiline={true}
                        numberOfLines={10}
                        mode='outlined'
                        value={this.state.description}
                        onChangeText={value => this.setState({ description: value })}
                        error={this.isFieldInError('description')}
                    />
                </View>
            </ScrollView>
            <Button
                style={{ marginLeft: 23, marginRight: 30, marginBottom: 24 }}
                contentStyle={{ height: 48 }}
                mode="contained"
                color="#FFC107"
                loading={this.state.loading} onPress={() => this.onSubmit()}
            >
                Сохранить
            </Button>
        </View>
    );
  }
}

const styles = StyleSheet.create({
    textInput: {
        fontSize: 16,
        height: 48,
        backgroundColor: '#fff',
        marginBottom: 10
    },
    textArea: {
        fontSize: 16,
        backgroundColor: '#fff',
        marginBottom: 20
    }
});

export default Form;