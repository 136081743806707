import React from 'react';
import { Platform, ScrollView, View, Text, FlatList, StyleSheet, Dimensions } from 'react-native';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { Button, TextInput, IconButton } from 'react-native-paper';
import CreateGroup from '../components/CreateGroup';
import ContextMenu from '../components/ContextMenu';
import TitleBar from '../components/TitleBar';
import ClientInfo from '../components/ClientInfo';
import GroupInfo from '../components/GroupInfo';
import OrderForm from '../components/SidebarOrderForm';
import OrderEditForm from '../components/SidebarOrderEditForm';
import AuctionForm from '../components/SidebarAuctionForm';
import AuctionEditForm from '../components/SidebarAuctionEditForm';
import OrderList from '../components/OrderList';
import ChatList from '../components/ChatList';
import Chat from '../components/Chat';
import Api from '../components/Api';
import App from '../components/App';
import Notification from '../components/Notification';
import moment from 'moment';
import defaultStyles from '../styles/main.js';
import Logger from '../components/Logger';

import { connect } from 'react-redux';
import {
    loadAccountInfo,
} from '../redux/actions/account';
import {
    setActiveChat,
    removeOrderFromList,
} from '../redux/actions/index';

const window = Dimensions.get("window");

class MainTabs extends React.Component {

    componentDidMount() {
        Api.socket.on('chat/orderArchived', this._onOrderArchived);
    }

    componentWillUnmount() {
        Api.socket.off('chat/orderArchived', this._onOrderArchived);
    }

    _onOrderArchived = (data) => {
        Logger.log('request', 'Заказ перемещен в архив', data);
    }

    state = {
        index: 0,
        routes: [
            { key: 'orders', title: 'Заказы' },
            { key: 'chat', title: 'Чаты' }
        ],
    }

    _setIndex = (value) => {
        this.setState({ index: value });
    }

    selectTab = (name) => {
        let index = this.state.routes.findIndex(route => route.key === name);
        if (index) {
            this.setState({ index: index });
        }
    }

    _renderScene = ({ route }) => {
        switch (route.key) {
            case 'orders':
                return <OrdersTab selectOrder={order => this.props.selectOrder(order)} />;
            case 'chat':
                return <ChatTab selectChat={chat => this.props.selectChat(chat)} />;
            default:
                return null;
        }
    };

    _renderTabBar = props => (
        <TabBar
            {...props}
            activeColor="#212529"
            inactiveColor="#868E96"
            indicatorStyle={{ backgroundColor: '#FFC107' }}
            style={{ backgroundColor: '#fff' }}
        />
    );

    render() {
        return (
            <TabView
                navigationState={this.state}
                renderScene={this._renderScene}
                onIndexChange={this._setIndex}
                renderTabBar={this._renderTabBar}
            />
        );
    }
}

function OrdersTab({ selectOrder }) {
    return (
        <OrderList onSelect={(order) => {selectOrder(order)}} />
    );
}

function ChatTab({ selectChat }) {
    return (
        <ChatList onSelect={(chat) => selectChat(chat)} />
    );
}


class ManagerScreen extends React.Component {

    state = {
        activeChat: false,
        sidebar: false,
        sidebarTitle: '',
        notification: {
            title: ''
        },
    }

    componentDidMount() {
        Api.notify = title => this.notify(title);
        this._unsubscribeCreateGroup = this.props.navigation.addListener('onCreateGroup', () => {
            this.props.navigation.closeDrawer();
            this.createGroupDlg.show();
        });
        this.props.loadAccountInfo();
    }

    componentWillUnmount() {
        this._unsubscribeCreateGroup();
    }

    notify(title) {
        this.setState({notification: {
            title: ''
        }}, () => {
            this.setState({notification: {
                title: title
            }});
        });
    }
    
    selectChat(chat) {
        this.setState(state => {
            if (state.sidebar == 'order' || state.sidebar == 'group') {
                return {
                    sidebar: false,
                    sidebarTitle: ''
                }
            }
        });
    }

    selectOrder(orderChat) {
        this.setState({
            sidebar: false,
            sidebarTitle: ''
        });
    }

    _onChangeChatRequest = data => {
        let chat;
        if (data.chatId) {
            chat = this.props.chatList.find(item => item.chat._id === data.chatId);
        } else {
            chat = this.props.chatList.find(chat => chat._id === data.clientId);
        }
        if (chat) {
            this.props.setActiveChat(chat);
            this.selectChat(chat);
            this._mainTabs.selectTab('chat');
        }
    }

    submitOrder() {
        this.setState({ sidebar: false, sidebarTitle: '' });
    }

    showSidebar(action) {
        if (this.state.sidebar == action || action == 'close') {
            this.setState({ sidebar: false, sidebarTitle: '' });
        } else {
            let title = '';
            if (action == 'order') title = 'Создание заказа';
            if (action == 'editOrder') title = 'Редактирование заказа';
            if (action == 'auction') title = 'Разместить лот';
            if (action == 'editAuction') title = 'Редактирование лота';
            if (action == 'details') title = 'Информация';
            if (action == 'group') title = 'Информация о группе';
            this.setState({ sidebar: action, sidebarTitle: title });
        }
    }

    _showDrawer = () => {
        this.props.navigation.openDrawer();
    }

    render() {
        if (Platform.OS != 'web') {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: 32}}>
                    <Text style={{ fontSize: 20, textAlign: 'center', marginBottom: 24 }}>Мобильная версия для менеджеров не поддерживается. Перейдите на компьютере по ссылке http://app.inertmarket.com/</Text>
                    <Button
                        mode="contained"
                        color="#FFC107"
                        contentStyle={{height: 36}}
                        onPress={() => App().logout()}
                    >
                        Выход
                    </Button>
                </View>
            );
        } else {
            return (
                <View style={styles.container}>
                    <CreateGroup ref={refLink => this.createGroupDlg = refLink} />
                    <View style={styles.sidebarContainer}>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <IconButton
                                icon="menu"
                                color='#868E96'
                                size={20}
                                onPress={this._showDrawer}
                            />
                            <View style={{ paddingTop: 3, marginRight: 10 }}>
                                <Button
                                    mode="contained"
                                    color="#FFC107"
                                    contentStyle={{height: 36}}
                                    onPress={() => this.showSidebar('order')}
                                >
                                    Создать заказ
                                </Button>
                            </View>
                            <View style={{ paddingTop: 3 }}>
                                <Button
                                    mode="contained"
                                    color="#FFC107"
                                    contentStyle={{height: 36}}
                                    onPress={() => this.showSidebar('auction')}
                                >
                                    Разместить лот
                                </Button>
                            </View>
                        </View>
                        <View style={{flex: 1}}>
                            <MainTabs
                                ref={refLink => this._mainTabs = refLink}
                                selectChat={(chat) => this.selectChat(chat)}
                                selectOrder={(order) => this.selectOrder(order)}
                            />
                        </View>
                    </View>
                    <View style={styles.chatContainer}>
                        <TitleBar data={this.props.activeChat} onSidebarShow={() => {
                            if (this.props.activeChat.group) {
                                this.showSidebar('group')
                            } else {
                                this.showSidebar('details')
                            }
                        }} />
                        {this.props.activeChat ? <Chat accountType="manager"
                                                        chatId={this.props.activeChat.chat._id}
                                                        owner={this.props.activeChat.owner}
                                                        key={this.props.activeChat._id}
                                                        onOrderEdit={() => {
                                                            if (this.props.activeChat.order_type === 'auction') {
                                                                this.showSidebar('editAuction');
                                                            } else {
                                                                this.showSidebar('editOrder');
                                                            }
                                                        }}
                                                        onChangeChatRequest={event => this._onChangeChatRequest(event)}
                                                 /> : null}
                    </View>
                    {this.state.sidebar ? 
                    <View style={styles.rightSidebarContainer}>
                        <View style={styles.rightSidebarTitleContainer}>
                            <Text style={styles.rightSidebarTitle}>{this.state.sidebarTitle}</Text>
                            <IconButton
                                icon="close"
                                color='#212529'
                                size={20}
                                onPress={() => this.showSidebar('close')}
                            />
                        </View>
                        {(this.state.sidebar == 'order')?
                        <OrderForm onSubmit={() => this.submitOrder()} />
                        : null }
                        {(this.state.sidebar == 'editOrder')? 
                        <OrderEditForm key={this.props.activeChat._id} chat={this.props.activeChat} onSubmit={() => {}} />
                        : null }
                        {(this.state.sidebar == 'auction')?
                        <AuctionForm onSubmit={() => this.submitOrder()} />
                        : null }
                        {(this.state.sidebar == 'editAuction')? 
                        <AuctionEditForm key={this.props.activeChat._id} chat={this.props.activeChat} onSubmit={() => {}} />
                        : null }
                        {(this.state.sidebar == 'details')? 
                        <ClientInfo style={{ flex: 1, paddingLeft: 23 }} managerStyle={true} key={this.props.activeChat._id} chat={this.props.activeChat} />
                        : null }
                        {(this.state.sidebar == 'group')? 
                        <GroupInfo style={{ flex: 1, paddingLeft: 23, paddingRight: 15 }} key={this.props.activeChat._id} group={this.props.activeChat} />
                        : null }
                    </View> : null}
                </View>
            );
        }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        maxHeight: window.height,
        minWidth: 960
    },
    sidebarContainer: {
        flex: 1,
        backgroundColor: '#fff',
        borderRightColor: '#CED4DA',
        borderRightWidth: 1
    },
    chatContainer: {
        flex: 2,
        backgroundColor: '#F1F3F5'
    },
    searchInput: {
        flex: 1,
        marginRight: 10,
        backgroundColor: '#fff',
        fontSize: 16,
        height: 32,
    },
    rightSidebarContainer: {
        flex: 1,
        backgroundColor: '#fff',
        width: 290,
        borderLeftColor: '#CED4DA',
        borderLeftWidth: 1
    },
    rightSidebarTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 23,
        paddingRight: 15,
    },
    rightSidebarTitle: {
        flex: 1,
        fontSize: 18,
        lineHeight: 21,
        color: '#212529'
    },
    marginTop: {
        marginTop: 10
    }
})

const mapStateToProps = state => {
    return {
        chatList: state.chatList.list,
        orderList: state.chatList.orderList,
        activeChat: state.chatList.activeChat,
    }
}

export default connect(mapStateToProps, {
    loadAccountInfo,
    setActiveChat,
    removeOrderFromList,
})(ManagerScreen);