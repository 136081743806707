import React from 'react';
import { ScrollView, TouchableOpacity, View, Text, Dimensions } from 'react-native';
import { TextInput, Button, Dialog, Portal } from 'react-native-paper';
import { Entypo } from '@expo/vector-icons'; 
import MyButton from './MyButton';
import ValidationComponent from 'react-native-form-validator';

const window = Dimensions.get("window");

class NoteModal extends ValidationComponent {
    state = {
        visible: false,
        loading: false,
        note: '',
    };

    componentDidMount = () => {
    }

    show = (note = '') => {
        this.setState({
            visible: true,
            loading: false,
            note: note,
        });
    }

    _hide = () => {
        this.setState({
            visible: false,
            loading: false,
            note: '',
        });
    }

    validRules = {
        note: {required: true},
    }

    onSubmit = () => {
        if (this.state.loading) return;
        if (this.validate(this.validRules)) {
            this.setState({ loading: true }, () => {
                this.props.onSubmit(this.state.note);
            });
        }
    }

    render() {
        return (
            <Portal>
                <Dialog
                    style={{
                        maxHeight: window.height-80,
                        maxWidth: 640, 
                        marginHorizontal: 'auto' 
                    }}
                    visible={this.state.visible}
                    onDismiss={this._hide}
                >
                    <Dialog.Title>Заметка</Dialog.Title>
                    <Dialog.Content style={{ flex: 1, minWidth: 396}}>
                        <ScrollView style={{ flex: 1}}>
                            <TextInput
                                value={this.state.note}
                                onChangeText={value => this.setState({ note: value })}
                                error={this.isFieldInError('note')}
                                label='Текст заметки'
                                mode='outlined'
                                multiline={true}
                                numberOfLines={10}
                            />
                        </ScrollView>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={this.onSubmit} loading={this.state.loading}>Сохранить</Button>
                        <Button onPress={this._hide}>Отмена</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        );
    }
}

NoteModal.defaultProps = {
    onSubmit: () => {},
}

class NoteList extends React.Component {

    _showNoteModal = () => {
        this._noteModal.show();
    }

    _onRemoveNote = (index) => {
        this.props.onRemoveNote(index);
    }

    _onSubmit = (note) => {
        this.props.onAddNote(note, this._noteModal._hide);
    }

    render() {
        return (
            <View>
                <NoteModal
                    ref={(refLink) => this._noteModal = refLink}
                    onSubmit={this._onSubmit}
                />
                <MyButton
                    style={{ marginBottom: 24 }}
                    color="#212121"
                    text="Добавить заметку"
                    onPress={this._showNoteModal}
                />
                {(this.props.notes && this.props.notes.length) ?
                    <View>
                        {this.props.notes.map((note, index) => (
                            <View
                                key={index}
                                style={{
                                    flexDirection: 'row',
                                    borderWidth: 1,
                                    borderColor: '#CED4DA',
                                    borderRadius: 6,
                                    paddingVertical: 6,
                                    paddingHorizontal: 10,
                                    marginBottom: 24,
                                }}
                            >
                                <Text style={{
                                    flex: 1,
                                    color: '#212529',
                                    fontSize: 16,
                                    lineHeight: 26
                                }}>{note}</Text>
                                <TouchableOpacity
                                    onPress={() => this._onRemoveNote(index)}
                                >
                                    <Entypo name="cross" size={18} color="#666" />
                                </TouchableOpacity>
                            </View>
                        ))}
                    </View>
                    :
                    <View style={{
                        borderWidth: 1,
                        borderColor: '#CED4DA',
                        borderRadius: 6,
                        paddingVertical: 6,
                        paddingHorizontal: 10,
                        marginBottom: 24,
                    }}>
                        <Text style={{
                            color: '#212529',
                            fontSize: 16,
                            lineHeight: 26
                        }}>Заметок нет</Text>

                    </View>
                }
            </View>
        );
    }
}

NoteList.defaultProps = {
    onAddNote: () => {},
    onRemoveNote: () => {},
    notes: [],
}

export default NoteList;