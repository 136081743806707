import React from 'react';
import { ScrollView, View, Text, StyleSheet } from 'react-native';
import {KeyboardAwareScrollView} from '../components/KBscrollview';
import ScrollViewKB from '../components/ScrollViewKB';
import { Button, Switch, TextInput, IconButton } from 'react-native-paper';
import Icon from 'react-native-vector-icons/FontAwesome';
import TextField from '../components/TextField';
import { TextInputMask } from 'react-native-masked-text';
import ValidationComponent from 'react-native-form-validator';
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import Rating from '../components/Rating';
import App from '../components/App';
import Api from '../components/Api';
import Alert from '../components/Alert';
import MyButton from '../components/MyButton';
import { alertMsg } from '../components/Api';
import TruckEditForm from '../components/TruckEditForm';

class EmployeeScreen extends ValidationComponent {
    state = {
        isEmployee: true,
        employeeRating: 0,
        companyName: '',
        inn: '',
        phone: '',
        email: '',
        trucks: [],
        loading: false,
        KBheight: 0,
    }
    
    _onToggleEmployeeSwitch = () => {
        this.props.onChange({ employee: !this.props.formData.employee });
    }

    _onRatingChange = () => {
        this.Alert.show({
            title: 'Рейтинг',
            content: 'Для изменения рейтинга обратитесь к менеджеру через чат',
        });
    }

    _onCompanyDataChange = data => {
        this.props.onChange({
            companyData: {
                ...this.props.formData.companyData,
                ...data,
            }
        });
    }

    _addTruck = () => {
        let trucks = this.props.formData.trucks.slice();
        trucks.push({ name: '', city: '', count: '1', amount: '10' });
        this.props.onChange({ trucks: trucks });
    }

    _removeTruck = (index) => {
        let trucks = this.props.formData.trucks.slice();
        if (trucks.length <= 1) return;
        trucks.splice(index, 1);
        this.props.onChange({ trucks: trucks });
    }
    
    _editTruckName = (name, index) => {
        let trucks = this.props.formData.trucks.slice();
        trucks[index].name = name;
        this.props.onChange({ trucks: trucks });
    }
    
    _editTruckCity = (city, index) => {
        let trucks = this.props.formData.trucks.slice();
        trucks[index].city = city;
        this.props.onChange({ trucks: trucks });
    }

    _editTruckDescription = (description, index) => {
        let trucks = this.props.formData.trucks.slice();
        trucks[index].description = description;
        this.props.onChange({ trucks: trucks });
    }

    componentDidMount() {
        // Api.request('account/getInfo', {
        // }).then(response => {
        //     let state = {
        //         loading: false,
        //         phone: (response.user.phone) ? response.user.phone.toString() : '',
        //         email: response.user.email,
        //         isEmployee: response.user.employee ? true : false,
        //         employeeRating: response.user.employeeRating,
        //         trucks: (response.user.trucks) ? response.user.trucks : [],
        //     };
            
        //     if (!response.user.companyData) response.user.companyData = {};
        //     if (!response.user.companyData.companyName) response.user.companyData.companyName = '';
        //     if (!response.user.companyData.inn) response.user.companyData.inn = '';
        //     state.companyName = response.user.companyData.companyName;
        //     state.inn = response.user.companyData.inn.toString();
            
        //     this.setState(state);
        // });
    }

    validRules = {
        companyName: {required: true},
        inn: {required: true},
        phone: {required: true},
    }

    _onVerificationRequest = () => {
        this.Alert.show({
            title: 'Верификация',
            content: 'Отправить менеджеру запрос на верификацию данных?',
            actions: [{
                title: 'Отправить',
                action: async (dialog) => {
                    await Api.request('account/requestVerification', {});
                    dialog._hideDialog();
                    this.props.navigation.navigate('Chat');
                }
            }, {
                title: 'Отмена',
                action: dialog => dialog._hideDialog()
            }]
        });
    }

    onSubmit = () => {
        if (this.state.loading) return;
        
        if (!this.state.isEmployee) {
            this.setState({ loading: true });
            Api.request('account/saveInfo', {
                form: {
                    employee: false
                }
            }).then(response => {
                this.setState({ loading: false });
                alertMsg('Сохранено', 'Изменения внесены');
                App().changeClientType({ employee: false });
            });
        } else {
            if (this.validate(this.validRules)) {
                this.setState({ loading: true }, () => {
                    let form = {
                        employee: true,
                        phone: this.state.phone.replace(/\D+/g,''),
                        email: this.state.email,
                        companyData: {
                            companyName: this.state.companyName,
                            inn: this.state.inn,
                        },
                        trucks: this.state.trucks
                    }
                    this.setState({ loading: true });
                    Api.request('account/saveInfo', {
                        form: form
                    }).then(response => {
                        this.setState({ loading: false });
                        alertMsg('Сохранено', 'Изменения внесены');
                        App().changeClientType({ employee: true });
                    });
                });
            }
        }
    };

    render() {
        let formData = this.props.formData;
        return (
            <View style={{
                flex: 1,
                backgroundColor: '#fff'
            }}>
                <ScrollViewKB
                    style={{
                        flex: 1,
                        paddingHorizontal: 16,
                        paddingTop: 24,
                    }}
                    bottomContentInset={this.props.bottomContentInset}
                    onKBShow={this.props.onKBShow}
                    onKBHide={this.props.onKBHide}
                >
                    <Alert ref={refLink => this.Alert = refLink} />
                    <View style={styles.switchContainer}>
                        <Switch
                            color="#FFC107"
                            value={formData.employee}
                            onValueChange={this._onToggleEmployeeSwitch}
                        />
                        <Text style={styles.switchLabel}>Я перевозчик</Text>
                        <Icon style={{ marginLeft: 10 }} name="check-circle" size={38} color={formData.verified ? '#40C057' : '#CED4DA'} />
                    </View>
                    { formData.employee ?
                        <View>
                            <Text style={[styles.text, styles.marginTop ]}>Мой статус</Text>
                            <Rating style={{ marginTop: 16 }} value={formData.employeeRating} title="заказов" procent={false} onChange={() => this._onRatingChange()} />
                            <Text style={{ marginTop: 44, marginBottom: 24, fontSize: 18, lineHeight: 28 }}>Техника</Text>
                            {formData.trucks.map((truck, index) => (
                            <View key={index} style={{ marginBottom: 24 }}>
                                <TruckEditForm
                                    style={{ marginHorizontal: 0, borderWidth: 1 }}
                                    truck={truck}
                                    canRemove={index>0}
                                    onChangeName={(value) => this._editTruckName(value, index)}
                                    onChangeDescription={(value) => this._editTruckDescription(value, index)}
                                    onChangeCity={(value) => this._editTruckCity(value, index)}
                                    onRemove={() => this._removeTruck(index)}
                                />
                            </View>
                            ))}
                            <MyButton
                                onPress={this._addTruck}
                                color="#212121"
                                text="Добавить еще технику"
                            />
                            <View style={styles.divider}></View>
                            <Text style={styles.text}>Персональные данные</Text>
                            <TextField
                                style={[styles.textInput, styles.marginTop]}
                                value={formData.companyData.companyName}
                                onChangeText={value => this._onCompanyDataChange({ companyName: value })}
                                error={this.isFieldInError('companyName')}
                                label='Юридическое лицо (ООО/ИП)'
                            />
                            <TextField
                                style={[styles.textInput, styles.marginTop]}
                                value={formData.companyData.inn}
                                onChangeText={value => this._onCompanyDataChange({ inn: value })}
                                error={this.isFieldInError('inn')}
                                label='ИНН'
                            />
                            <TextInput
                                mode="outlined"
                                style={[styles.textInput, styles.marginTop]}
                                label='Телефон'
                                value={formData.phone}
                                onChangeText={value => this.props.onChange({ phone: value })}
                                error={this.isFieldInError('phone')}
                                autoCorrect={false}
                                keyboardType="phone-pad"
                                returnKeyType="next"
                                render={props =>
                                    <TextInputMask
                                        {...props}
                                        type={'custom'}
                                        options={{
                                            mask: '+7 (999) 999-99-99'
                                        }}
                                    />
                                }
                            />
                            <TextField
                                style={[styles.textInput, styles.marginTop]}
                                value={formData.email}
                                onChangeText={value => this.props.onChange({ email: value })}
                                label='Электронная почта'
                            />
                            {this.props.showVerificationButton && !formData.verified ?
                                <View
                                    style={{
                                        paddingTop: 36,
                                        marginBottom: 14,
                                    }}
                                > 
                                    <Button
                                        contentStyle={{height: 48}}
                                        mode="contained"
                                        color="#FFC107"
                                        loading={false}
                                        onPress={this._onVerificationRequest}
                                    >
                                        Запросить верификацию
                                    </Button>
                                </View>
                                :
                                null
                            }
                        </View>
                        :
                        null
                    }
                </ScrollViewKB>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    marginTop: {
        marginTop: 23
    },
    marginBottom: {
        marginBottom: 50
    },
    textInput: {
        height: 48
    },
    text: {
        color: '#212529',
        fontSize: 18,
        lineHeight: 28
    },
    divider: {
        height: 1,
        backgroundColor: '#CED4DA',
        marginVertical: 36
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    switchLabel: {
        color: '#212529',
        fontSize: 16,
        lineHeight: 19,
        marginLeft: 16
    },
    switch: {
        color: '#FFC107'
    }
})

EmployeeScreen.defaultProps = {
    onChange: () => {},
    onKBShow: () => {},
    onKBHide: () => {},
    bottomContentInset: 0,
};

export default EmployeeScreen;