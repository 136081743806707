import { debugLevel } from '../config.js';

class Logger {
	level = {
		'request': 3,
		'debug': 10
	}

	log(level, ...message) {
		if (typeof debugLevel === 'number') {
			if (typeof level !== 'number') {
				level = this.level[level];
				if (level === undefined) level = 10;
			}
			if (level <= debugLevel) console.log(message);
		}
		if (Array.isArray(debugLevel)) {
			if (typeof level === 'string') {
				if (debugLevel.indexOf(level) > -1) console.log(message);
			}
		}
	}
}

export default new Logger();