import React from 'react';
import { ScrollView, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Button, IconButton, TextInput } from 'react-native-paper';
import Checkbox from '../components/Checkbox.js';
import NumInput from '../components/NumInput.js';
import Api from '../components/Api';

class ListItems extends React.Component {
    state = {
        opened: false,
        itemsChecked: [],
        itemsValue: []
    }

    componentDidMount() {
        let checked = [];
        let itemsValue = [];
        this.props.items.map(() => checked.push(false));
        this.props.items.map(() => itemsValue.push('10'));
        this.setState({ itemsChecked: checked, itemsValue: itemsValue });
    }

    onCheck(i, state) {
        let checked = this.state.itemsChecked;
        checked[i] = state;
        this.setState({
            itemsChecked: checked.slice()
        });
    }

    updateValue(value, i) {
        let values = this.state.itemsValue;
        values[i] = value;
        this.setState({
            itemsValue: values.slice()
        });
    }

    getValue() {
        let value = [];
        this.props.items.map((item, i) => {
            if (this.state.itemsChecked[i]) {
                value.push(item.name + ' - ' + this.state.itemsValue[i]);
            }
        });
        if (value.length) {
            return this.props.title + ': ' + value.join(', ');
        }
        return '';
    }
    
    render() {
        return (
            <View style={{ marginTop: 15, borderBottomWidth: 1, borderBottomColor: '#CED4DA'}}>
                <TouchableOpacity style={styles.listItem} onPress={() => this.setState(state => ({opened: !state.opened}))}>
                    <Text style={styles.listItemTitle}>{this.props.title}</Text>
                    <IconButton
                        icon={this.state.opened ? 'chevron-up' : 'chevron-down'}
                        color="#495057"
                        size={20}
                    />
                </TouchableOpacity>
                {this.state.opened ?
                <View>
                    {this.props.items.map((item, i) => (
                    <View key={i} >
                        <Checkbox title={item.name} checked={item.checked} onCheck={state => this.onCheck(i, state)} />
                        {this.state.itemsChecked[i] ? <View style={{ marginLeft: 43, marginBottom: (i == this.props.items.length-1) ? 15 : 0 }}><NumInput suffix={true} step={10} min={10} onChangeText={(value) => this.updateValue(value, i)} /></View> : null }
                    </View>
                    ))}
                </View>
                : null}
            </View>
        );
    }
}

class MaterialsScreen extends React.Component {
    state = {
        inertItems: [],
        loading: false
    }

    componentDidMount() {
        Api.request('getInert').then(response => {
            if (response.success) {
                this.setState({
                    inertItems: response.items
                })
            }
        });
    }

    getValue(props, state) {
        let value = [];
        props.items.map((item, i) => {
            if (state.itemsChecked[i]) {
                value.push(item.name + ' - ' + state.itemsValue[i]);
            }
        });
        if (value.length) {
            return props.title + ': ' + value.join(', ');
        }
        return '';
    }

    submit() {
        console.log('refs', this.itemRefs);

        let message = 'Зравствуйте. Хочу оформить заказ.';
        let order = [];
        this.itemRefs.map(ref => {
            if (ref) {
                let value = this.getValue(ref.props, ref.state);
                if (value) order.push(value);
            }
        });
        if (order.length) {
            message = message + ' ' + order.join('. ');
            this.setState({ loading: true }, () => {
                Api.request('chat/addOrder', {
                    type: 'material',
                    message: message
                }).then(response => {
                    this.setState({ loading: false });
                    this.props.navigation.navigate('Chat');
                });
            });
        }
    }

    itemRefs = [];

    render() {
        this.itemRefs = [];
        return (
            <View style={{ flex: 1, backgroundColor: '#fff' }}>
                <ScrollView style={{ paddingTop: 30, paddingBottom: 48, paddingHorizontal: 16 }}>
                    <Text style={styles.h1}>Что доставить</Text>
                    <Button
                        style={{ marginBottom: 24 }}
                        mode="outlined"
                        color="#212121"
                        loading={false}
                        onPress={() => this.props.navigation.navigate('Chat')}
                    >
                        Написать менеджеру в чат
                    </Button>
                    {this.state.inertItems.map((item, i) => (
                        <ListItems ref={ref => this.itemRefs.push(ref)} key={i} title={item.name} items={item.items} />
                    ))}
                    <View style={{ height: 40 }}></View>
                </ScrollView>
                <Button style={{ marginTop: 10, marginBottom: 26, marginHorizontal: 16, height: 48 }} labelStyle={{ lineHeight: 32 }} mode="contained" color="#FFC107" onPress={() => { this.submit() }} loading={this.state.loading}>
                    Заказать
                </Button>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingTop: 30,
        paddingBottom: 40,
    },
    container2: {
        flex: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingTop: 30,
        paddingBottom: 40,
    },
    h1: {
        fontSize: 21,
        lineHeight: 25,
        marginBottom: 40
    },
    listItem: {
        flexDirection: 'row'
    },
    listItemTitle: {
        flex: 1,
        fontSize: 21,
        lineHeight: 25
    }
})

export default MaterialsScreen;