import React from 'react';
import { Platform, View, StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialIcons } from '@expo/vector-icons';

import ServiceScreen from '../screens/ServiceScreen';
import OrdersScreen from '../screens/OrdersScreen';
import ChatScreen from '../screens/ChatScreen';
import AccountScreen from '../screens/AccountScreen';

import Api from '../components/Api';
import App from '../components/App';

import Logger from '../components/Logger';

import { connect } from 'react-redux';
import { loadAccountInfo, updateAccountInfo } from '../redux/actions/account';

const Tab = createBottomTabNavigator();

class AppTabs extends React.Component {

    componentDidMount() {
        this._loadAccountInfo();
    }

    componentWillUnmount() {
        Api.socket.off('accountInfo/update', this._onAccountInfoUpdate);
    }

    _loadAccountInfo = async () => {
        await this.props.loadAccountInfo();
        Api.socket.on('accountInfo/update', this._onAccountInfoUpdate);
    }

    _onAccountInfoUpdate = (data) => {
        Logger.log('debug', 'Обновилась информация о профиле пользователя', data);
        if (data.block) {
            App().logout();
        }
        this.props.updateAccountInfo(data, false);
    }

    render() {
        return (
            <View style={ (Platform.OS == 'web') ? { flex: 1, width: 640, marginHorizontal: 'auto' } : { flex: 1} }>
                <NavigationContainer>
                    <Tab.Navigator>
                        { this.props.client ? 
                        <Tab.Screen
                            name = "Service"
                            component = {ServiceScreen}
                            options = {{
                                tabBarLabel: 'Услуги',
                                tabBarIcon: ({ color, size}) => (
                                    <MaterialIcons name="pin-drop" color={color} size={size} />
                                )
                            }}
                        /> : null }
                        { this.props.employee ? 
                        <Tab.Screen
                            name = "Orders"
                            component = {OrdersScreen}
                            options = {{
                                tabBarLabel: 'Заказы',
                                tabBarIcon: ({ color, size}) => (
                                    <MaterialIcons name="dns" color={color} size={size} />
                                )
                            }}
                        /> : null }
                        <Tab.Screen
                            name = "Chat"
                            component = {ChatScreen}
                            options = {{
                                tabBarLabel: 'Чат',
                                tabBarIcon: ({ color, size}) => (
                                    <MaterialIcons name="chat" color={color} size={size} />
                                )
                            }}
                        />
                        <Tab.Screen
                            name = "Account"
                            component = {AccountScreen}
                            options = {{
                                tabBarLabel: (this.props.employee) ? 'Профиль' : 'Кошелек',
                                tabBarIcon: ({ color, size}) => (
                                    <MaterialIcons name={(this.props.employee) ? 'person' : 'account-balance-wallet'} color={color} size={size} />
                                )
                            }}
                        />
                    </Tab.Navigator>
                </NavigationContainer>
            </View>
        );
    }
}

const styles = StyleSheet.create({

});

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}

export default connect(mapStateToProps, {
    loadAccountInfo,
    updateAccountInfo,
})(AppTabs);