import React from 'react';
import { ScrollView, View, Text, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import ListItem from './ListItem';
import Api from './Api';
import moment from 'moment';
import sort from 'fast-sort';
import Logger from './Logger';

import { connect } from 'react-redux';
import { loadOrderList, addOrderToList, updateOrderInfo, setActiveChat } from '../redux/actions';

function timestampFormat(timestamp) {
    if (!timestamp) return '';
    let date = moment.unix(timestamp);
    if (moment().diff(date, 'days') < 0) {
        return date.format();
    }
    return date.format("DD.MM h:mm");
}

function unreadCount(chat, userId) {
    if (!chat) return 0;
    let readCount = 0;
    if (chat.readCount) {
        readCount = chat.readCount.find(item => item._id == userId);
        if (!readCount) readCount = 0;
        else readCount = readCount.count;
    }
    return chat.count - readCount;
}

class OrderList extends React.Component {
    state = {
        search: ''
    }

    componentDidMount() {
        if (!this.props.notLoad) {
            this.props.loadOrderList();
            
            Api.socket.on('chat/updateInfo', (data) => {                
                Logger.log('debug', 'Получено обновление информации о чате', data);
                if (data && data.chat && data.chat.chat) {
                    if (!Array.isArray(data)) data = [data];
                    this.props.updateOrderInfo(data);
                    this.props.onUpdate(data);
                }
                
                // TODO: если прилетел новый чат...
            });
            
            Api.socket.on('order/newOrder', (data) => {
                Logger.log('debug', 'Принят новый заказ', data);
                this.props.addOrderToList(data);
                this.props.onAdd(data);
            });
        }
    }

    selectOrder(chat) {
        this.props.setActiveChat(chat);
        if (this.props.onSelect) this.props.onSelect(chat);
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <TextInput
                    label='Найти...'
                    mode='outlined'
                    value={this.state.search}
                    onChangeText={(value) => this.setState({ search: value })}
                    autoCorrect={false}
                    returnKeyType="search"
                    style={{
                        backgroundColor: '#fff',
                        fontSize: 16,
                        height: 32,
                        marginVertical: 10,
                        marginHorizontal: 10
                    }}
                    theme={{ colors: { placeholder: '#868E96'}}}
                />
                <FlatList
                    data={sort(this.props.orderList.filter(item => {
                        if (this.props.archive) {
                            if (!item.order_archived) return false;
                        } else {
                            if (item.order_archived) return false;
                        }
                        if (this.state.search) {
                            let found = false;
                            if (item.name && item.mname.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) found = true;
                            let customerName = '';
                            if (item.customer && item.customer.name) {
                                customerName = item.customer.name.toLowerCase();
                            }
                            if (item.customer && item.customer.nickname) {
                                customerName = customerName + ' ' + item.customer.nickname.toLowerCase();
                            }
                            if (customerName && item.customer.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) found = true;
                            return found;
                        }
                        return true;
                    })).desc([
                        u => unreadCount(u.chat, Api.userId),
                        u => u.chat.lastTimestamp
                    ])}
                    extraData={[this.props.selected, this.props.orderList]}
                    renderItem={({ item }) => <ListItem
                                            title={item.name}
                                            content={item.customer.nickname ? item.customer.nickname : item.customer.name}
                                            time={timestampFormat(item.chat.lastTimestamp)}
                                            counter={unreadCount(item.chat, Api.userId)}
                                            active={this.props.selected._id==item._id}
                                            onPress={() => this.selectOrder(item)}
                                        />}
                    keyExtractor={item => item._id}
                />
            </View>
        );
    }
}

OrderList.defaultProps = {
    onUpdate: () => {},
    onAdd: () => {},
    archive: false,
    notLoad: false,
}

const mapStateToProps = state => {
    return {
        orderList: state.chatList.orderList,
        selected: state.chatList.activeChat
    }
}

export default connect(mapStateToProps, { loadOrderList, addOrderToList, updateOrderInfo, setActiveChat })(OrderList);