import React from 'react';
import { Linking } from 'expo';
import { StyleSheet, Platform, View, Text, Image } from 'react-native';
import { GiftedChat, Send, Time, SystemMessage, MessageImage, InputToolbar } from 'react-native-gifted-chat';
import ChatActions from './ChatActions';
import ChatBubble from './ChatBubble';
import WebChatActions from './WebChatActions';
import { Feather, Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import * as ImageManipulator from "expo-image-manipulator";
import { Button } from 'react-native-paper';
import config from '../config.js';
import Api from './Api';
import Alert from './Alert';
import Logger from './Logger';

function signOrder(chat, currentMessage, updateMessage) {
    currentMessage.loading = true;
    updateMessage('order', currentMessage);
    Api.request('order/sign', { orderId: chat._id }).then(response => {
        currentMessage.loading = false;
        updateMessage('order', currentMessage);
    });
};

function unsignOrder(chat, currentMessage, updateMessage) {
    currentMessage.loading = true;
    updateMessage('order', currentMessage);
    Api.request('order/unsign', { orderId: chat._id }).then(response => {
        currentMessage.loading = false;
        updateMessage('order', currentMessage);
    });
};

function publishOrder(chat, currentMessage, updateMessage) {
    currentMessage.loading = true;
    updateMessage('order', currentMessage);
    Api.request('order/publish', { orderId: chat._id }).then(response => {
        currentMessage.loading = false;
        updateMessage('order', currentMessage);
    });
};

function unpublishOrder(chat, currentMessage, updateMessage) {
    currentMessage.loading = true;
    updateMessage('order', currentMessage);
    Api.request('order/unpublish', { orderId: chat._id }).then(response => {
        currentMessage.loading = false;
        updateMessage('order', currentMessage);
    });
};

function requestArchiveOrder(chat, currentMessage, updateMessage) {
    currentMessage.loading = true;
    updateMessage('order', currentMessage);
    Api.request('order/requestArchive', { orderId: chat._id }).then(response => {
        currentMessage.loading = false;
        updateMessage('order', currentMessage);
    });
};

function archiveOrder(chat, currentMessage, updateMessage) {
    currentMessage.loading = true;
    updateMessage('order', currentMessage);
    Api.request('order/archive', { orderId: chat._id }).then(response => {
        currentMessage.loading = false;
        updateMessage('order', currentMessage);
    });
};

function assignManager(chat, currentMessage, updateMessage, alertDlg) {
    alertDlg.show({
        title: 'Взять в работу?',
        content: 'Подтвердите действие',
        actions: [{
            title: 'Взять',
            action: (dlg) => {
                currentMessage.loading = true;
                updateMessage(currentMessage._id, currentMessage);
                Api.request('siteOrder/assignManager', {
                    message: currentMessage
                }).then(response => {
                    currentMessage.loading = false;
                    dlg._hideDialog();
                });
            }
        }, {
            title: 'Отмена',
            action: (dlg) => { dlg._hideDialog() }
        }]
    });
};

function renderCustomView( { position, chat, accountType, currentMessage, updateMessage, onOrderEdit = () => {} } ) {
    let alertDlg;

    if (currentMessage.order) {
        return (
            <View style={{ paddingHorizontal: 20, alignItems: 'flex-end', flexDirection: 'row' }}>
            {(chat.order.state == 'На подписании' || chat.order.state == 'Не опубликован') && accountType == 'manager' ?
                <Button style={{ marginRight: 29 }} mode="contained" color="#FFC107" onPress={onOrderEdit}>
                    Редактировать
                </Button>
            : null }
            {chat.order.state == 'На подписании' && accountType == 'manager' ?
                <Button mode="contained" color="#FFC107" disabled={true} loading={false} onPress={() => {}}>
                    На подписании
                </Button>
            : null }
            {chat.order.state == 'Опубликован' && accountType == 'manager' ?
                <Button mode="contained" color="#FFC107" loading={currentMessage.loading} onPress={() => unpublishOrder(chat, currentMessage, updateMessage)}>
                    Остановить публикацию
                </Button>
            : null }
            {chat.order.state == 'Не опубликован' && accountType == 'manager' ?
                <Button mode="contained" color="#FFC107" loading={currentMessage.loading} onPress={() => publishOrder(chat, currentMessage, updateMessage)}>
                    Опубликовать повторно
                </Button>
            : null }
            {chat.order.state == 'На подписании' && accountType == 'director' ?
                <Button mode="contained" color="#FFC107" loading={currentMessage.loading} onPress={() => signOrder(chat, currentMessage, updateMessage)}>
                    Подписать
                </Button>
            : null }
            {chat.order.state == 'На подписании' && accountType == 'director' ?
                <Button style={{ marginLeft: 10 }} mode="contained" color="#FFC107" loading={currentMessage.loading} onPress={() => archiveOrder(chat, currentMessage, updateMessage)}>
                    Отклонить
                </Button>
            : null }
            {chat.order.state == 'Опубликован' && accountType == 'director' ?
                <Button mode="contained" color="#FFC107" disabled={true} loading={false} onPress={() => {}}>
                    Подписано
                </Button>
            : null }
            {chat.order.state == 'Опубликован' && accountType == 'director' ?
                <Button style={{marginLeft: 10}} mode="contained" color="#FFC107" loading={currentMessage.loading} onPress={() => unpublishOrder(chat, currentMessage, updateMessage)}>
                    Остановить
                </Button>
            : null }
            {chat.order.state == 'Не опубликован' && accountType == 'director' ?
                <Button mode="contained" color="#FFC107" disabled={true} loading={false} onPress={() => {}}>
                    Остановлен
                </Button>
            : null }
            {chat.order.state == 'Не опубликован' && accountType == 'manager' ?
                <Button style={{ marginLeft: 29 }} loading={currentMessage.loading} mode="contained" color="#FFC107" onPress={() => requestArchiveOrder(chat, currentMessage, updateMessage)}>
                    В архив
                </Button>
            : null }
            {chat.order.state == 'Не опубликован' && accountType == 'director' ?
                <Button style={{marginLeft: 10}} mode="contained" color="#FFC107" loading={false} onPress={() => archiveOrder(chat, currentMessage, updateMessage)}>
                    В архив
                </Button>
            : null }
            {chat.order.state == 'Архив' ?
                <Text>Заказ находится в архиве</Text>
            : null }
            </View>
        );
    } else if (currentMessage.type === 'siteOrder') {
        return (
            <View style={{ paddingHorizontal: 20, alignItems: 'flex-end', flexDirection: 'row' }}>
                <Alert ref={(refLink) => alertDlg = refLink} />
                <Button
                    style={{ marginLeft: 29 }}
                    mode="contained"
                    color="#FFC107"
                    disabled={currentMessage.assigned}
                    onPress={() => assignManager(chat, currentMessage, updateMessage, alertDlg)}>
                    {(currentMessage.assigned) ? 'Взято в работу: '+currentMessage.data.managerName : 'Беру в работу'}
                </Button>
            </View>
        );
    } else if (currentMessage.file) {
        return (
            <View style={{
                alignItems: 'center'
            }}>
                <View style={{
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    borderRadius: 4,
                    borderWidth: 1,
                }}>
                    { (Platform.OS === 'web') ?
                    <MaterialCommunityIcons
                        accessibilityRole='link'
                        href={currentMessage.file}
                        target="_blank"
                        name="file"
                        size={32}
                        color={(position === 'right') ? '#fff' : '$000'}
                    />
                    :
                    <MaterialCommunityIcons
                        onPress={() => {
                            Linking.openURL(currentMessage.file);
                        }}
                        name="file"
                        size={32}
                        color={(position === 'right') ? '#fff' : '$000'}
                    />
                    }
                </View>
            </View>
        );
    } else {
        return;
    }
}

function renderBubble(props) {
    return (
        <View>
            <ChatBubble
                {...props}
                wrapperStyle={{
                    left: { backgroundColor: '#FFF5D9', minWidth: 100, maxWidth: 600 },
                    right: { backgroundColor: '#fff', minWidth: 100, maxWidth: 600 },
                }}
                textStyle={{
                    left: { color: '#212529' },
                    right: { color: '#212529' },
                }}
                timeTextStyle={{
                    left: { color: '#868E96' },
                    right: { color: '#868E96' },
                }}
                containerStyle={{
                    left: { marginTop: 20 },
                    right: { marginTop: 20 },
                }}
                bottomContainerStyle={{
                    left: { marginTop: 8 },
                    right: { marginTop: 8 },
                }}
                userNameContainerStyle={{
                    marginBottom: 8,
                }}
            />
        </View>
    );
}

function renderTime(props) {
    return (
        <Time
            {...props}
            timeFormat="HH:mm (DD.MM.YYYY)"
        />
    );
}

function renderSystemMessage(props) {
    return (
          <SystemMessage
            {...props}
            wrapperStyle={{ borderWidth: 1, borderColor: '#000', backgroundColor: '#fff', paddingVertical: 10, paddingHorizontal: 15 }}
            textStyle={{ fontSize: 16, color: '#000', fontWeight: '500' }}
          />
        );
}

class Chat extends React.Component {
    state = {
        chat: false,
        chatId: false,
        owner: false,
        sender: false,
        messages: []
    }

    componentDidMount() {
        if (this.props.accountType == 'client') {
            this.init('client');
        }
        
        if (this.props.accountType == 'manager') {
            this.setState({ chatId: this.props.chatId, owner: this.props.owner }, () => this.init('manager'));
        }
        
        if (this.props.accountType == 'director') {
            this.setState({ chatId: this.props.chatId, owner: this.props.owner }, () => this.init('manager'));
        }

        this.setState({ sender: Api.userId });

        console.log('start listen');
        Api.socket.on('chat/message', this.listen);
        Api.socket.on('chat/updateMessage', this.listenUpdate);
        Api.socket.on('chat/updateOrderMessage', this.listenOrderUpdate);
        
        Api.chatReconnect = () => {
            this.loadMessages();
        }
    }

    componentWillUnmount() {
        console.log('stop listen');
        Api.socket.off('chat/message', this.listen);
        Api.socket.off('chat/updateMessage', this.listenUpdate);
        Api.socket.off('chat/updateOrderMessage', this.listenOrderUpdate);
    }

    listen = (data) => {
        Logger.log('request', 'Входящее сообщение', data);
        if (Api.socket.id != data.owner) {
            Logger.log('request', 'Входящее сообщение добавлено в чат');
            this.setRead();
            this.setState(previousState => ({
                messages: GiftedChat.append(previousState.messages, [ this.convertMessage(data.message, data.user ? [data.user] : []) ])
            }));
        }
    }

    convertMessage(msg, users = []) {
        let userName = '';
        let user = users.find(user => user._id === msg.user);
        if (msg.robot) {
            if (this.props.accountType !== 'client') {
                userName = 'Автоматическое сообщение';
            }
        } else {
            if (user) {
                userName = [];
                if (user.last_name) userName.push(user.last_name);
                if (user.name) userName.push(user.name);
                if (user.second_name) userName.push(user.second_name);
                userName = userName.join(' ');
                if (user.type === 'Менеджер' || user.type === 'Директор') userName = userName + ' - ' + user.type;
            }
        }

        return {
            _id: msg._id,
            text: msg.message,
            image: (msg.image) ? msg.image : false,
            file: (msg.file) ? msg.file : false,
            link: (msg.link) ? msg.link : false,
            createdAt: new Date(msg.timestamp*1000),
            system: (msg.system) ? true : false,
            order: (msg.order) ? true : false,
            type: (msg.type) ? msg.type : false,
            assigned: (msg.assigned) ? msg.assigned : false,
            data: (msg.data) ? msg.data : {},
            state: msg.state,
            user: {
                _id: msg.robot ? 'robot' : msg.user,
                name: userName,
                // Временная заглушка для аватарки. Считаем, что у клиента нет аватарки, а для менеджера или робота и спользуем заглушку
                avatar: (user?.type === 'Менеджер' || user?.type === 'Директор' || msg.robot) ?
                    require('../img/avatar.jpg') : null,
            }
        };
    }

    orderMessageText() {
        const chat = this.state.chat;
        
        // let message = 'Заказ №' + chat.order_num;
        let message = chat.name;

        if (chat.order_type === 'auction') {
            message = message + '\n' +
                ((chat.order.description) ? ('Описание лота: ' + chat.order.description + '\n') : '');
        } else {
            if (chat.order_type === 'transportation' || (chat.order_type !== 'delivery' && chat.order_type !== 'trucks' && chat.order_type !== 'materials')) {
                message = message + '\n' +
                    'Перевозка\n' +
                    ((chat.order.from) ? ('Откуда: ' + chat.order.from + '\n') : '') +
                    ((chat.order.destination) ? ('Куда: ' + chat.order.destination + '\n') : '') +
                    ((chat.order.payload) ? ('Что: ' + chat.order.payload + '\n') : '') +
                    ((chat.order.amount) ? ('Сколько: ' + chat.order.amount + '\n') : '') +
                    ((chat.order.price) ? ('Цена: ' + chat.order.price + '\n') : '');
            }

            if (chat.order_type === 'delivery') {
                message = message + '\n' +
                    'Поставка\n' +
                    ((chat.order.from) ? ('Откуда: ' + chat.order.from + '\n') : '') +
                    ((chat.order.destination) ? ('Куда: ' + chat.order.destination + '\n') : '') +
                    ((chat.order.payload) ? ('Что: ' + chat.order.payload + '\n') : '') +
                    ((chat.order.amount) ? ('Сколько: ' + chat.order.amount + '\n') : '') +
                    ((chat.order.price) ? ('Цена: ' + chat.order.price + '\n') : '');
            }

            if (chat.order_type === 'trucks') {
                message = message + '\n' +
                    'Техника\n' +
                    ((chat.order.truckName) ? ('Название техники: ' + chat.order.truckName + '\n') : '') +
                    ((chat.order.destination) ? ('Куда: ' + chat.order.destination + '\n') : '') +
                    ((chat.order.truckParams) ? ('Характеристики: ' + chat.order.truckParams + '\n') : '') +
                    ((chat.order.amount) ? ('Сколько: ' + chat.order.amount + '\n') : '') +
                    ((chat.order.date) ? ('Когда: ' + chat.order.date + '\n') : '') +
                    ((chat.order.time) ? ('Во сколько: ' + chat.order.time + '\n') : '') +
                    ((chat.order.price) ? ('Цена: ' + chat.order.price + '\n') : '');
            }

            if (chat.order_type === 'materials') {
                message = message + '\n' +
                    'Доставка материала\n' +
                    ((chat.order.destination) ? ('Куда: ' + chat.order.destination + '\n') : '') +
                    ((chat.order.payload) ? ('Что: ' + chat.order.payload + '\n') : '') +
                    ((chat.order.amount) ? ('Сколько: ' + chat.order.amount + '\n') : '') +
                    ((chat.order.price) ? ('Цена: ' + chat.order.price + '\n') : '');
            }
            
            if (chat.order.comment) {
                message = message+'\nКомментарий: '+chat.order.comment;
            }
        }
        
        return {
            _id: 'order',
            text: message,
            createdAt: new Date(this.state.chat.created*1000),
            order: true,
//            user: {
//                _id: msg.user,
//                name: 'React Native',
//                avatar: require('../img/avatar.jpg'),
//            }
        };
    }

    locationMessageText(data) {
        console.log(data.location);
        const message = 'Отправляю свои координаты: https://www.google.com/maps/search/?api=1&query='+data.location.latitude+'+'+data.location.longitude;
        
        return {
            _id: 'order',
            text: message,
            createdAt: new Date(),
            order: true,
//            user: {
//                _id: msg.user,
//                name: 'React Native',
//                avatar: require('../img/avatar.jpg'),
//            }
        };
    }

    init(type) {
        let data = {};
        if (type == 'manager') {
            if (this.props.chatId) data.chatId = this.props.chatId;
            else data.owner = this.props.owner;
        }
        Api.request('chat/init', data).then(response => {
            if (response.success) {
                this.setState({ chat: response.chat, chatId: response.chat._id, owner: response.chat.owner }, () => {
                    Api.request('joinChat', {
                        room: response.chat._id
                    });
                    this.loadMessages();
                });
            } else {
                Api.alert();
            }
        });
    }

    setRead() {
        Api.request('chat/setRead', {
            chatId: this.state.chatId
        });
    }

    loadMessages(limit) {
        if (!this.state.chatId) return;
        let timestamp = false;
        if (this.state.messages.length) timestamp = this.state.messages[this.state.messages.length-1].createdAt.getTime()/1000;
        
        Api.request('chat/loadMessages', {
            chatId: this.state.chatId,
            timestamp: timestamp,
            limit: limit
        }).then(response => {
            
            let messagesArray = response.messages.map((msg, i) => {
                return this.convertMessage(msg, response.users);
            });
            
            if (this.state.chat.type == 'Заказ') {
                messagesArray.push(this.orderMessageText());
            }
            
            this.setRead();

            this.setState({
                messages: messagesArray
            });

        });
    }

    async onSend(messages = []) {
        if (!this.state.chatId) {
            Api.alert();
            return;
        }
        if (messages.length) {
            this.setState(previousState => ({
                messages: GiftedChat.append(previousState.messages, messages),
            }));
            Api.request('chat/addMessage', {
                chatId: this.state.chatId,
                message: messages[0].text,
                image: (messages[0].image) ? messages[0].image : ''
            }).then(response => {
                if (response.success) {
                    const newMessagesArray = this.state.messages.map((msg, i) => {
                        if (msg._id == messages[0]._id) {
                            msg._id = response.message._id;
                            msg.state = response.message.state;
                        }
                        return msg;
                    });

                    this.setState({
                        messages: newMessagesArray
                    });
                } else {
                    // TODO: пометить сообщение, что он не может быть доставлено
                    Api.alert(false, response.message);
                }
            });
        }
    }

    async sendImage(image, base64 = false) {
        const id = `f${(~~(Math.random()*1e8)).toString(16)}`;
        let message = {
            _id: id,
//            text: message,
            createdAt: new Date(this.state.chat.created*1000),
            image: image.uri,
            user: {
                _id: this.state.sender,
//                name: 'React Native',
//                avatar: require('../img/avatar.jpg'),
            }
        }
        this.setState(previousState => ({
            messages: GiftedChat.append(previousState.messages, [message]),
        }), async () => {
            if (!base64) {
                if (image.width > 1000) {
                    const manipResult = await ImageManipulator.manipulateAsync(
                        image.uri,
                        [{ resize: { width: 1000 }}],
                        { compress: 0.6, format: ImageManipulator.SaveFormat.jpg }
                    );

                    image = {...image, ...manipResult};
                }
            }

            Api.uploadFile(image.uri, base64, base64 ? image.file.name : '').then(response => {
                Api.request('chat/addMessage', {
                    chatId: this.state.chatId,
    //                message: '',
                    image: response
                }).then(response => {
                    if (response.success) {
                        const newMessagesArray = this.state.messages.map((msg, i) => {
                            if (msg._id == message._id) {
                                msg._id = response.message._id;
                                msg.state = response.message.state;
                                if (base64) msg.image = response.message.image;
                            }
                            return msg;
                        });

                        this.setState({
                            messages: newMessagesArray
                        });
                    } else {
                        // TODO: пометить сообщение, что он не может быть доставлено
                        Api.alert(false, response.message);
                    }
                });
            });
        });
    }

    async sendFile(doc, base64 = false) {
        const id = `f${(~~(Math.random()*1e8)).toString(16)}`;
        let message = {
            _id: id,
            text: base64 ? doc.file.name : file,
            createdAt: new Date(this.state.chat.created*1000),
            user: {
                _id: this.state.sender,
//                name: 'React Native',
//                avatar: require('../img/avatar.jpg'),
            }
        }
        this.setState(previousState => ({
            messages: GiftedChat.append(previousState.messages, [message]),
        }), async () => {
            Api.uploadFile(doc.uri, base64, base64 ? doc.file.name : '').then(response => {
                Api.request('chat/addMessage', {
                    chatId: this.state.chatId,
                    message: message.text,
                    file: response
                }).then(response => {
                    if (response.success) {
                        const newMessagesArray = this.state.messages.map((msg, i) => {
                            if (msg._id == message._id) {
                                msg._id = response.message._id;
                                msg.state = response.message.state;
                                msg.file = response.message.file;
                            }
                            return msg;
                        });

                        this.setState({
                            messages: newMessagesArray
                        });
                    } else {
                        // TODO: пометить сообщение, что он не может быть доставлено
                        Api.alert(false, response.message);
                    }
                });
            });
        });
    }

    _onMessagePress = (context, message) => {
        Logger.log('debug', 'Событие: нажатие на сообщение в чате', context, message);
        if (message.link) {
            if (message.link.type === 'clientChat') {
                if (this.props.onChangeChatRequest) {
                    this._alertDlg.show({
                        title: 'Открыть чат пользователя?',
                        content: 'Подтвердите действие',
                        actions: [{
                            title: 'Перейти в чат',
                            action: dlg => this.props.onChangeChatRequest({ clientId: message.link.clientId }),
                        }, {
                            title: 'Отмена',
                            action: (dlg) => { dlg._hideDialog() }
                        }]
                    });
                }
            } else {

            }
        }
    }

    listenUpdate = (data) => {
        this.updateMessage(data.message.id , this.convertMessage(data.message));
    }
    
    listenOrderUpdate = (data) => {
        let chat = { ... this.state.chat };
        chat.order = {
            ...chat.order,
            ...data.order,
        };
        this.setState({ chat: chat });
       let message = this.orderMessageText();
       this.updateMessage('order', message);
    }

    updateMessage = (id, message) => {
        const newMessagesArray = this.state.messages.map((msg, i) => {
            if (msg._id == id) {
                return {
                    ...msg,
                    ...message
                };
            }
            return msg;
        });
        this.setState({
            messages: newMessagesArray
        });
    }

    renderSend(props) {
        return (
            <Send
                {...props}
                containerStyle={styles.button}
            >
                <Feather name="arrow-up" color="#212529" size={24} />
            </Send>
        );
    }

    renderInputToolbar(props) {
        return (
            <InputToolbar {...props}
                textInputProps={{
                    onSubmitEditing: () => {
                        if (props.text && props.onSend) {
                            let text = props.text;
                            props.onSend({ text: text.trim() }, true);
                        }
                    }
                }}
            />
        );
    }

    renderCustomActions = props => {
        if (Platform.OS == 'web') {
            return (
                <WebChatActions
                    onPick={(data) => {
                        console.log(data);
                        if (data.file.type === 'image/jpeg' || data.file.type === 'image/png') {
                            this.sendImage(data, true);
                        } else {
                            this.sendFile(data, true);
                        }
                    }}
                />
            );
        } else {
            return (
                <ChatActions
                    {...props}
                    onImage={(data) => {this.sendImage(data)}}
                    onLocation={(coords) => {
                        this.onSend([this.locationMessageText(coords)]);
                     }}
                />
            );
        }
    }
    
    renderMessageImage = props => {
        if (Platform.OS == 'web') {
            return (
                <Image accessibilityRole='link' href={props.currentMessage.image} target='_blank'
                    {...props.imageProps}
                    style={[{width: 150, height: 100, borderRadius: 13, margin: 3, resizeMode: 'cover'}]}
                    source={{ uri: props.currentMessage.image }}
                />
            );
        } else {
            return (
                <MessageImage {...props} />
            );
        }
    }

    render() {
        return (
            <View style={{flex: 1}}>
                <Alert ref={refLink => this._alertDlg = refLink} />
                <GiftedChat
                    chat={this.state.chat}
                    messages={this.state.messages}
                    user={{ _id: this.state.sender }}
                    accountType={this.props.accountType}
                    onSend={messages => this.onSend(messages)}
                    onMessagePress={this._onMessagePress}
                    renderSend={this.renderSend}
                    placeholder='Сообщение'
                    alwaysShowSend={true}
                    multiline={false}
                    renderSystemMessage={renderSystemMessage}
                    renderCustomView={renderCustomView}
                    isCustomViewBottom
                    renderBubble={renderBubble}
                    renderTime={renderTime}
                    renderUsernameOnMessage={true}
                    updateMessage={this.updateMessage}
                    renderActions={this.renderCustomActions}
                    onOrderEdit={this.props.onOrderEdit}
                    renderMessageImage={this.renderMessageImage}
                    renderInputToolbar={this.renderInputToolbar}
                    parsePatterns={linkStyle => [
                        {
                            pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                            style: { color: '#000', textDecorationLine: 'underline' },
                            onPress: props => {
                                if (window && window.open) {
                                    window.open(props, '_blank');
                                } else {
                                    Linking.openURL(props, '_blank');
                                } 
                            }
                        },
                    ]}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    button: {
        height: 36,
        width: 36,
        backgroundColor: '#FFC107',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 6,
        marginBottom: 4
    },
    statusBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: 10,
        minWidth: 100
    },
    timeContainer: {
        marginRight: 5
    },
    iconContainer: {
        
    }
});

export default Chat;