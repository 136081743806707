import React from 'react';
import { StyleSheet, View, Text, TouchableWithoutFeedback } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
    MessageText,
    MessageImage,
    MessageVideo,
    MessageAudio,
    Time,
    User,
    IMessage,
    LeftRightStyle,
    Reply,
    Omit,
    MessageVideoProps,
    MessageAudioProps,
} from 'react-native-gifted-chat';
import dayjs from 'dayjs';

const DEFAULT_OPTION_TITLES = ['Copy Text', 'Cancel'];

function isSameUser(
    currentMessage: IMessage,
    diffMessage: IMessage | null | undefined,
) {
    return !!(
        diffMessage &&
        diffMessage.user &&
        currentMessage.user &&
        diffMessage.user._id === currentMessage.user._id
    )
}

function isSameDay(
    currentMessage: IMessage,
    diffMessage: IMessage | null | undefined,
) {
    if (!diffMessage || !diffMessage.createdAt) {
        return false
    }

    const currentCreatedAt = dayjs(currentMessage.createdAt)
    const diffCreatedAt = dayjs(diffMessage.createdAt)

    if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
        return false
    }

    return currentCreatedAt.isSame(diffCreatedAt, 'day')
}

class ChatBubble extends React.Component {

    static defaultProps = {
        touchableProps: {},
        onMessagePress: null,
        onLongPress: null,
        renderMessageImage: null,
        renderMessageVideo: null,
        renderMessageAudio: null,
        renderMessageText: null,
        renderCustomView: null,
        renderUsername: null,
        renderTicks: null,
        renderTime: null,
        renderQuickReplies: null,
        onQuickReply: null,
        position: 'left',
        optionTitles: DEFAULT_OPTION_TITLES,
        currentMessage: {
            text: null,
            createdAt: null,
            image: null,
        },
        nextMessage: {},
        previousMessage: {},
        containerStyle: {},
        wrapperStyle: {},
        bottomContainerStyle: {},
        tickStyle: {},
        usernameStyle: {},
        containerToNextStyle: {},
        containerToPreviousStyle: {},
    }

    onMessagePress = () => {
      const { currentMessage } = this.props;
      if (this.props.onMessagePress) {
          this.props.onMessagePress(this.context, this.props.currentMessage);
      }
    }

    onLongPress = () => {
        const { currentMessage } = this.props;
        if (this.props.onLongPress) {
            this.props.onLongPress(this.context, this.props.currentMessage);
        } else if (currentMessage && currentMessage.text) {
            const { optionTitles } = this.props;
            const options =
                optionTitles && optionTitles.length > 0
                ? optionTitles.slice(0, 2)
                : DEFAULT_OPTION_TITLES;
            const cancelButtonIndex = options.length - 1;
            this.context.actionSheet().showActionSheetWithOptions(
                {
                    options,
                    cancelButtonIndex,
                },
                (buttonIndex: number) => {
                    switch (buttonIndex) {
                        case 0:
                            Clipboard.setString(currentMessage.text);
                            break;
                        default:
                        break;
                    }
                },
            );
        }
    }

    styledBubbleToNext() {
        const {
            currentMessage,
            nextMessage,
            position,
            containerToNextStyle,
        } = this.props;
        if (
            currentMessage &&
            nextMessage &&
            position &&
            isSameUser(currentMessage, nextMessage) &&
            isSameDay(currentMessage, nextMessage)
        ) {
            return [
                styles[position].containerToNext,
                containerToNextStyle && containerToNextStyle[position],
            ];
        }
        return null;
    }

    styledBubbleToPrevious() {
        const {
            currentMessage,
            previousMessage,
            position,
            containerToPreviousStyle,
        } = this.props;
        if (
            currentMessage &&
            previousMessage &&
            position &&
            isSameUser(currentMessage, previousMessage) &&
            isSameDay(currentMessage, previousMessage)
        ) {
            return [
                styles[position].containerToPrevious,
                containerToPreviousStyle && containerToPreviousStyle[position],
            ];
        }
        return null;
    }

  renderQuickReplies() {
    const {
      currentMessage,
      onQuickReply,
      nextMessage,
      renderQuickReplySend,
      quickReplyStyle,
    } = this.props
    if (currentMessage && currentMessage.quickReplies) {
      const { containerStyle, wrapperStyle, ...quickReplyProps } = this.props
      if (this.props.renderQuickReplies) {
        return this.props.renderQuickReplies(quickReplyProps)
      }
      return (
        <QuickReplies
          {...{
            currentMessage,
            onQuickReply,
            nextMessage,
            renderQuickReplySend,
            quickReplyStyle,
          }}
        />
      )
    }
    return null
  }

  renderMessageText() {
    if (this.props.currentMessage && this.props.currentMessage.text) {
      const {
        containerStyle,
        wrapperStyle,
        optionTitles,
        ...messageTextProps
      } = this.props
      if (this.props.renderMessageText) {
        return this.props.renderMessageText(messageTextProps)
      }
      return <MessageText {...messageTextProps} />
    }
    return null
  }

  renderMessageImage() {
    if (this.props.currentMessage && this.props.currentMessage.image) {
      const { containerStyle, wrapperStyle, ...messageImageProps } = this.props
      if (this.props.renderMessageImage) {
        return this.props.renderMessageImage(messageImageProps)
      }
      return <MessageImage {...messageImageProps} />
    }
    return null
  }

  renderMessageVideo() {
    if (this.props.currentMessage && this.props.currentMessage.video) {
      const { containerStyle, wrapperStyle, ...messageVideoProps } = this.props
      if (this.props.renderMessageVideo) {
        return this.props.renderMessageVideo(messageVideoProps)
      }
      return <MessageVideo {...messageVideoProps} />
    }
    return null
  }

  renderMessageAudio() {
    if (this.props.currentMessage && this.props.currentMessage.audio) {
      const { containerStyle, wrapperStyle, ...messageAudioProps } = this.props
      if (this.props.renderMessageAudio) {
        return this.props.renderMessageAudio(messageAudioProps)
      }
      return <MessageAudio {...messageAudioProps} />
    }
    return null
  }

    renderTicks() {
        const { currentMessage, renderTicks, user } = this.props;
        if (renderTicks && currentMessage) {
            return renderTicks(currentMessage);
        }
        if (
            currentMessage &&
            user &&
            currentMessage.user &&
            currentMessage.user._id !== user._id
        ) {
            return null;
        }
        if (
            currentMessage &&
            currentMessage.state
        ) {
            return (
                <View style={styles.content.tickView}>
                    {(currentMessage.state === 'saved') && (
                        <MaterialCommunityIcons name="check" color="#4CAF50" />
                    )}
                    {(currentMessage.state === 'delivered') && (
                        <MaterialCommunityIcons name="check-all" color="#868E96" />
                    )}
                    {(currentMessage.state === 'read') && (
                        <MaterialCommunityIcons name="check-all" color="#4CAF50" />
                    )}
                </View>
            );
        }
        return null;
    }

    renderTime() {
        if (this.props.currentMessage && this.props.currentMessage.createdAt) {
            const {
                containerStyle,
                wrapperStyle,
                textStyle,
                ...timeProps
            } = this.props;
            if (this.props.renderTime) {
                return this.props.renderTime(timeProps);
            }
            return (<Time {...timeProps} />);
        }
        return null;
    }

    renderUsername() {
        const { currentMessage, previousMessage, user, userNameContainerStyle } = this.props;
        if (this.props.renderUsernameOnMessage && currentMessage) {
            if (
                user &&
                currentMessage.user._id === user._id ||
                !currentMessage.user.name
                // || (previousMessage && isSameUser(currentMessage, previousMessage))
            ) {
                return null;
            }
            return (
                <View style={[styles.content.usernameView, userNameContainerStyle]}>
                    <Text
                        style={
                            [styles.content.username, this.props.usernameStyle]
                        }
                    >
                        {currentMessage.user.name}
                    </Text>
                </View>
            )
        }
        return null;
    }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props)
    }
    return null
  }

  renderBubbleContent() {
    return this.props.isCustomViewBottom ? (
      <View>
        {this.renderMessageImage()}
        {this.renderMessageVideo()}
        {this.renderMessageAudio()}
        {this.renderMessageText()}
        {this.renderCustomView()}
      </View>
    ) : (
      <View>
        {this.renderCustomView()}
        {this.renderMessageImage()}
        {this.renderMessageVideo()}
        {this.renderMessageAudio()}
        {this.renderMessageText()}
      </View>
    )
  }


    render() {
        const {
            position,
            containerStyle,
            wrapperStyle,
            bottomContainerStyle,
            userNameContainerStyle,
        } = this.props
        return (
            <View
                style={[
                    styles[position].container,
                    containerStyle && containerStyle[position],
                ]}
            >
                {this.renderUsername()}
                <View
                    style={[
                        styles[position].wrapper,
                        this.styledBubbleToNext(),
                        this.styledBubbleToPrevious(),
                        wrapperStyle && wrapperStyle[position],
                    ]}
                >
                    <TouchableWithoutFeedback
                        onPress = {this.onMessagePress}
                        onLongPress={this.onLongPress}
                        accessibilityTraits='text'
                        {...this.props.touchableProps}
                    >
                        <View>
                            {this.renderBubbleContent()}
                        </View>
                    </TouchableWithoutFeedback>
                </View>
                {this.renderQuickReplies()}
                <View
                    style={[
                    styles[position].bottom,
                    bottomContainerStyle && bottomContainerStyle[position],
                    ]}
                >
                    {this.renderTime()}
                    {this.renderTicks()}
                </View>
            </View>
        );
    }
}

export default ChatBubble;

const styles = {
  left: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-start',
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: '#f0f0f0',
      marginRight: 60,
      minHeight: 20,
      justifyContent: 'flex-end',
    },
    containerToNext: {
      borderBottomLeftRadius: 3,
    },
    containerToPrevious: {
      borderTopLeftRadius: 3,
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
  right: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-end',
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: '#0084ff',
      marginLeft: 60,
      minHeight: 20,
      justifyContent: 'flex-end',
    },
    containerToNext: {
      borderBottomRightRadius: 3,
    },
    containerToPrevious: {
      borderTopRightRadius: 3,
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  }),
  content: StyleSheet.create({
    tick: {
      fontSize: 10,
      backgroundColor: 'transparent',
      color: '#fff',
    },
    tickView: {
      flexDirection: 'row',
      marginRight: 10,
    },
    username: {
      top: -3,
      left: 0,
      fontSize: 12,
      backgroundColor: 'transparent',
      color: '#868E96',
    },
    usernameView: {
      flexDirection: 'row',
      marginHorizontal: 10,
    },
  }),
}