import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { TextInputMask } from 'react-native-masked-text'
import defaultStyles from '../styles/main.js';
import formStyles from '../styles/forms.js';
import UniqueId from '../components/UniqueId';
import Api from '../components/Api';
import { alertMsg } from '../components/Api';

class AuthForm extends React.Component {
    state = {
        login: '',
        password: '',
        loading: false,
        smsLoading: false,
        timer: 0
    };

    smsTimer = {
        handler: false,
        timeout: 0
    };

    handleLoginChange = (login) => {
        let phone = login.replace(/\D+/g,'');
        if (phone.substring(1,2) === '8') phone = '7'+phone.substring(2);
        this.setState({ login: phone });
    };

    handlePasswordChange = (password) => {
        this.setState({ password: password });
    };

    handleLoginPress = () => {
        if (this.state.loading) return;
        this.setState({loading: true}, () => {
            Api.auth(this.state.login, this.state.password).then(({ token, user }) => {
                this.setState({ loading: false });
                this.props.loginCallback(token, user);
            }, () => {
                this.setState({ loading: false });
            });
        });
    };

    handleSMSPress = () => {
        UniqueId().then(id => {
            if (this.state.smsLoading) return;
            if (this.smsTimer.timeout > 0) return;
            this.setState({ smsLoading: true }, () => {
                Api.remember({
                    phone: this.state.login,
                    uniqueId: id
                }).then(() => {
                    this.setState({ smsLoading: false });
                    if (this.smsTimer.handler) clearInterval(this.smsTimer.handler);
                    this.smsTimer.timeout = 60;
                    this.setState({ timer: this.smsTimer.timeout });
                    this.smsTimer.handler = setInterval(() => {
                        this.smsTimer.timeout--;
                        if (this.smsTimer.timeout <= 0) {
                            this.smsTimer.timeout = 0;
                            clearInterval(this.smsTimer.handler);
                        }
                        this.setState({ timer: this.smsTimer.timeout });
                    }, 1000);
                    alertMsg('Сообщение', 'Пароль отправлен по SMS на номер ' + this.state.login);
                }, () => {
                    this.setState({ smsLoading: false });
                });
            });
        });
    };
    
    render() {
        return (
            <View style={[this.props.style, styles.formContainer, formStyles.formShadowContainer]}>
                <Text style={formStyles.title}>Вход в ИнертМаркет</Text>
                <TextInput
                    style={{height: 48, marginBottom: 14, marginTop: 10}}
                    label='Телефон'
                    mode='outlined'
                    value={this.state.login}
                    onChangeText={this.handleLoginChange}
                    autoCorrect={false}
                    keyboardType="phone-pad"
                    returnKeyType="next"
                    render={props =>
                        <TextInputMask
                            {...props}
                            type={'custom'}
                            options={{
                                mask: '+7 (999) 999-99-99'
                            }}
                        />
                    }
                />
                <TextInput
                    style={{height: 48, marginBottom: 20}}
                    label='Пароль'
                    mode='outlined'
                    value={this.state.password}
                    onChangeText={this.handlePasswordChange}
                    onSubmitEditing={this.handleLoginPress}
                    secureTextEntry={true}
                    keyboardType="phone-pad"
                    returnKeyType="done"
                />
                <Button
                    style={{ marginBottom: 20 }}
                    contentStyle={{ height: 48 }}
                    mode="outlined"
                    color="#212121"
                    loading={this.state.smsLoading}
                    onPress={this.handleSMSPress}
                >
                    { !this.state.timer && 'Получить пароль по SMS' }
                    { this.state.timer>0 && 'Отправить SMS через ' + this.state.timer + ' сек.' }
                </Button>
                <Button
                    contentStyle={{ height: 48 }}
                    mode="contained"
                    color="#FFC107"
                    loading={this.state.loading}
                    onPress={this.handleLoginPress}
                >
                        Войти
                </Button>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    maxWidth: {
        maxWidth: 360
    },
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#E5E5E5'
    },
    titleContainer: {
        marginTop: 35,
        marginHorizontal: 32
    },
    imageContainer: {
        marginBottom: 24
    },
    image: {
        width: 295,
        height: 63
    },
    title: {
        fontSize: 16,
        lineHeight: 26,
        color: '#212529',
        marginBottom: 30
    },
    formContainer: {
        marginHorizontal: 16
    }
})

export default AuthForm