import PropTypes from 'prop-types';
import React from 'react';
import { MaterialIcons } from '@expo/vector-icons'; 
import { Platform, StyleSheet, Text, TouchableOpacity, View, ViewPropTypes } from 'react-native';
import { getLocationAsync, pickImageAsync, takePictureAsync } from './mediaUtils';

export default class ChatActions extends React.Component {
    onActionsPress = () => {
        let options;
        if (Platform.OS == 'web') {
            options = [
                'Отправить файл',
                'Отмена',
            ];
        } else {
            options = [
                'Сделать снимок',
                'Отправить файл',
                'Отправить GPS',
                'Отмена',
            ];
        }
        const cancelButtonIndex = options.length - 1;
        this.context.actionSheet().showActionSheetWithOptions(
        {
            options,
            cancelButtonIndex,
        },
        async buttonIndex => {
            const { onImage, onLocation } = this.props;
            if (Platform.OS == 'web') {
                switch (buttonIndex) {
                    case 0:
                        pickImageAsync(onImage);
                        return;
                    default:
                }
            } else {
                switch (buttonIndex) {
                    case 0:
                        takePictureAsync(onImage);
                        return;
                    case 1:
                        pickImageAsync(onImage);
                        return;
                    case 2:
                        getLocationAsync(onLocation);
                    default:
                }
            }
        },
    )}

    renderIcon = () => {
        return (
            <MaterialIcons name="attach-file" size={24} color="black" />
        );
    }

    render() {
        return (
            <TouchableOpacity
                style={[styles.container, this.props.containerStyle]}
                onPress={this.onActionsPress}
            >
                {this.renderIcon()}
            </TouchableOpacity>
        )
    }
}

const styles = StyleSheet.create({
  container: {
    width: 26,
    height: 26,
    marginLeft: 10,
    marginBottom: 10,
  },
  wrapper: {
    borderRadius: 13,
    borderColor: '#b2b2b2',
    borderWidth: 2,
    flex: 1,
  },
  iconText: {
    color: '#b2b2b2',
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: 'transparent',
    textAlign: 'center',
  },
})

ChatActions.contextTypes = {
    actionSheet: PropTypes.func,
}

ChatActions.defaultProps = {
    onImage: () => {},
    onLocation: () => {},
    options: {},
    containerStyle: {},
    wrapperStyle: {},
    iconTextStyle: {},
}

ChatActions.propTypes = {
    onImage: PropTypes.func,
    onLocation: PropTypes.func,
    options: PropTypes.object,
    containerStyle: ViewPropTypes.style,
    wrapperStyle: ViewPropTypes.style,
    iconTextStyle: Text.propTypes.style,
}